import { Component, OnInit} from '@angular/core';
import { AuthenticationService } from '@app/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.less']
})
export class FormsComponent {

  userData: any;
  hasFormsPermission = false;
  emailHref = '';

  constructor(
    private authService: AuthenticationService,
    public router: Router,
  ) {
    this.userData = this.authService.currentUserValue.user;
    this.hasFormsPermission = this.userData.claims.includes('forms');
    this.emailHref = `mailto:sales@nurtureboss.io?subject=Id like to enable Nurture Boss Surveys on my account&body=Please contact me to enable Nurture Boss Surveys on my account at property ${this.userData.propertyName}!`
  }
}
