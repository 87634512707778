<div class="vla-wrapper"id="vla-parent-wrapper">
  <nav class="vla-sidebar" *ngIf="vlaEnabled()">
    <div class="sidebar-header">
      <h2 class="page-title">VLA</h2>
    </div>
    <ul class="nav flex-column">
      <li class="nav-item">
        <a routerLink="/vla/automations" [class.active]="router.url === '/vla/automations'">
          <div class="nav-item-content">
            <div class="nav-icon">
              <i class="far fa-wand-sparkles"></i>
            </div>
            <div class="nav-text">
              Automations
            </div>
          </div>
        </a>
      </li>
      <li class="nav-item">
        <a routerLink="/vla/allconversations" [class.active]="router.url === '/vla/allconversations'">
          <div class="nav-item-content">
            <div class="nav-icon">
              <i class="far fa-people-arrows"></i>
            </div>
            <div class="nav-text">
              Conversations
            </div>
          </div>
        </a>
        <ul class="nav sub-nav flex-column" [ngStyle]="{'display': router.url.includes('/vla/allconversations') ? 'block' : 'none'}">
          <li class="nav-item">
            <a routerLink="/vla/allconversations/active" [class.active]="router.url === '/vla/allconversations/active'">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-play-circle"></i>
                </div>
                <div class="nav-text">
                  Active
                </div>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/vla/allconversations/all" [class.active]="router.url === '/vla/allconversations/all'">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-list"></i>
                </div>
                <div class="nav-text">
                  All
                </div>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/vla/allconversations/active-handoff" [class.active]="router.url === '/vla/allconversations/active-handoff'">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-hand-point-right"></i>
                </div>
                <div class="nav-text">
                  Active Handoff
                </div>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/vla/allconversations/email" [class.active]="router.url === '/vla/allconversations/email'">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-envelope"></i>
                </div>
                <div class="nav-text">
                  Email
                </div>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/vla/allconversations/phone" [class.active]="router.url === '/vla/allconversations/phone'">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-phone"></i>
                </div>
                <div class="nav-text">
                  Phone
                </div>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/vla/allconversations/chatbot" [class.active]="router.url === '/vla/allconversations/chatbot'">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-robot-astromech"></i>
                </div>
                <div class="nav-text">
                  Chatbot
                </div>
              </div>
            </a>
          </li>
        </ul>
      </li>
      <li class="nav-item">
        <a routerLink="/vla/knowledgebase" [class.active]="router.url === '/vla/knowledgebase'">
          <div class="nav-item-content">
            <div class="nav-icon">
              <i class="far fa-book-open"></i>
            </div>
            <div class="nav-text">
              Knowledge Base
            </div>
          </div>
        </a>
      </li>
      <li class="nav-item">
        <a routerLink="/settings/vla" [class.active]="router.url === '/settings/vla'">
          <div class="nav-item-content">
            <div class="nav-icon">
              <i class="far fa-brain-circuit"></i>
            </div>
            <div class="nav-text">
              Settings
            </div>
          </div>
        </a>
      </li>
    </ul>
    <div class="row sidebar-buttons-wrapper">
      <button *ngIf="isAdmin && !isEmailVlaEnabled" (click)="openVlaModal(emailVlaModal)" class="button button-dark sidebar-button">
        <i class="fa fa-envelope"></i>
        &nbsp;Enable Email VLA
      </button>
      <button *ngIf="isAdmin && !isTextVlaEnabled" (click)="openVlaModal(textVlaModal)" class="button button-dark sidebar-button">
        <i class="fa fa-comment-sms"></i>
        &nbsp;Enable Text VLA
      </button>
      <button *ngIf="isAdmin && !isVoiceVlaEnabled" (click)="openVlaModal(voiceVlaModal)" class="button button-dark sidebar-button">
        <i class="fa fa-phone"></i>
        &nbsp;Enable Voice VLA
      </button>
      <button *ngIf="isAdmin && !isChatbotAssistantEnabled" (click)="openVlaModal(chatbotAssistantModal)" class="button button-dark sidebar-button">
        <i class="fa fa-robot"></i>
        &nbsp;Enable Chatbot
      </button>
    </div>
  </nav>
  <div class="vla-content" *ngIf="vlaEnabled()">
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
  <div *ngIf="!vlaEnabled()" class="enable-vla-button-wrap">
    <p class="text-center"><img src="/assets/virtual-leasing-assistant-placeholder.png" /></p>
    <h3 class="text-center">
      <a target="_blank" [href]="vlaHref">Click Here</a> to enable the Nurture Boss Virtual Leasing Assistant!
    </h3>
    <div class="row justify-content-center align-items-center enable-vla-button-row">
      <button *ngIf="isAdmin && !isEmailVlaEnabled" (click)="openVlaModal(emailVlaModal)" class="button button-dark margin-top-6 full-width margin-right-12">
        <i class="fa fa-envelope"></i>
        &nbsp;Enable Email VLA
      </button>
      <button *ngIf="isAdmin && !isTextVlaEnabled" (click)="openVlaModal(textVlaModal)" class="button button-dark margin-top-6 full-width margin-right-12">
        <i class="fa fa-comment-sms"></i>
        &nbsp;Enable Text VLA
      </button>
      <button *ngIf="isAdmin && !isVoiceVlaEnabled" (click)="openVlaModal(voiceVlaModal)" class="button button-dark margin-top-6 full-width margin-right-12">
        <i class="fa fa-phone"></i>
        &nbsp;Enable Voice VLA
      </button>
      <button *ngIf="isAdmin && !isChatbotAssistantEnabled" (click)="openVlaModal(chatbotAssistantModal)" class="button button-dark margin-top-6 full-width margin-right-12">
        <i class="fa fa-robot"></i>
        &nbsp;Enable Chatbot
      </button>
  </div>
</div>

<!-- Enable Email VLA Modal -->
<ng-template #emailVlaModal let-modal>
  <div class="vla-modal">
    <div class="form-group">
      <label>VLA Bot Name (Optional)</label>
      <label class="vla-modal-label">*Updating this name will apply to all bots</label>
      <div class="editable-input-wrapper">
        <input type="text" class="form-control" [(ngModel)]="botName" id="botName" aria-describedby="botName" [disabled]="!botNameEditable">
        <i class="fa fa-pencil editable-input-clickable" *ngIf="!botNameEditable" (click)="botNameEditable = true"></i>
      </div>
    </div>
    <button [disabled]="isEnabling" (click)="enableVLA('email')" class="button button-dark pull-right">
      <span *ngIf="isEnabling" class="spinner-border spinner-border-sm mr-1"></span>
        <i class="fa fa-envelope"></i>
        Enable Email VLA
    </button>
  </div>
</ng-template>

<!-- Enable Text VLA Modal -->
<ng-template #textVlaModal let-modal>
  <div class="vla-modal">
    <div class="form-group">
      <label>VLA Bot Name (Optional)</label>
      <label class="vla-modal-label">*Updating this name will apply to all bots</label>
      <div class="editable-input-wrapper">
        <input type="text" class="form-control" [(ngModel)]="botName" id="botName" aria-describedby="botName" [disabled]="!botNameEditable">
        <i class="fa fa-pencil editable-input-clickable" *ngIf="!botNameEditable" (click)="botNameEditable = true"></i>
      </div>
    </div>
    <button [disabled]="isEnabling" (click)="enableVLA('text')" class="button button-dark pull-right">
      <span *ngIf="isEnabling" class="spinner-border spinner-border-sm mr-1"></span>
      <i class="fa fa-comment-sms"></i>
      Enable Text VLA
    </button>
  </div>
</ng-template>

<!-- Enable Voice VLA Modal -->
<ng-template #voiceVlaModal let-modal>
  <div class="vla-modal">
    <div class="form-group">
      <label>VLA Bot Name (Optional)</label>
      <label class="vla-modal-label">*Updating this name will apply to all bots</label>
      <div class="editable-input-wrapper">
        <input type="text" class="form-control" [(ngModel)]="botName" id="botName" aria-describedby="botName" [disabled]="!botNameEditable">
        <i class="fa fa-pencil editable-input-clickable" *ngIf="!botNameEditable" (click)="botNameEditable = true"></i>
      </div>
    </div>
    <button [disabled]="isEnabling" (click)="enableVLA('voice')" class="button button-dark pull-right">
      <span *ngIf="isEnabling" class="spinner-border spinner-border-sm mr-1"></span>
      <i class="fa fa-phone"></i>
      Enable Voice VLA
    </button>
  </div>
</ng-template>

<!-- Enable Chatbot Assistant Modal -->
<ng-template #chatbotAssistantModal let-modal>
  <div class="vla-modal">
    <div class="form-group">
      <label>VLA Bot Name (Optional)</label>
      <label class="vla-modal-label">*Updating this name will apply to all bots</label>
      <div class="editable-input-wrapper">
        <input type="text" class="form-control" [(ngModel)]="botName" id="botName" aria-describedby="botName" [disabled]="!botNameEditable">
        <i class="fa fa-pencil editable-input-clickable" *ngIf="!botNameEditable" (click)="botNameEditable = true"></i>
      </div>
    </div>
    <button [disabled]="isEnabling" (click)="enableVLA('chatbot')" class="button button-dark pull-right">
      <span *ngIf="isEnabling" class="spinner-border spinner-border-sm mr-1"></span>
      <i class="fa fa-robot"></i>
      Enable Chatbot
    </button>
  </div>
</ng-template>
