import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { TtsService } from "@app/_services";

type TtsProvider = "cartesia" | "elevenlabs";

@Component({
  selector: "tts-audio-player",
  templateUrl: "./tts-audio-player.component.html",
})
export class TtsAudioPlayerComponent implements OnChanges {
  @Input() transcript: string;
  @Input() voiceId: string;
  @Input() provider: TtsProvider;

  audioSource: string | null = null;
  isLoading = false;

  constructor(private ttsService: TtsService) {}

  ngOnChanges(changes: SimpleChanges) {
    // Reset audio if any input changes
    if (changes["transcript"] || changes["voiceId"] || changes["provider"]) {
      if (this.audioSource) {
        URL.revokeObjectURL(this.audioSource);
      }
      this.audioSource = null;
    }
  }

  loadAudio() {
    if (!this.transcript || !this.voiceId || !this.provider) return;

    this.isLoading = true;
    this.ttsService
      .getTextToSpeech(this.provider, this.voiceId, this.transcript)
      .subscribe(
        (audioBlob: Blob) => {
          if (this.audioSource) {
            URL.revokeObjectURL(this.audioSource);
          }
          this.audioSource = URL.createObjectURL(audioBlob);
          this.isLoading = false;
        },
        (error) => {
          console.error("Error loading audio:", error);
          this.isLoading = false;
          this.audioSource = null;
        }
      );
  }
}
