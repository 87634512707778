// Angular:
import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs'

// Libs
import * as _ from 'lodash';

// Services:
import { LoaderService, ToastService } from '@app/_services';

// Types:
import { Table } from 'primeng/table';
import { Calendar } from 'primeng/calendar';
import { LazyLoadEvent, TreeNode } from 'primeng/api';
import { ThreadsApiResponse, ThreadsService } from '@app/_services/threads.service';

interface IPaginate {
  first: number;
  last: number;
}

interface IActiveQuery {
  query: string;
  property: string;
}

@Component({
  selector: 'app-vlaallconversations',
  templateUrl: './vlaallconversations.component.html',
  styleUrls: ['./vlaallconversations.component.less']
})
export class VLAAllConversationsComponent implements OnInit, AfterViewInit {
  @ViewChild('dt') table: Table;
  @ViewChild('updatedCalendar') updatedCalendar: Calendar;

  activeQuery: IActiveQuery = {
    query: '',
    property: ''
  };
  dateRange: Date[] = [];
  debouncedSearch: any;
  conversations: any[] = []; // TODO: Type.
  pagination: IPaginate = {
    first: 1,
    last: 25
  };
  totalRecords = null;
  treeNodes: TreeNode[] = [];
  firstLoad = true;
  currentFilter: string = 'all';

  constructor(
    private toastService: ToastService,
    private loaderService: LoaderService,
    private threadsService: ThreadsService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.debouncedSearch = _.debounce(this.searchFunc, 300);
  }

  ngOnInit() { 
    this.route.url.subscribe(() => {
      this.currentFilter = this.route.snapshot.firstChild.data['filter'] || 'all';
      this.loadAllConversations();
    });
  }

  ngAfterViewInit() {
    this.firstLoad = false;
  }

  async loadAllConversations(query?: string, property?: string): Promise<void> {
    this.loaderService.triggerLoader();
    this.pagination.first = 1;
    this.pagination.last = 25;
    try {
      const threads = await this.getAllConversations(0, 25, query, property).toPromise();
      this.groupConversations(threads.result.data);
      this.totalRecords = threads.result.totalRecords;
    } catch (e) {
      this.toastService.showError('There was an error loading conversations.');
    }
    this.loaderService.stopLoader();
  }

  groupConversations(contactList: any[]): void {
    this.treeNodes = [];
    for (const [index, item] of contactList.entries()) {
      const parentNodeData = {
        channel: 'email', // TODO: Support multiple channels.
        id: item.latestThread._id,
        lastUpdated: item.latestThread.updated,
        messagePreview: item.latestThread.recentMessage,
        name: item.latestThread?.contact?.contactIdentifier || item.latestThread?.visitorId || 'Unknown',
        status: item.latestThread.passedToOnsiteTeam || (item.latestThread.hasOwnProperty('wasSuccessful') && !item.latestThread.wasSuccessful),
      };
      let hasEmail = false;
      let hasCall = false;
      let hasText = false;
      let hasChatbot = false;
      const childrenNodes = item.threads.map((thread) => {
        if (thread.callId) {
          hasCall = true;
        } else if (thread.isTextMessageThread) {
          hasText = true;
        } else if (thread.threadSubject) {
          hasEmail = true;
        } else if (thread.visitorId) {
          hasChatbot = true;
        }
        let channel = 'email';
        if (thread.callId) {
          channel = 'call';
        } else if (thread.isTextMessageThread) {
          channel = 'text';
        } else if (thread.visitorId) {
          channel = 'chatbot';
        }
        return {
          data: {
            child: true,
            channel: channel,
            id: thread._id,
            lastUpdated: thread.updated,
            messagePreview: thread.recentMessage || 'Could not get message.',
            name: item.latestThread?.contact?.contactIdentifier || item.latestThread?.visitorId || 'Unknown',
            status: thread.passedToOnsiteTeam, // TODO: This is confusing.
          }
        }
      });
      let channel = '';
      if (hasCall) {
        channel = channel + 'call';
      }
      if (hasEmail) {
        channel = channel + 'email';
      }
      if (hasText) {
        channel = channel + 'text';
      }
      if (hasChatbot) {
        channel = channel + 'chatbot';
      }
      parentNodeData.channel = channel;
      this.treeNodes.push({
        data: parentNodeData,
        children: childrenNodes,
        expanded: index === 0,
      });
    }
  }

  async paginate(event: LazyLoadEvent): Promise<void> {
    this.loaderService.triggerLoader();
    this.pagination.first = event.first + 1;
    this.pagination.last = event.first + Number(event.rows)

    // Load more data if needed
    const newThreads = await this.getAllConversations(event.first, event.rows, this.activeQuery.query, this.activeQuery.property).toPromise()
    this.groupConversations(newThreads.result.data)

    this.loaderService.stopLoader();
  }

  getAllConversations(skip: number, take: number, query: string, property: string): Observable<ThreadsApiResponse> {
    let startingDate = null;
    let endingDate = null;
    if (this.dateRange.length === 2) {
      startingDate = this.dateRange[0].getTime();

      // Add one day so we can get everything before the next day at midnight
      const endDate = new Date(this.dateRange[1]);
      endDate.setDate(endDate.getDate() + 1);
      endingDate = endDate.getTime();
    }
    
    let filter = this.currentFilter || 'all';

    return this.threadsService.getAllThreads(skip, take, query, property, startingDate, endingDate, filter);
  }
 
  setTableHeading(): void {
    this.pagination.first = this.table._first;
    this.pagination.last = this.table._rows;
    if (this.pagination.first === 0) {
      this.pagination.first = 1;
    }
  }

  searchFunc(query: string, property: string): void {
    this.activeQuery = {
      query: query,
      property: property
    }
    this.loadAllConversations(query, property);
  }

  deferCalendarToggle(calendar: Calendar): void {
  
    // TODO: Not sure why we put this in a setTimeout, maybe to force an Angular cycle?
    setTimeout(() => {
      calendar.toggle();
    }, 100);
  }

  onDateSelect(date: Date): void {
    if (this.dateRange.length === 0 || this.dateRange.length === 2) {
      this.dateRange = [date]
      return
    } else {
      this.dateRange.push(date)
    }
    if (this.dateRange.length === 2) {
      this.deferCalendarToggle(this.updatedCalendar)
      this.dateRange.sort(function (a, b) {
        return +new Date(a) - +new Date(b)
      })
      this.loadAllConversations(this.activeQuery.query, this.activeQuery.property)
    }
  }

  onDateClear(): void {
    this.dateRange = [];
    this.loadAllConversations(this.activeQuery.query, this.activeQuery.property);
  }

  loadSingleConversation(id: string, name: string, rowChildren: any, rowData: any):void {
    if (!rowChildren) {
      const currentRoute = this.router.url;
      this.router.navigate(['/vla/singleconversation'], { 
        queryParams: {
          id,
          backTo: currentRoute,
          contactName: name,
        } 
      });
    } else {
      rowData.expanded = !rowData.expanded;
    }
  }
}
