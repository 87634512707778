import { Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { AuthenticationService, ToastService, LoaderService, TextMessagesService, ContactsService } from '@app/_services';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-two-way',
  templateUrl: './two-way.component.html',
  styleUrls: ['./two-way.component.less']
})
export class TwoWayComponent implements OnInit {

  @ViewChild('optOutModal') private optOutModal: TemplateRef<any>;
  @ViewChild('accordion') accordion;

  registeredUserData: any;
  conversations: any;
  activeConversation: any;
  selectedThread: any;
  replyMessage: string;
  sendingText: boolean = false;
  optOutMessage: any = {};
  optOutInProgress: boolean = false;
  selectedConversationType = [];
  tabIndex = {
    0: 'all',
    1: 'awaiting',
    2: 'unread',
    3: 'prospects',
    4: 'residents',
  };
  activeCategory: string = 'all';
  activeSubCategory: string = 'active';
  assignedNumber: string;

  constructor(
    private authService: AuthenticationService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private textMessagesService: TextMessagesService,
    private router: Router,
    private contactService: ContactsService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
  ) {
      this.registeredUserData = this.authService.currentUserValue;
      this.assignedNumber = this.registeredUserData.user.assignedNumber.replace('+1', '');
  }

  setActiveCategory(category) {
    this.activeCategory = category;
    this.activeSubCategory = 'all';
    this.filterConversations(this.activeCategory, this.activeSubCategory);
  }

  setActiveSubCategory(subCategory) {
    this.activeSubCategory = subCategory;
    this.filterConversations(this.activeCategory, this.activeSubCategory);
  }

  async ngOnInit() {
    try {
      const tab = this.activatedRoute.snapshot.queryParams.tab;
      const tabName = this.tabIndex[tab];
      await this.loadData(tabName ? tabName : 'all');
      if (tabName) {
        this.activeCategory = this.tabIndex[tab];
      } else {
        this.activeCategory = 'all';
      }
      this.activeSubCategory = 'all';
      this.loaderService.stopLoader();
    } catch(e) {
      this.loaderService.stopLoader();
      this.toastService.showError('There was an error retrieving your conversations');
    }
  }

  async loadData(category = 'all') {
    this.loaderService.triggerLoader();
    const data = await this.textMessagesService.getConversations().toPromise();
  
    // Group all messages by timestamp
    this.conversations = data.result.sort((a, b) => {
      return +new Date(b.mostRecentActivity) - +new Date(a.mostRecentActivity)
    });
    this.filterConversations(category, 'all');
  }

  getConversation(thread) {
    if (!thread) {
      this.selectedThread = null;
    } else {
      thread.read = true;
      this.loaderService.triggerLoader();
      this.selectedThread = thread;
      this.textMessagesService.getConversation(thread.contactId).subscribe((data) => {
        this.activeConversation = data.result;
        setTimeout(function() {
          var elem = document.getElementById('chat-window');
          elem.scrollTop = elem.scrollHeight;
        });
        this.loaderService.stopLoader();
        // Mark conversation as read.
        this.textMessagesService.updateConversation(thread._id, {read: true}).subscribe();
        this.textMessagesService.updateUnreadCountInternally({conversationId: thread._id, read: true});
      }, (e) => {
        this.loaderService.stopLoader();
        this.toastService.showError('There was an error retrieving your conversations');
      });
    }
  }

  sendText(e) {
    this.sendingText = true;
    e.preventDefault();
    this.textMessagesService.replyToText({
      to: this.selectedThread.contact.phoneNumber,
      message: this.replyMessage,
      contactId: this.selectedThread.contact._id,
      conversationId: this.selectedThread._id
    }).subscribe((data) => {
      this.activeConversation.push(data.result);
      this.sendingText = false;
      setTimeout(function() {
        var elem = document.getElementById('chat-window');
        elem.scrollTop = elem.scrollHeight;
      });
    }, (e) => {
      this.sendingText = false;
      this.toastService.showError(e.cause || 'There was an error sending your text message');
    });
    this.replyMessage = '';
  }

  goToContact(id) {
    this.router.navigate(['/contact'], { queryParams: { id, backTo: '/conversations' } });
  }

  checkOptInStatus(contactId) {
    this.contactService.checkOptInStatus(contactId).subscribe((data) => {
      if (data.result.optout) {
        this.optOutMessage = 'Opt In';
      } else {
        this.optOutMessage = 'Opt Out';
      }
      this.modalService.open(this.optOutModal).result.then(() => {
        //no-op
      }, () => {
        //no-op
      });
    });
  }

  changeOptInStatus(modal, contactId) {
    this.optOutInProgress = true;
    var optOut = this.optOutMessage === 'Opt Out';
    this.contactService.optOutContact(contactId, {optout: optOut}).subscribe(() => {
      this.optOutInProgress = true;
      this.toastService.show('Opt-out status changed!', {
        classname: 'bg-success text-light',
        delay: 5000
      });
      modal.close();
    }, () => {
      this.toastService.show('There was an error changing opt-out status', {
        classname: 'bg-danger text-light',
        delay: 5000
      });
      modal.dismiss();
    });
  }

  exitModal(e, modal) {
    e.preventDefault();
    modal.dismiss();
  }

  filterConversations(category, group) {
    this.activeCategory = category;
    this.activeSubCategory = group;
    switch (category) {
      case 'unread':
        const unreadList = this.conversations.filter((conversation) => {
          return conversation.read === false;
        });
        this.selectedConversationType = this.filterConversationGroup(group, unreadList);
        this.getConversation(this.selectedConversationType[0]);
        break;
      case 'prospects':
        const prospectList = this.conversations.filter((conversation) => {
          return conversation.contact.type === 'prospect';
        });
        this.selectedConversationType = this.filterConversationGroup(group, prospectList);
        this.getConversation(this.selectedConversationType[0]);
        break;
      case 'residents':
        const residentList = this.conversations.filter((conversation) => {
          return conversation.contact.type === 'resident';
        });
        this.selectedConversationType = this.filterConversationGroup(group, residentList);
        this.getConversation(this.selectedConversationType[0]);
        break;
      case 'awaiting':
        const awaitingResponseList = this.conversations.filter((conversation) => {
          return conversation.mostRecentSender !== this.assignedNumber;
        });
        this.selectedConversationType = this.filterConversationGroup(group, awaitingResponseList);
        this.getConversation(this.selectedConversationType[0]);
        break;
      case 'all':
      default:
        this.selectedConversationType = this.filterConversationGroup(group, this.conversations);
        this.getConversation(this.selectedConversationType[0]);
        break;
    }
  }

  filterConversationGroup(group, array) {
    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);
    const lastMonth = new Date();
    lastMonth.setMonth(lastWeek.getMonth() - 1);
    switch (group) {
      case 'lastWeek':
        return array.filter((conversation) => {
          return new Date(conversation.mostRecentActivity) > lastWeek;
        });
      case 'lastMonth':
        return array.filter((conversation) => {
          return new Date(conversation.mostRecentActivity) > lastMonth;
        });
      case 'all':
      default:
        return array;
    }
  }
}