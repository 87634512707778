<div class="conversations-wrapper">
  <!-- Conversations Sidebar -->
  <nav class="conversations-sidebar">
    <div class="sidebar-header">
      <h2 class="page-title">Conversations</h2>
    </div>
    <ul class="nav flex-column">
      <li class="nav-item">
        <a (click)="setActiveCategory('all')">
          <div class="nav-item-content">
            <div class="nav-icon">
              <i class="far fa-comments"></i>
            </div>
            <div class="nav-text">
              All
            </div>
          </div>
        </a>
        <ul class="nav sub-nav flex-column" [ngStyle]="{'display': activeCategory === 'all' ? 'block' : 'none'}">
          <li class="nav-item">
            <a (click)="setActiveSubCategory('all')" [class.active]="activeSubCategory === 'all'">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-clock"></i>
                </div>
                <div class="nav-text">
                  All Time
                </div>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a (click)="setActiveSubCategory('lastWeek')" [class.active]="activeSubCategory === 'lastWeek'">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-calendar-week"></i>
                </div>
                <div class="nav-text">
                  This Week
                </div>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a (click)="setActiveSubCategory('lastMonth')" [class.active]="activeSubCategory === 'lastMonth'">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-calendar-alt"></i>
                </div>
                <div class="nav-text">
                  This Month
                </div>
              </div>
            </a>
          </li>
        </ul>
      </li>
      <li class="nav-item">
        <a (click)="setActiveCategory('awaiting')">
          <div class="nav-item-content">
            <div class="nav-icon">
              <i class="far fa-bell"></i>
            </div>
            <div class="nav-text">
              Awaiting Response
            </div>
          </div>
        </a>
        <ul class="nav sub-nav flex-column" [ngStyle]="{'display': activeCategory === 'awaiting' ? 'block' : 'none'}">
          <li class="nav-item">
            <a (click)="setActiveSubCategory('all')" [class.active]="activeSubCategory === 'all'">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-clock"></i>
                </div>
                <div class="nav-text">
                  All Time
                </div>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a (click)="setActiveSubCategory('lastWeek')" [class.active]="activeSubCategory === 'lastWeek'">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-calendar-week"></i>
                </div>
                <div class="nav-text">
                  This Week
                </div>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a (click)="setActiveSubCategory('lastMonth')" [class.active]="activeSubCategory === 'lastMonth'">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-calendar-alt"></i>
                </div>
                <div class="nav-text">
                  This Month
                </div>
              </div>
            </a>
          </li>
        </ul>
      </li>
      <li class="nav-item">
        <a (click)="setActiveCategory('unread')">
          <div class="nav-item-content">
            <div class="nav-icon">
              <i class="far fa-envelope"></i>
            </div>
            <div class="nav-text">
              Unread
            </div>
          </div>
        </a>
        <ul class="nav sub-nav flex-column" [ngStyle]="{'display': activeCategory === 'unread' ? 'block' : 'none'}">
          <li class="nav-item">
            <a (click)="setActiveSubCategory('all')" [class.active]="activeSubCategory === 'all'">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-clock"></i>
                </div>
                <div class="nav-text">
                  All Time
                </div>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a (click)="setActiveSubCategory('lastWeek')" [class.active]="activeSubCategory === 'lastWeek'">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-calendar-week"></i>
                </div>
                <div class="nav-text">
                  This Week
                </div>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a (click)="setActiveSubCategory('lastMonth')" [class.active]="activeSubCategory === 'lastMonth'">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-calendar-alt"></i>
                </div>
                <div class="nav-text">
                  This Month
                </div>
              </div>
            </a>
          </li>
        </ul>
      </li>
      <li class="nav-item">
        <a (click)="setActiveCategory('prospects')">
          <div class="nav-item-content">
            <div class="nav-icon">
              <i class="far fa-user"></i>
            </div>
            <div class="nav-text">
              Prospects
            </div>
          </div>
        </a>
        <ul class="nav sub-nav flex-column" [ngStyle]="{'display': activeCategory === 'prospects' ? 'block' : 'none'}">
          <li class="nav-item">
            <a (click)="setActiveSubCategory('all')" [class.active]="activeSubCategory === 'all'">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-clock"></i>
                </div>
                <div class="nav-text">
                  All Time
                </div>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a (click)="setActiveSubCategory('lastWeek')" [class.active]="activeSubCategory === 'lastWeek'">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-calendar-week"></i>
                </div>
                <div class="nav-text">
                  This Week
                </div>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a (click)="setActiveSubCategory('lastMonth')" [class.active]="activeSubCategory === 'lastMonth'">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-calendar-alt"></i>
                </div>
                <div class="nav-text">
                  This Month
                </div>
              </div>
            </a>
          </li>
        </ul>
      </li>
      <li class="nav-item">
        <a (click)="setActiveCategory('residents')">
          <div class="nav-item-content">
            <div class="nav-icon">
              <i class="far fa-home"></i>
            </div>
            <div class="nav-text">
              Residents
            </div>
          </div>
        </a>
        <ul class="nav sub-nav flex-column" [ngStyle]="{'display': activeCategory === 'residents' ? 'block' : 'none'}">
          <li class="nav-item">
            <a (click)="setActiveSubCategory('all')" [class.active]="activeSubCategory === 'all'">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-clock"></i>
                </div>
                <div class="nav-text">
                  All Time
                </div>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a (click)="setActiveSubCategory('lastWeek')" [class.active]="activeSubCategory === 'lastWeek'">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-calendar-week"></i>
                </div>
                <div class="nav-text">
                  This Week
                </div>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a (click)="setActiveSubCategory('lastMonth')" [class.active]="activeSubCategory === 'lastMonth'">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-calendar-alt"></i>
                </div>
                <div class="nav-text">
                  This Month
                </div>
              </div>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
  <div class="conversations-content">
    <nav class="conversations-sidebar">
      <ul class="nav flex-column">
        <li class="nav-item" *ngFor="let thread of selectedConversationType; let i = index">
          <a 
            (click)="getConversation(thread)"
            [ngClass]="{'unread': thread.read === false, 'active': selectedThread && (selectedThread._id === thread._id)}"
          >
            <div class="nav-item-content">
              <div class="nav-icon">
                <i class="far fa-user"></i>
              </div>
              <div class="nav-text">
                <p class="contact-name">{{thread.contact.firstName}}</p>
                <p class="truncate message-preview">{{thread.body}}</p>
                <span class="thread-date">{{thread.mostRecentActivity | date:'h:mm a'}}&nbsp;{{thread.mostRecentActivity | date:'M/d/yy'}}</span>
              </div>
            </div>
          </a>
        </li>
        <li *ngIf="selectedConversationType.length === 0">
          <a>
            <div class="nav-item-content">
              <div class="nav-text no-message-text">
                No Messages
              </div>
            </div>
          </a>
        </li>
      </ul>
    </nav>
    <div class="conversations-content">
      
      <!-- Conversation Chat Bubbles-->
      <div class="chat-wrapper" *ngIf="selectedThread">
        <div class="message-action-wrap">
          <h4 class="contact-click pull-left" (click)="goToContact(selectedThread.contact._id)">{{selectedThread.contact.contactIdentifier}} - {{selectedThread.contact.phoneNumber | phone}}</h4>
          <div class="button button-small pull-right button-dark" (click)="checkOptInStatus(selectedThread.contact._id)">Opt In/Out</div>
        </div>
        <div id="chat-window">
          <div class="chat-bubble-wrap" *ngFor="let message of activeConversation; let i = index">
            <p [ngClass]="{'from-them': message.from === selectedThread.contact.phoneNumber || message.from === 'visitor', 'from-me': message.to === selectedThread.contact.phoneNumber, 'from-bot': message.from === 'bot'}">
              {{message.body}}
            </p>
            <span [ngClass]="{'them-time': message.from === selectedThread.contact.phoneNumber || message.from === 'visitor',
                'me-time': message.to === selectedThread.contact.phoneNumber || message.to === 'user'}"
                class="timestamp">
              <ng-container *ngIf="message.from === 'bot'">
                <i class="far fa-user-robot"></i>
                ChatBot on&nbsp;
              </ng-container>
              {{message.updated | date:'MMM d, y, h:mm a'}}
            </span>
          </div>
        </div>
        <div class="reply-input">
          <textarea rows="3" placeholder="Reply..." [(ngModel)]="replyMessage"
          [ngModelOptions]="{standalone: true}" class="form-control reply-field"></textarea>
          <div>
            <button class="button button-dark button-small submit-button" [disabled]="sendingText" (click)="sendText($event)"><span *ngIf="sendingText" class="spinner-border spinner-border-sm mr-1"></span>Send</button>
          </div>
        </div>
      </div>

      <!-- No Conversation Selected or Available -->
      <div *ngIf="!selectedThread" class="full-width padding-top-48">
        <div id="chat-window">
          <p class="text-center"><img src="/assets/no-messaging.png" /></p>
          <h3 class="text-center">
            Select a conversation to get started!
          </h3>
        </div>
      </div>

      <!-- Messaging Disabled -->
      <div *ngIf="!registeredUserData.user.twilioAccountId" class="messaging-disabled">
        <p class="text-center full-width margin-top-48">
          <img src="/assets/no-messaging.png" />
        </p>
        <h3 class="full-width margin-top-48">You do not have two way messaging enabled, please contact <a href="mailto:customersuccess@nurtureboss.io">customersuccess@nurtureboss.io</a> to enable this for your account!</h3>
      </div>
    </div>
  </div>
</div>

<!-- Opt Out Modal -->
<ng-template #optOutModal let-optOutModal>
  <div class="modal-header">
      <h5 class="modal-title">{{optOutMessage}} Contact</h5>
      <div class="float-right">
          <button type="button" class="button button-white button-small" (click)="exitModal($event, optOutModal)"><i class="far fa-times" aria-hidden="true"></i></button>
      </div>
  </div>
  <div class="schedule-text-modal modal-body">
      <div class="alert alert-danger"><strong>Warning:</strong> You are about to {{optOutMessage}} this contact. Please be sure this is your intention.</div>
      <button (click)="changeOptInStatus(optOutModal, selectedThread.contact._id)" [disabled]="optOutInProgress" class="button button-dark">
          <span *ngIf="optOutInProgress" class="spinner-border spinner-white spinner-border-sm mr-1"></span>
          {{optOutMessage}} Contact
      </button>
  </div>
</ng-template>