<div id="contacts-table" class="margin-top-24">
  <div class="overflow-auto step-instruction-wrap">
    <div>
      <span class="step-instructions pull-left">{{headerLabel}}</span>
      <span *ngIf="!!headerTooltip" class="step-instructions" [ngbTooltip]="headerTooltip">&nbsp;<i class="far fa-question-circle"></i></span>
    </div>
    <button *ngIf="showPrimaryButton()" class="button button-dark pull-right"
      [disabled]="!isPrimaryEnabled"
      (click)="onButtonClick($event, 'primary')">{{primaryButtonText}}</button>
    <button *ngIf="showSecondaryButton()" class="button button-white pull-right margin-right-12"
      [disabled]="!isSecondaryEnabled"
      (click)="onButtonClick($event, 'secondary')">{{secondaryButtonText}}</button>
    <button *ngIf="showTernaryButton()" class="button button-red pull-right margin-right-12"
      [disabled]="!isTernaryEnabled"
      (click)="onButtonClick($event, 'ternary')">{{ternaryButtonText}}</button>
  </div>
  <div class="overflow-auto table-actions">
    <p class="table-row-indicator" *ngIf="totalRecords > 0"><strong>Showing {{pagination.first}} to {{pagination.last + pagination.first - 1}} of {{totalRecords}} records</strong></p>
    <p class="table-row-indicator" *ngIf="totalRecords === 0">
      <strong>No contacts found</strong>
    </p>
    <i class="fa-regular fa-columns-3 column-selector" (click)="openColumnSelectorModal($event)"></i>
  </div>
  <div class="crm-prospect-table-wrap">
    <p-table
      #contactsTable
      [value]="normalizedContacts"
      dataKey="_id"
      selectionMode="multiple"
      styleClass="ui-table-customers"
      [rowHover]="true"
      [rows]="25"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[25,50,100,250,500,1000]"
      [loading]="loading"
      [paginator]="true"
      [filterDelay]="0"
      [lazy]="true"
      totalRecords="{{totalRecords}}"
      (onPage)="paginate($event)"
      [(selection)]="selectedContacts"
      (onRowSelect)="onRowSelect($event)"
      (onRowUnselect)="onRowUnselect($event)"
      (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event)"
    >
      <ng-template pTemplate="header">
        <!-- Table Filters -->
        <tr *ngIf="!valuesOverride">
          <th *ngIf="allowRowSelect" class="hascheck">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th *ngIf="columns.name">
            <input pInputText type="text" placeholder="Search" [(ngModel)]="contactNameSearchText" (ngModelChange)="debouncedSearch(contactNameSearchText, 'contactIdentifier') "placeholder="Search by Name" class="ui-column-filter">
          </th>
          <th *ngIf="columns.created">
            <p-calendar #createdCalendar selectionMode="range" (onSelect)="onDateSelect($event, 'created')"
              (onClearClick)="clearDateFilter('created')" [showButtonBar]="true"
              styleClass="ui-column-filter" placeholder="Created Date" [readonlyInput]="true" dateFormat="mm-dd-yy">
            </p-calendar>
          </th>
          <th *ngIf="columns.status">
            <p-multiSelect [options]="dynamicFilterFields['status']" placeholder="All"
              (onChange)="onFilterChange($event, 'status')" styleClass="ui-column-filter">
            </p-multiSelect>
          </th>
          <th *ngIf="columns.type">
            <p-multiSelect [options]="dynamicFilterFields['type']" placeholder="All"
              (onChange)="onFilterChange($event, 'type')" styleClass="ui-column-filter">
            </p-multiSelect>
          </th>
          <th *ngIf="columns.stage">
            <p-multiSelect [options]="dynamicFilterFields['stage']" placeholder="All" (onChange)="onFilterChange($event, 'stage')"
              styleClass="ui-column-filter">
            </p-multiSelect>
          </th>
          <th *ngIf="columns.lastActivity"></th>
          <th *ngIf="columns.source">
            <p-multiSelect [options]="dynamicFilterFields['source']" placeholder="All"
              (onChange)="onFilterChange($event, 'source')" styleClass="ui-column-filter">
            </p-multiSelect>
          </th>
          <th *ngIf="columns.beds">
              <p-multiSelect [options]="dynamicFilterFields['desiredBedCount']" placeholder="All"
              (onChange)="onFilterChange($event, 'desiredBedCount')" styleClass="ui-column-filter">
            </p-multiSelect>
          </th>
          <th *ngIf="columns.commTypes">
            <p-multiSelect *ngIf="!textOnly" [options]="communicationFilterOptions" placeholder="All"
              (onChange)="onFilterChange($event, 'communicationFilter')" styleClass="ui-column-filter">
            </p-multiSelect>
          </th>
          <th *ngIf="columns.pets">
            <p-multiSelect *ngIf="!textOnly" [options]="petFilterOptions" placeholder="All"
              (onChange)="onFilterChange($event, 'pets')" styleClass="ui-column-filter">
            </p-multiSelect>
          </th>         
          <th *ngIf="columns.unit">
            <input pInputText type="text" placeholder="Search" [(ngModel)]="contactUnitSearchText" (ngModelChange)="debouncedSearch(contactUnitSearchText, 'unit') "placeholder="Search Unit" class="ui-column-filter">
          </th>
          <th *ngIf="columns.renewedLeaseStartDate">
            <p-calendar 
              #renewedLeaseStartDateCalendar
              id="renewedLeaseStartDateCalendar" 
              selectionMode="range" 
              (onSelect)="onDateSelect($event, 'renewedLeaseStartDate')"
              (onClearClick)="clearDateFilter('renewedLeaseStartDate')" 
              [showButtonBar]="true"
              styleClass="ui-column-filter" 
              placeholder="Renewal Date" 
              [readonlyInput]="true" 
              dateFormat="mm-dd-yy">
            </p-calendar>
          </th>
        </tr>
        <tr>
          <th *ngIf="allowRowSelect" class="hascheck"></th>
          <th *ngIf="columns.name">Name
          </th>
          <th *ngIf="columns.created">Created
          </th>
          <th *ngIf="columns.status">Status
          </th>
          <th *ngIf="columns.type">Type
          </th>
          <th *ngIf="columns.stage">Stage
          </th>
          <th *ngIf="columns.lastActivity">Last Activity
          </th>
          <th *ngIf="columns.source">Source
          </th>
          <th *ngIf="columns.beds">Beds
          </th>
          <th *ngIf="columns.commTypes">Comm. Types
          </th>
          <th *ngIf="columns.pets">Has Pets
          </th>
          <th *ngIf="columns.unit">Unit
          </th>
          <th *ngIf="columns.renewedLeaseStartDate">Renewal Date
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-prospect let-rowData>
        <tr class="ui-selectable-row">
          <td *ngIf="allowRowSelect" class="hascheck">
            <p-tableCheckbox [value]="rowData" ></p-tableCheckbox>
          </td>
          <td *ngIf="columns.name">
            <a *ngIf="linkToContactPage" class="clickable" (click)="viewContact(prospect._id, $event)">{{prospect.clientFullName}}</a>
            <span *ngIf="!linkToContactPage">{{prospect.clientFullName}}</span>
          </td>
          <td *ngIf="columns.created">
            {{prospect.created | date:'shortDate'}}
          </td>
          <td *ngIf="columns.status">
            <span [class]="'flag flag-us'"></span>
            <span style="vertical-align: middle; margin-left: .5em">{{prospect.status || 'Unknown'}}</span>
          </td>
          <td *ngIf="columns.type">
            {{(prospect.type || 'Unknown') | titlecase}}
          </td>
          <td *ngIf="columns.stage">
            <span *ngIf="prospect.stage === 'Pre Tour' && prospect.type !== 'resident'"
              [class]="'customer-badge status-tour'">{{prospect.stage}}</span>
            <span *ngIf="prospect.stage === 'Pre Application' && prospect.type !== 'resident'"
              [class]="'customer-badge status-application'">{{prospect.stage}}</span>
            <span *ngIf="prospect.stage === 'Pre Move In' && prospect.type !== 'resident'"
              [class]="'customer-badge status-lease'">{{prospect.stage}}</span>
            <span *ngIf="prospect.stage === 'Leasing/Leased' && prospect.type !== 'resident'"
              [class]="'customer-badge status-leased'">{{prospect.stage}}</span>
            <span *ngIf="prospect.type === 'resident' || (prospect.type === 'occupant' && prospect.stage === 'Resident')" [class]="'customer-badge status-leased'">Resident</span>
            <span *ngIf="(!prospect.stage || prospect.stage === 'Unknown') && prospect.type !== 'resident'"
                  [class]="'customer-badge status-unknown'">{{prospect.stage}}</span>
          </td>
          <td *ngIf="columns.lastActivity">
            {{prospect.recentActivityTimestamp | date:'shortDate'}}
          </td>
          <td *ngIf="columns.source">
            {{prospect.source || 'Unknown'}}
          </td>
          <td *ngIf="columns.beds">
            {{prospect.desiredBedCount || 'Unknown'}}
          </td>
          <td *ngIf="columns.commTypes" class="communication-type">
            <span *ngIf="prospect.communication.includes('hasPhone') && !prospect.communication.includes('hasOptIn')"
              ngbTooltip="This contact has a phone number" class="communication-icon"><i
                class="far fa-phone"></i></span>
            <span *ngIf="prospect.communication.includes('hasOptIn')"
              ngbTooltip="This contact has a phone number and has opted in to text messaging"
              class="communication-icon"><i class="far fa-sms"></i></span>
            <span *ngIf="prospect.communication.includes('hasEmail')" ngbTooltip="This contact has an email address"
              class="communication-icon"><i class="far fa-envelope"></i></span>
          </td>
          <td *ngIf="columns.pets" class="communication-type">
            <span *ngIf="prospect.pets"
              ngbTooltip="This contact has at least one pet" class="communication-icon"><i
                class="far fa-paw"></i></span>
          </td>
          <td *ngIf="columns.unit">
            {{prospect.unit}}
          </td>
          <td *ngIf="columns.renewedLeaseStartDate">
            {{prospect.renewedLeaseStartDate | date:'shortDate'}}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="emptyColspan" style="text-align:left">No contacts found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<app-contact-table-column-selector 
  [columns]="columns"
  (toggleColumn)="updateColumns($event)"
></app-contact-table-column-selector>