<div class="page hp-setup">
  <div class="template-defaults-title">
    <h6 class="title pull-left">Hyper Personalization</h6>
  </div>
  <div class="row hp-thumbnails">
    <div class="col-lg-3 col-md-4 col-sm-6">
      <div class="hp-thumbnail">
        <img src="/assets/dog-icon.png" />
        <h6 class="text-center">Pets</h6>
        <button class="button button-dark button-small" (click)="configureHpData('pets', configurepethpmodal)">
          <i class="far fa-robot" aria-hidden="true"></i> Configure
        </button>
      </div>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6" *ngIf="canUseAvailabilityHP">
      <div class="hp-thumbnail">
        <img src="/assets/availability-hyper-personalization.png" />
        <h6 class="text-center">Availability</h6>
        <button class="button button-dark button-small" (click)="configureHpData('availability', configureavailabilityhpmodal)">
          <i class="far fa-robot" aria-hidden="true"></i> Configure
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Global Defaults -->
<div class="page">
  <div class="overflow-auto">
    <h6 class="title pull-left">Global Defaults</h6>
    <button class="button button-dark pull-right" [disabled]="savingDefaults" (click)="openSaveDefaultsModal($event)">
      <span *ngIf="savingDefaults" class="spinner-border spinner-border-sm mr-1"></span>
      Save/Reset Defaults
    </button>
  </div>
  <app-spam-warnings
    [warningText]="'WARNING: Your Global Defaults contain words that are known to trigger Spam Filters for various Email Service Providers. Problomatic words found:'"
    [spamWarnings]="spamWarnings"
  ></app-spam-warnings>
  <div class="settings-table" [formGroup]="globalDefaults">
    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Banner Image</h6>
        <p class="setting-description">The banner image is displayed in email templates and nurture pages.</p>
        <div *ngIf="!globalDefaults.controls.bannerImage.value && !loadingImage.bannerImage" class="missing-image">* Missing Banner Image Default</div>
        <img class="preview-image" *ngIf="globalDefaults.controls.bannerImage.value && !loadingImage.bannerImage" [src]="globalDefaults.controls.bannerImage.value" />
        <p *ngIf="globalDefaults.controls.bannerImage.value && !loadingImage.bannerImage" (click)="resetImage('bannerImage', 'globalDefaults')" class="remove-default"><i class="far fa-trash-alt"></i> Remove Image</p>
        <div *ngIf="loadingImage.bannerImage" class="spinner-border spinner-border-sm"></div>
        <div class="media-manager-container">
          <app-file-type-image
            inputId="bannerImageUpload"
            buttonStyle="button button-small"
            *ngIf="!globalDefaults.controls.bannerImage.value && !loadingImage.bannerImage"
            (onFilesPicked)="uploadMedia($event.target.files, 'bannerImage', 'globalDefaults')"
          ></app-file-type-image>
          <p *ngIf="!globalDefaults.controls.bannerImage.value && !loadingImage.bannerImage" class="media-manager-helper-text">or</p>
          <button *ngIf="!globalDefaults.controls.bannerImage.value && !loadingImage.bannerImage" (click)="openMediaModal($event, 'bannerImage', 'globalDefaults')" class="button button-white button-small"><i class="far fa-image"></i> Select From Media Manager</button>
        </div>
      </div>
    </div>

    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Logo</h6>
        <p class="setting-description">The logo is displayed in email templates and nurture pages.</p>
        <div *ngIf="!globalDefaults.controls.leftLogo.value && !loadingImage.leftLogo" class="missing-image">* Missing Logo Default</div>
        <img class="preview-image" *ngIf="globalDefaults.controls.leftLogo.value && !loadingImage.leftLogo" [src]="globalDefaults.controls.leftLogo.value" />
        <p *ngIf="globalDefaults.controls.leftLogo.value && !loadingImage.leftLogo" (click)="resetImage('leftLogo', 'globalDefaults')" class="remove-default"><i class="far fa-trash-alt"></i> Remove Image</p>
        <div *ngIf="loadingImage.leftLogo" class="spinner-border spinner-border-sm"></div>
        <div class="media-manager-container">
          <app-file-type-image
            inputId="leftLogoUpload"
            buttonStyle="button button-small"
            *ngIf="!globalDefaults.controls.leftLogo.value && !loadingImage.leftLogo"
            (onFilesPicked)="uploadMedia($event.target.files, 'leftLogo', 'globalDefaults')"
          ></app-file-type-image>
          <p *ngIf="!globalDefaults.controls.leftLogo.value && !loadingImage.leftLogo" class="media-manager-helper-text">or</p>
          <button *ngIf="!globalDefaults.controls.leftLogo.value && !loadingImage.leftLogo" (click)="openMediaModal($event, 'leftLogo', 'globalDefaults')" class="button button-small button-white"><i class="far fa-image"></i> Select From Media Manager</button>
        </div>
        
      </div>
    </div>

    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Current Concession</h6>
        <p class="setting-description">The concession is automatically inserted into some template and accessible via a token in others.</p>
        <input type="text" class="form-control" formControlName="concession" id="concession" aria-describedby="concession" placeholder="Enter property concession">
      </div>
    </div>

    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Property Website URL</h6>
        <p class="setting-description">This is the URL to direct prospects and residents to your property website.</p>
        <input type="text" class="form-control" formControlName="yourWebsite" id="yourWebsite" aria-describedby="yourWebsite" placeholder="Enter property website">
      </div>
    </div>

    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Concession Expiration Date (Optional)</h6>
        <p class="setting-description">This is the date that the concession will be removed from the system. If no date is entered the concession will never expire.</p>
        <p-calendar
          [showIcon]="true"
          inputId="concessionExpirationDate"
          formControlName="concessionExpirationDate"
          [minDate]="tomorrow"
          placeholder="Concession Expiration Date"
          [readonlyInput]="true"
          dateFormat="mm-dd-yy"
          [showButtonBar]="true"
          todayButtonStyleClass="d-none"
          [style]="{ width: '100%', border: '1px solid #ced4da' }"
          [inputStyle]="{
            width: 'calc(100% - 33px)',
            border: 'none',
            fontFamily: 'Lato, sans-serif',
            fontSize: '14px',
            padding: '.375rem .75rem'
          }"
          styleClass="calendar-input"
        ></p-calendar>
      </div>
    </div>

    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Property Email Address (Tracking Email Preferred)</h6>
        <p class="setting-description">This is the email address that will be shared with prospects and residents.</p>
        <input type="text" class="form-control" formControlName="yourEmailAddress" id="yourEmailAddress" aria-describedby="yourEmailAddress" placeholder="Enter property email address">
      </div>
    </div>

    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Property Phone Number (Tracking Number Preferred)</h6>
        <p class="setting-description">This is the phone number that will be shared with prospects and residents.</p>
        <input type="text" class="form-control" formControlName="yourPhoneNumber" id="yourPhoneNumber" aria-describedby="yourPhoneNumber" placeholder="Enter property phone number">
      </div>
    </div>

    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Resident Portal URL</h6>
        <p class="setting-description">This is the URL to direct residents to your resident portal.</p>
        <input type="text" class="form-control" formControlName="residentPortalUrl" id="residentPortalUrl" aria-describedby="residentPortalUrl" placeholder="Enter online resident portal URL">
      </div>
    </div>

    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Schedule a Tour URL</h6>
        <p class="setting-description">This is the URL to direct prospects to schedule a tour.</p>
        <input type="text" class="form-control" formControlName="scheduleATourUrl" id="scheduleATourUrl" aria-describedby="scheduleATourUrl" placeholder="Enter schedule a tour url">
      </div>
    </div>

    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Apply Online URL</h6>
        <p class="setting-description">This is the URL to direct prospects to apply online.</p>
        <input type="text" class="form-control" formControlName="applyOnlineUrl" id="applyOnlineUrl" aria-describedby="applyOnlineUrl" placeholder="Enter online application url">
      </div>
    </div>

    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Online Review URL</h6>
        <p class="setting-description">This is the URL to direct residents to leave an online review.</p>
        <input type="text" class="form-control" formControlName="onlineReviewUrl" id="onlineReviewUrl" aria-describedby="onlineReviewUrl" placeholder="Enter online review url">
      </div>
    </div>

    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Amenities URL</h6>
        <p class="setting-description">This is the URL to direct prospects to your amenities page.</p>
        <input type="text" class="form-control" formControlName="amenitiesUrl" id="amenitiesUrl" aria-describedby="amenitiesUrl" placeholder="Enter amenities page url">
      </div>
    </div>

    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Floor Plans URL</h6>
        <p class="setting-description">This is the URL to direct prospects to your floor plans page.</p>
        <input type="text" class="form-control" formControlName="floorPlansUrl" id="floorPlansUrl" aria-describedby="floorPlansUrl" placeholder="Enter floor plans page url">
      </div>
    </div>

    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Image Gallery URL</h6>
        <p class="setting-description">This is the URL to direct prospects to your image gallery page.</p>
        <input type="text" class="form-control" formControlName="imageGalleryUrl" id="imageGalleryUrl" aria-describedby="imageGalleryUrl" placeholder="Enter gallery page url">
      </div>
    </div>

    <div class="setting-row align-items-baseline no-border">
      <div class="setting-content no-padding">
        <h6>Instagram Handle <i class="fab fa-instagram"></i></h6>
        <p class="setting-description">This is the Instagram handle to be displayed in the footer of your emails and nurture pages.</p>
        <input type="text" class="form-control" formControlName="instagramHandle" id="instagramHandle" aria-describedby="instagramHandle" placeholder="@nurtureboss">
      </div>
    </div>

    <div class="setting-row align-items-baseline no-border">
      <div class="setting-content no-padding">
        <h6>Facebook URL <i class="fab fa-facebook"></i></h6>
        <p class="setting-description">This is the Facebook URL to be displayed in the footer of your emails and nurture pages.</p>
        <input type="text" class="form-control" formControlName="facebookUrl" id="facebookUrl" aria-describedby="facebookUrl" placeholder="https://www.facebook.com/nutureboss">
      </div>
    </div>
  </div>
</div>

<app-media-modal (imageSubmittedEvent)="handleImageSubmitEvent($event)"></app-media-modal>
<app-save-defaults-modal [globalDefaults]="globalDefaults"></app-save-defaults-modal>

<!-- Pet HP Data Configuration Modal -->
<ng-template #configurepethpmodal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Pet Hyper Personalization Configuration</h5>
    <div class="float-right">
        <button type="button" class="button button-white button-small" (click)="exitModal($event, modal)">
          <i class="far fa-times" aria-hidden="true"></i>
        </button>
    </div>
  </div>
  <div class="modal-body configure-hp-modal">
    <div class="settings-table" [formGroup]="hyperpersonalizationData" (ngSubmit)="saveHyperpersonalization(modal)">
      <div class="setting-row align-items-baseline">
        <div class="setting-content no-padding">
          <h6>Pet Image</h6>
          <p class="setting-description">This image is inserted into content sent to pet owners.</p>
          <div *ngIf="!hyperpersonalizationData.controls.petImage.value && !loadingImage.petImage" class="missing-image">* Missing Pet Image Default</div>
          <img class="preview-image" *ngIf="hyperpersonalizationData.controls.petImage.value && !loadingImage.petImage" [src]="hyperpersonalizationData.controls.petImage.value" />
          <p *ngIf="hyperpersonalizationData.controls.petImage.value && !loadingImage.petImage" (click)="resetImage('petImage', 'hyperpersonalizationData')" class="remove-default"><i class="far fa-trash-alt"></i> Remove Image</p>
          <div *ngIf="loadingImage.petImage" class="spinner-border spinner-border-sm"></div>
          <div class="media-manager-container">
            <app-file-type-image
              inputId="petImageUpload"
              buttonStyle="button button-small"
              *ngIf="!hyperpersonalizationData.controls.petImage.value && !loadingImage.petImage"
              (onFilesPicked)="uploadMedia($event.target.files, 'petImage', 'hyperpersonalizationData')"
            ></app-file-type-image>
            <p *ngIf="!hyperpersonalizationData.controls.petImage.value && !loadingImage.petImage" class="media-manager-helper-text">or</p>
            <button *ngIf="!hyperpersonalizationData.controls.petImage.value && !loadingImage.petImage" (click)="openMediaModal($event, 'petImage', 'hyperpersonalizationData')" class="button button-white button-small"><i class="far fa-image"></i> Select From Media Manager</button>
          </div>
        </div>
      </div>
      <div class="setting-row align-items-baseline">
        <div class="setting-content no-padding">
          <h6>Pet Image Label</h6>
          <p class="setting-description">This text will be inserted into content sent to pet owners.</p>
          <input type="text" class="form-control" formControlName="petImageLabel" id="petImageLabel" aria-describedby="petImageLabel" placeholder="Enter pet image label">
        </div>
      </div>
      <div class="setting-row align-items-baseline no-border">
        <div class="setting-content no-padding">
          <h6>Pet Policy</h6>
          <p class="setting-description">Additional content that may be inserted into content sent to pet owners.</p>
          <textarea class="form-control" formControlName="petPolicy" id="petPolicy" aria-describedby="petPolicy" placeholder="Enter pet policy"></textarea>
        </div>
      </div>
      <div class="setting-row no-border">
        <div class="setting-icon">
          <i class="fas fa-paw"></i>
        </div>
        <div class="setting-content">
          <h6>Pet Hyper Personalization</h6>
          <p class="setting-description">Enable dynamic pet hyper personalization content for your messages.</p>
        </div>
        <label class="switch">
          <input type="checkbox" formControlName="petActive" id="petActive">
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <div class="col-md-12 no-margin no-padding">
      <form [formGroup]="hyperpersonalizationData" (ngSubmit)="saveHyperpersonalization(modal)">
        <div class="pull-right">
          <button [disabled]="savingDefaults" class="button button-dark margin-bottom-24 margin-top-12">
            <span *ngIf="savingDefaults" class="spinner-border spinner-border-sm mr-1"></span>
            Save Hyper Personalization Configuration
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<!-- Availability HP Data Configuration Modal -->
<ng-template #configureavailabilityhpmodal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Availability Hyper Personalization Configuration</h5>
    <div class="float-right">
        <button type="button" class="button button-white button-small" (click)="exitModal($event, modal)">
          <i class="far fa-times" aria-hidden="true"></i>
        </button>
    </div>
  </div>
  <div class="modal-body configure-hp-modal">
    <div class="settings-table" [formGroup]="hyperpersonalizationData" (ngSubmit)="saveHyperpersonalization(modal)">
      <div class="setting-row align-items-baseline">
        <div class="setting-row no-border">
          <div class="setting-icon">
            <i class="fas fa-building-user"></i>
          </div>
          <div class="setting-content">
            <h6>Availability Hyper Personalization</h6>
            <p class="setting-description">Enabling Availability Hyper Personalization will allow us to dynamically insert information into follow-ups we send of available units that match the bedroom/bathroom count and desired move in date of each prospect. If no desired bedroom count is specified on the guest card no availability will be shows to that prospect.</p>
          </div>
          <label class="switch">
            <input type="checkbox" formControlName="availabilityActive" id="availabilityActive">
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>
    <div class="col-md-12 no-margin no-padding">
      <form [formGroup]="hyperpersonalizationData" (ngSubmit)="saveHyperpersonalization(modal)">
        <div class="pull-right">
          <button [disabled]="savingDefaults" class="button button-dark margin-bottom-24 margin-top-12">
            <span *ngIf="savingDefaults" class="spinner-border spinner-border-sm mr-1"></span>
            Save Hyper Personalization Configuration
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
