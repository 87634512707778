<div class="player-container" [class.disabled]="disabled">
  <div class="circular-progress">
    <div class="progress-ring" #progressRing></div>
    <button 
      class="player-button" 
      [class.loading]="isLoading || externalLoading"
      (click)="togglePlayPause()" 
      [disabled]="disabled || hasError || (isLoading && !externalLoading)">
      <i [class]="hasError ? 'pi pi-exclamation-circle' : (isPlaying ? 'pi pi-pause' : 'pi pi-play')"></i>
    </button>
  </div>
</div>
