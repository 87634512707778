import { Component, OnInit, ViewChild } from '@angular/core';
import { LoaderService, ContactsService, AuthenticationService, ToastService } from '@app/_services';
import { Table } from 'primeng/table';
import { Router } from '@angular/router';

/* LEGACY IMPORTS FOR CONTACT MANAGEMENT --- KEEP */
// import { ToastService } from '@app/_services';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { Papa } from 'ngx-papaparse';
// import { mergeMap, toArray } from 'rxjs/operators';
// import { from } from 'rxjs';
// import { chunkArray } from '../_utils/arrays';

@Component({
  templateUrl: 'contacts.component.html',
  styleUrls: ['contacts.component.less']
})
export class ContactsComponent implements OnInit {

  @ViewChild('dt') table: Table;

  user: any = null;
  totalRecords = 0;
  activeState = 'hotleads';
  hotLeadData: any = [];
  isKnockUser = false;
  tableColumnStyle = 'repeat(7, minmax(0, 1fr))';
  
  /* LEGACY CODE FOR CONTACT MANAGEMENT --- KEEP */
  // loading: boolean = true;
  // activeStep: number = 1;
  // sampleData: any;
  // contactUploadError: boolean = false;
  // selectedRows: any = [];
  // newLabelData: any = [];
  // labelSaveInProgress: boolean = false;
  // reader: FileReader;
  // fileUploaded: any;
  // parserConfig: any;
  // bulkUploadError: boolean = false;
  // contactUploadRequiredFields: Array<string> = [
  //   'firstName',
  //   'lastName',
  //   'phoneNumber',
  //   'emailAddress',
  //   'optIn'
  // ];
  // dataForUpload: Array<any> = [];

  constructor(
    private authService: AuthenticationService,
    private loaderService: LoaderService,
    private contactsService: ContactsService,
    private router: Router,
    private toastService: ToastService,
    /* LEGACY CODE FOR CONTACT MANAGEMENT --- KEEP */
    // private modalService: NgbModal,
    // private papa: Papa
  ) {
    // no-op
  }

  ngOnInit() {
    this.user = this.authService.currentUserValue.user;
    if (this.user.claims.includes('knock')) {
      this.isKnockUser = true;
      this.tableColumnStyle = 'repeat(6, minmax(0, 1fr))';
    }
    this.loaderService.triggerLoader(true);
    this.getHotLeads();
  }

  async getHotLeads() {
    try {
      const data = await this.contactsService.getHotLeads().toPromise();
      // const data = HOT_LEAD_RESULTS;
      this.hotLeadData = data.result;
      this.hotLeadData = this.hotLeadData.sort((a, b) => {
        return b.leadScore - a.leadScore;
      });
      this.loaderService.stopLoader();
    } catch (e) {

      // TODO Show Toaster Error
      this.loaderService.stopLoader();
      this.toastService.showError('There was an error loading contacts.');
    }
  }

  onTypeChange(event, type) {
    event.preventDefault();
    this.activeState = type;
  }

  viewContact(id, e) {
    e.preventDefault();
    this.router.navigate(['/contact/events'], { queryParams: { id, backTo: '/contacts' }});
  }

  /* LEGACY CODE FOR UPLOADING AND DELETING CONTACTS --- KEEP */
  // refresh(): void {
  //   window.location.reload();
  // }

  // onContactSelectionChange(selectedContacts) {
  //   this.selectedRows = selectedContacts;
  // }

  // exitModal(e, modal) {
  //   e.preventDefault();
  //   modal.dismiss();
  // }

  // addLabelToContacts(modal) {
  //   return (_) => {
  //     this.modalService.open(modal, {
  //       windowClass: 'custom-modal-styles-new'
  //     });
  //   };
  // }

  // createNewContacts(modal) {
  //   return (_) => {
  //     this.dataForUpload = [];
  //     this.bulkUploadError = null;
  //     this.activeStep = 1;
  //     this.fileUploaded = null;
  //     this.contactUploadError = false;
  //     this.modalService.open(modal, {
  //       windowClass: 'custom-modal-styles-new'
  //     }).result.then((result) => {
  //       window.location.reload();
  //     }, () => {
  //       // no-op
  //     });
  //     setTimeout(() => {
  //       this.setListenersForFileUpload();
  //     });
  //   };
  // }

  // saveNewLabel(e, modal) {
  //   this.labelSaveInProgress = true;
  //   e.preventDefault();
  //   var idArray = [];
  //   for (var t = 0; t < this.selectedRows.length; t++) {
  //     idArray.push(this.selectedRows[t]._id);
  //   }
  //   this.contactsService.addLabelToContacts({
  //     label: this.newLabelData,
  //     contactIds: idArray
  //   }).subscribe(() => {
  //     this.newLabelData = [];
  //     this.toastService.show('Label created and added to contacts!', {
  //       classname: 'bg-success text-light',
  //       delay: 5000
  //     });
  //     this.labelSaveInProgress = false;
  //     modal.dismiss();
  //     window.location.reload();
  //   }, (err) => {
  //     console.error('There was an error adding labels ', err);
  //     this.toastService.show('There was an error creating your label', {
  //       classname: 'bg-danger text-light',
  //       delay: 5000
  //     });
  //     this.labelSaveInProgress = false;
  //   });
  // }

  // Event listener to detect user upload of file.
  // setListenersForFileUpload() {
  //   var file;
  //   var config;
  //   document.querySelector('#data-upload').addEventListener('change', () => {
  //     file = (<HTMLInputElement>document.getElementById('data-upload')).files[0];
  //     config = {
  //       delimiter: '', // auto-detect
  //       newline: '', // auto-detect
  //       quoteChar: '\"',
  //       escapeChar: '\"',
  //       header: true,
  //       transformHeader: undefined,
  //       dynamicTyping: false,
  //       preview: 0,
  //       encoding: '',
  //       worker: false,
  //       comments: false,
  //       step: this.validateTemplateContent,
  //       complete: this.onFileUploaded,
  //       error: undefined,
  //       download: false,
  //       downloadRequestHeaders: undefined,
  //       skipEmptyLines: false,
  //       chunk: undefined,
  //       fastMode: undefined,
  //       beforeFirstChunk: undefined,
  //       withCredentials: undefined,
  //       transform: undefined,
  //       delimitersToGuess: [',', '\t', '|', ';', String.fromCharCode(30), String.fromCharCode(31)]
  //     };
  //     this.reader.readAsArrayBuffer(file);
  //   });
  //   this.reader.onload = () => {
  //     this.fileUploaded = file;
  //     this.parserConfig = config;
  //   };
  // }

  // Validate file that user uploads.
  // validateTemplateContent(results, parser) {
  //   parser.pause();
  //   var requiredFields = self.contactUploadRequiredFields;
  //   if (results.errors.length > 0 && !Array.isArray(results.data)) {
  //     console.error('CSV Parser Error: ', results.errors);
  //     self.bulkUploadError = 'Upload Error! Please check your spreadsheet and try again.';
  //     for (var i = 0; i < results.errors.length; i++) {
  //       console.error('There was an error with your uploaded file! ' + results.errors[i]);
  //       self.toastService.show('There was an error with your uploaded file!', {
  //         classname: 'bg-danger text-light',
  //         delay: 7000
  //       });
  //     }
  //     parser.abort();
  //   }
  //   var count = 0;
  //   if (Array.isArray(results.data)) {

  //     // Empt line... no-op
  //   } else {
  //     for (let i in results.data) {

  //       // Check if field is empty
  //       if (requiredFields.indexOf(i) >= 0) {
  //         count = count + 1;
  //       }
  //     }

  //     if (count < requiredFields.length) {
  //       console.error('Missing Fields! ', count, requiredFields.length);
  //       parser.abort();
  //       self.toastService.show('Your uploaded CSV is missing required fields.', {
  //         classname: 'bg-danger text-light',
  //         delay: 7000
  //       });
  //       self.bulkUploadError = 'Missing fields! Please check your spreadsheet and try again.';
  //     } else {

  //       // Save to DB
  //       results.data.type = self.pageType;

  //       // Strip out all non-digits.
  //       results.data.phoneNumber = results.data.phoneNumber.replace(/\D/g, '');
  //       self.dataForUpload.push(results.data);
  //       self.sampleData = results.data;
  //     }
  //   }
  //   parser.resume();
  // }

  // Handle bulk CSV file upload.
  // onFileUploaded(results, file) {
  //   setTimeout(() => {
  //     if (!self.bulkUploadError) {
  //       self.activeStep = 2;
  //     }
  //   });
  // }

  // uploadContacts(e, modal) {
  //   e.preventDefault();
  //   this.loaderService.triggerLoader();

  //   // We need to split into chunks of 100 contacts
  //   // to make sure the upload succeeds. This is a hacky
  //   // way to solve the upload size problem...
  //   const contactChunks = chunkArray(100, self.dataForUpload);
  //   from(contactChunks).pipe(
  //     mergeMap((contacts) => this.contactsService.createContacts({ contacts })),
  //     toArray()
  //   ).subscribe((aggregatedResults) => {
  //     for (const data of aggregatedResults) {
  //       for (var i = 0; i < data.result.length; i++) {
  //         if (data.result[i].state === 'rejected') {
  //           this.contactUploadError = true;
  //           break;
  //         }
  //       }
  //     }
  //     this.loaderService.stopLoader();
  //     if (!this.contactUploadError) {
  //       this.toastService.show('Contact uploaded!', {
  //         classname: 'bg-success text-light',
  //         delay: 5000
  //       });
  //     } else {
  //       this.toastService.show('Some contacts created!', {
  //         classname: 'bg-info text-light',
  //         delay: 5000
  //       });
  //     }
  //     modal.close();
  //   }, (err) => {
  //     console.error('There was an error uploading your contacts: ', err);
  //     this.toastService.show('There was an error uploading your contacts', {
  //       classname: 'bg-danger text-light',
  //       delay: 5000
  //     });
  //     this.contactUploadError = true;
  //     this.loaderService.stopLoader();
  //   });
  // }

  // processUploadedFile(e) {
  //   e.preventDefault();
  //   this.papa.parse(this.fileUploaded, this.parserConfig);
  // }

  // bulkDeleteContacts() {
  //   return (_) => {
  //     this.loaderService.triggerLoader();

  //     // Create promis array of deletion calls and verify no texts sent.
  //     var promiseArray: Array<any> = [];
  //     for (var i = 0; i < this.selectedRows.length; i++) {
  //       promiseArray.push(new Promise((resolve, reject) => {
  //         this.contactsService.deleteContact(this.selectedRows[i]._id).subscribe(() => {
  //           resolve('');
  //         }, () => {
  //           reject();
  //         });
  //       }));
  //     }
  //     Promise.all(promiseArray).then(function() {
  //       self.loaderService.stopLoader();
  //       self.getContacts();
  //     }).catch(function() {
  //       self.loaderService.stopLoader();
  //       this.toastService.show('Some of your contacts couldn\'t be deleted', {
  //         classname: 'bg-danger text-light',
  //         delay: 5000
  //       });
  //       setTimeout(() => {
  //         self.getContacts();
  //       }, 2000);
  //     });
  //   };
  // }
}
