<div id="contact-events">
  <div class="page-title">
    <h2 (click)="goBack()" class="pointer go-back-link pull-left"><i class="fa-regular fa-chevron-left"></i> Go Back</h2>
    <h2 class="pull-right">Events for {{activeContact.contactIdentifier || 'Unknown'}}</h2>
  </div>

<div class="page" id="event-scroll">
  <div 
    *ngFor="let event of allEvents"
    class="event-wrapper"
    [ngClass]="event.eventOrigin === integrationType ? 'thrid-party-event' : 'nurture-boss-event'
  ">
    <p class="event-date">{{event.eventDate | date:'short'}}</p>
    <div class="event-content">
      <!-- Nurture Boss Event Bubble -->
      <p class="event-type" [ngClass]="{'nb-event': event.eventOrigin !== integrationType}">
        <i class="nurture-boss-event-icon far fa-circle-info" *ngIf="event.eventOrigin !== integrationType"></i> 
        {{event.eventType}}
      </p>
      
      <!-- CRM/PMS Event Bubbles -->
      <div *ngIf="INBOUND_COMM.includes(event.eventType)">
        <p class="event-type">
          To: {{userData.propertyName}}
        </p>
        <p class="event-type" *ngIf="event.eventType === EMAIL_RECEIVED">
          From: {{activeContact.emailAddress}}
        </p>
        <p class="event-type" *ngIf="event.eventType !== EMAIL_RECEIVED">
          From: {{activeContact.phoneNumber}}
        </p>
      </div>
      <div *ngIf="OUTBOUND_COMM.includes(event.eventType)">
        <p class="event-type" *ngIf="event.eventType === EMAIL_SENT">
          To: {{activeContact.emailAddress}}
        </p>
        <p class="event-type" *ngIf="event.eventType === TEXT_MESSAGE_SENT || event.eventType === CALL_MADE">
          To: {{activeContact.phoneNumber}}
        </p>
        <p class="event-type" *ngIf="event.eventType === WEB_MESSAGE_SENT">
          To: {{activeContact.contactIdentifier}}
        </p>
        <p class="event-type">
          From: {{userData.propertyName}}
        </p>
      </div>
      <hr
        class="email-separator"
        *ngIf="OUTBOUND_COMM.includes(event.eventType)|| INBOUND_COMM.includes(event.eventType)"
      />
      <div
        *ngIf="event.eventOrigin === integrationType && event.eventNotes"
        class="event-notes"
        [innerHTML]="event.eventNotes"
      ></div>
      <p *ngIf="event.eventOrigin === integrationType && event.firstContact === 'true'" class="event-first-contact">First Contact</p>
      <p *ngIf="event.eventOrigin === integrationType && event.eventAgent" class="event-agent">Agent: {{event.eventAgent}}</p>
      <p *ngIf="event.eventOrigin === integrationType && event.eventSource" class="event-agent">Source: {{event.eventSource}}</p>
    </div>
  </div>
</div>