<ng-container *ngIf="(!treeNodes.length && !dateRange.length && !activeQuery.query) || firstLoad; else conversationsTable">
  <div class="row">
    <p class="text-center full-width margin-top-48">
      <img src="/assets/no-messaging.png" alt="No messaging" />
    </p>
    <h3 class="text-center full-width margin-top-48">You do not have any messages. <br /> Check back soon!</h3>
  </div>
</ng-container>

<ng-template #conversationsTable>
  <div class="card">
    <div class="card-body">
      <div class="overflow-auto">
        <p class="table-row-indicator pull-right" *ngIf="totalRecords > 0">
          <strong>Showing {{ pagination.first }} to {{ pagination.last }} of {{ totalRecords }} rows</strong>
        </p>
      </div>
      <p-treeTable
        [value]="treeNodes"
        styleClass="ui-table-vla-conversations"
        [paginator]="true"
        [rows]="25"
        [totalRecords]="totalRecords"
        [lazy]="true"
        (onLazyLoad)="paginate($event)"
        [rowsPerPageOptions]="[25, 50, 100, 250]"
      >
        <ng-template pTemplate="header">
          <tr class="ui-table-vla-conversation-header-row">
            <!-- Status -->
            <th></th>
            <!-- Name -->
            <th class="padding-bottom-20">
              <input
                *ngIf="currentFilter !== 'chatbot'"
                pInputText
                type="text"
                placeholder="Search Name..."
                class="w-100"
                [(ngModel)]="formNameSearchText"
                (ngModelChange)="debouncedSearch(formNameSearchText, 'name')"
              />
            </th>
            <!-- Channel -->
            <th></th>
            <!-- Last Updated -->
            <th class="padding-bottom-20">
              <p-calendar
              #updatedCalendar
              selectionMode="range"
              (onSelect)="onDateSelect($event)"
              (onClearClick)="onDateClear($event)"
              [showButtonBar]="true"
              placeholder="Search Date..."
              [readonlyInput]="true"
              dateFormat="mm-dd-yy"
              [style]="{ 'width': '100%' }"
              styleClass="ui-column-filter" 
              [inputStyle]="{ 'width': '100%' }"
              ></p-calendar>
            </th>
            <!-- Message Preview -->
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
          <tr class="conversations-table" [class]="{'child': rowData.child, 'pointer': rowData.child}" (click)="loadSingleConversation(rowData.id, rowData.name, rowNode.node.children, rowData)">
            <!-- Status -->
            <td>
              <div *ngIf="!rowData.status" class="ai-status engaged"></div>
              <div *ngIf="rowData.status" class="ai-status disabled"></div>
            </td>
            <!-- Name -->
            <td class="truncate">
              <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
              {{ rowData.name }}
            </td>
            <!-- Channel -->
            <td class="text-center">
              <span *ngIf="rowData.channel.includes('email')" class="customer-badge status-tour margin-right-8">Email</span>
              <span *ngIf="rowData.channel.includes('call')" class="customer-badge status-application margin-right-8">Call</span>
              <span *ngIf="rowData.channel.includes('text')" class="customer-badge status-lease margin-right-8">Text</span>
              <span *ngIf="rowData.channel.includes('chatbot')" class="customer-badge status-leased margin-right-8">Chatbot</span>
            </td>
            <!-- Last Updated -->
            <td>{{ rowData.lastUpdated | date: 'short' }}</td>
            <!-- Message Preview -->
            <td [innerHTML]="rowData.messagePreview"></td>
          </tr>
        </ng-template>
      </p-treeTable>
    </div>
  </div>
</ng-template>
