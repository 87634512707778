<div class="page">
  <div class="overflow-auto">
    <h6 class="title pull-left">VLA Settings</h6>
    <button class="button button-dark pull-right" [disabled]="savingSettings" (click)="updateVlaSettings()">
      <span *ngIf="savingSettings" class="spinner-border spinner-border-sm mr-1"></span>
      Update VLA Settings
    </button>
  </div>
  <div class="settings-table">
    <div class="setting-row no-border align-items-baseline">
      <div class="setting-icon">
        <i class="fas fa-robot"></i>
      </div>
      <div class="setting-content">
        <h6>Enable Handoff</h6>
        <p class="setting-description">Disabling this settings means the VLA will <strong>continue</strong> to respond to the conversation even after it has been handed off to a human.</p>
      </div>
        <label class="switch">
          <input type="checkbox" [(ngModel)]="disableEmailHandoffField">
          <span class="slider round"></span>
        </label>
      </div>
  </div>
</div>

<div class="page">
  <div class="overflow-auto">
    <h6 class="title pull-left">Personality</h6>
    <button class="button button-dark pull-right" [disabled]="savingSettings" (click)="updateVLAPersonalitySettings()">
      <span *ngIf="savingSettings" class="spinner-border spinner-border-sm mr-1"></span>
      Update Personality
    </button>
  </div>
  <div class="settings-table">
    <div class="setting-row align-items-baseline">
      <div class="setting-content">
        <h6>Name</h6>
        <p class="setting-description">You can name your VLA to help set the identity and personality of your VLA.</p>
        <input type="text" class="form-control short-input" [(ngModel)]="vlaSettings.botName" id="botName" aria-describedby="botName">
      </div>
    </div>

    <div class="setting-row align-items-baseline" *ngIf="hasVoiceAssistant">
      <div class="setting-content">
        <h6>Voice VLA Greeting Message</h6>
        <p class="setting-description">This is the first message that the VLA will say.</p>
        <textarea
          rows="5"
          type="text"
          class="form-control"
          [(ngModel)]="greetingMessage"
          id="voiceAssistantGreetingMessage"
          aria-describedby="voiceAssistantGreetingMessage">
        </textarea>
      </div>
    </div>

    <div class="setting-row" *ngIf="hasVoiceAssistant">
      <div class="setting-content">
        <h6>Voice</h6>
        <p class="setting-description">Select the voice that the VLA will use to communicate with callers during interactions.</p>
        <div class="d-flex align-items-center gap-1">
          <div class="form-group select-group">
            <p-autoComplete
              styleClass="form-control form-autocomplete"
              (onSelect)="onVoiceSelect($event)"
              (onClear)="onVoiceSelect(null)"
              [suggestions]="filteredVoices"
              (completeMethod)="filterVoices($event)"
              [dropdown]="true"
              [forceSelection]="true"
              [completeOnFocus]="true"
              [(ngModel)]="selectedVoice"
              placeholder="Select a voice (required)"
              [required]="true"
              [field]="getVoiceDisplay"
              id="voiceSelection"
              aria-describedby="voiceSelection">
            </p-autoComplete>
          </div>
          <tts-audio-player
            [transcript]="greetingMessage"
            [voiceId]="selectedVoice?.id"
            [provider]="isMultilingual ? 'elevenlabs' : 'cartesia'">
          </tts-audio-player>
        </div>
      </div>
    </div>
    <div class="setting-row" *ngIf="hasVoiceAssistant">
      <div class="setting-icon">
        <i class="fas fa-globe-americas"></i>
      </div>
      <div class="setting-content">
        <h6>Enable Multilingual Support</h6>
        <p class="setting-description">Enable multilingual support to seamlessly switch between languages for a more inclusive experience.</p>
      </div>
      <label class="switch">
        <input 
          type="checkbox" 
          [(ngModel)]="isMultilingual" 
          (change)="onMultilingualToggle()" 
          id="multilingualSupport"
          aria-describedby="multilingualSupport">
        <span class="slider round"></span>
      </label>
    </div>
    <div class="setting-row no-border" *ngIf="hasVoiceAssistant">
      <div class="setting-content">
        <h6>Voice Playground</h6>
        <p class="setting-description">Enter any text and click the play button to the right to hear how it sounds with your selected voice. If multilingual support is enabled you can enter any approved language.</p>
        <div class="d-flex align-items-center gap-1">
          <textarea
            rows="3"
            class="form-control"
            [(ngModel)]="testVoiceText"
            placeholder="Enter text to test the voice..."
            id="voicePlaygroundText"
            aria-describedby="voicePlaygroundText">
          </textarea>
          <tts-audio-player
            [transcript]="testVoiceText"
            [voiceId]="selectedVoice?.id"
            [provider]="isMultilingual ? 'elevenlabs' : 'cartesia'">
          </tts-audio-player>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="page" *ngIf="hasVoiceAssistant">
  <!-- EMERGENCY MAINTENANCE -->
  <div class="overflow-auto">
    <h6 class="title pull-left">Emergency Maintenance Call Transfer</h6>
    <button class="button button-dark pull-right" [disabled]="savingSettings" (click)="updateCallTransferDestinations()">
      <span *ngIf="savingSettings" class="spinner-border spinner-border-sm mr-1"></span>
      Update Call Transfers
    </button>
  </div>
  <div class="settings-table">

    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Emergency Maintenance Call Transfer </h6>
        <p class="setting-description">The Voice VLA will transfer any callers who have an emergency maintenance request to this number.</p>
      </div>
    </div>

    <div class="setting-row">
      <div class="setting-icon">
        <i class="fas fa-phone-alt"></i>
      </div>
      <div class="setting-content">
        <h6>Enable Emergency Maintenance Call Transfer</h6>
      </div>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="emergencyMaintenanceDestination.enabled" [disabled]="!emergencyMaintenanceDestination.number">
        <span class="slider round"></span>
      </label>
    </div>

    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Number</h6>
        <p class="setting-description">To change the emergency maintenance number, go to <a href="/#/settings/account">account settings</a>.</p>
        <div class="emergency-maintenance-number">{{(emergencyMaintenanceDestination.number || '') | phone}}</div>
      </div>
    </div>

    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Transfer Message</h6>
        <p class="setting-description">The transfer message is what the VLA will say to the caller just before transferring their call.</p>
        <textarea rows="5" type="text" class="form-control" [(ngModel)]="emergencyMaintenanceDestination.message" [disabled]="!emergencyMaintenanceDestination.number" id="emergencyMaintenanceDestinationMessage" aria-describedby="emergencyMaintenanceDestinationMessage"></textarea>
      </div>
    </div>
  </div>

  <!-- REPRESENTATIVE CALL FORWARDING -->
  <div class="overflow-auto">
    <h6 class="title pull-left">Representative Call Transfer</h6>
  </div>
  <div class="settings-table">

    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Representative Call Transfer </h6>
        <p class="setting-description">The Voice VLA will transfer any callers who request to speak to a person or represenative to this number.</p>
      </div>
    </div>

    <div class="setting-row">
      <div class="setting-icon">
        <i class="fas fa-phone-alt"></i>
      </div>
      <div class="setting-content">
        <h6>Enable Representative Call Transfer</h6>
      </div>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="representativeDestination.enabled">
        <span class="slider round"></span>
      </label>
    </div>

    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Representative Number</h6>
        <input type="text" class="form-control" [(ngModel)]="representativeDestination.number" [ngModelOptions]="{standalone: true}" id="representativeDestinationNumber" aria-describedby="representativeDestinationNumber" placeholder="6025551234">
      </div>
    </div>

    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Representative Transfer Message</h6>
        <p class="setting-description">The transfer message is what the VLA will say to the caller just before transferring their call.</p>
        <textarea rows="5" type="text" class="form-control" [(ngModel)]="representativeDestination.message" id="representativeDestinationMessage" aria-describedby="representativeDestinationMessage"></textarea>
      </div>
    </div>
  </div>

  <!-- RESIDENT REPRESENTATIVE CALL FORWARDING -->
  <div class="overflow-auto">
    <h6 class="title pull-left">Resident Representative Call Transfer</h6>
  </div>
  <div class="settings-table">

    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Resident Representative Call Transfer </h6>
        <p class="setting-description">The Voice VLA will transfer any callers with resident related issues to this number.</p>
      </div>
    </div>

    <div class="setting-row">
      <div class="setting-icon">
        <i class="fas fa-phone-alt"></i>
      </div>
      <div class="setting-content">
        <h6>Enable Resident Representative Call Transfer</h6>
      </div>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="residentRepresentativeDestination.enabled">
        <span class="slider round"></span>
      </label>
    </div>

    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Resident Representative Number</h6>
        <input type="text" class="form-control" [(ngModel)]="residentRepresentativeDestination.number" [ngModelOptions]="{standalone: true}" id="residentRepresentativeDestinationNumber" aria-describedby="residentRepresentativeDestinationNumber" placeholder="6025551234">
      </div>
    </div>

    <div class="setting-row align-items-baseline">
      <div class="setting-content no-padding">
        <h6>Resident Representative Transfer Message</h6>
        <p class="setting-description">The transfer message is what the VLA will say to the caller just before transferring their call.</p>
        <textarea rows="5" type="text" class="form-control" [(ngModel)]="residentRepresentativeDestination.message" id="residentRepresentativeDestinationMessage" aria-describedby="residentRepresentativeDestinationMessage"></textarea>
      </div>
    </div>
  </div>
</div>
