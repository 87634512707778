<div class="page-title">
  <h2 (click)="goBack()" class="pointer go-back-link pull-left"><i class="fa-regular fa-chevron-left"></i> Go Back</h2>
  <h2 class="pull-right">{{activeContact.contactIdentifier || 'Unknown'}}</h2>
</div>

<div class="page">
  <div class="row margin-left-12">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6">
          <p>First Name: <input class="form-control" [(ngModel)]="activeContact.firstName" [ngModelOptions]="{standalone: true}" readonly /></p>
          <p>Phone Number: <input class="form-control" [(ngModel)]="activeContact.phoneNumber" [ngModelOptions]="{standalone: true}" readonly /></p>
          <p>Status: <input class="form-control" [(ngModel)]="activeContact.status" [ngModelOptions]="{standalone: true}" readonly /></p>
          <p>Pets: <input class="form-control" [(ngModel)]="activeContact.pets" [ngModelOptions]="{standalone: true}" readonly /></p>
          <p>Desired Bedroom Count: <input class="form-control" [(ngModel)]="activeContact.desiredBedCount" [ngModelOptions]="{standalone: true}" readonly /></p>
          <p>Desired Rent: <input class="form-control" [(ngModel)]="activeContact.desiredRent" [ngModelOptions]="{standalone: true}" readonly /></p>
          <p>Expected Move In Date: <input class="form-control" [(ngModel)]="activeContact.expectedMoveInDate" [ngModelOptions]="{standalone: true}" readonly /></p>
        </div>
        <div class="col-md-6">
          <p>Last Name: <input class="form-control" [(ngModel)]="activeContact.lastName" [ngModelOptions]="{standalone: true}" readonly /></p>
          <p>Email Address: <input class="form-control" [(ngModel)]="activeContact.emailAddress" [ngModelOptions]="{standalone: true}" readonly /></p>          
          <p>Guest Card ID: <input class="form-control" [(ngModel)]="activeContact.thirdPartyGuestCardId" [ngModelOptions]="{standalone: true}" readonly /></p>
          <p>Lead Source: <input class="form-control" [(ngModel)]="activeContact.source" [ngModelOptions]="{standalone: true}" readonly /></p>
          <p>Stage: <input class="form-control" [(ngModel)]="activeContact.stage" [ngModelOptions]="{standalone: true}" readonly /></p>
          <p>Desired Amenities: <input class="form-control" [(ngModel)]="activeContact.desiredAmenities" [ngModelOptions]="{standalone: true}" readonly /></p>
          <p>Desired Lease Terms: <input class="form-control" [(ngModel)]="activeContact.desiredLeaseTerm" [ngModelOptions]="{standalone: true}" readonly /></p>
          <p>Desired Unit: <input class="form-control" [(ngModel)]="activeContact.desiredUnit" [ngModelOptions]="{standalone: true}" readonly /></p>
        </div>
      </div>
    </div>
  </div>
</div>