<!-- <div class="page">
  <div>
    <div class="settings-table">
      <div class="setting-row align-items-baseline no-border">
        <div class="setting-content no-padding">
          <h6>Texting Behavior</h6>
          <p class="setting-description">Do you want the AI to respond to text message from Prospects and Residents?</p>
          <label class="faq-label" for="smsSetting"></label>
          <select class="form-control" formControlName="smsSetting" id="smsSetting">
            <option value="off">Off</option>
            <option value="bot_only">Enable AI Two Way Texting</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="page">
  <div class="overflow-auto">
    <h6 class="title pull-left">Knowledge Base</h6>
    <button type="submit" class="button button-dark pull-right" [disabled]="saving" (click)="saveBotFaq($event)">
      <span *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>
      Save Knowledge Base
    </button>
  </div>
  <div id="bot-faq" class="margin-top-24">
    <p>The Knowledge Base is used by the AI to answer questions from Prospects and Residents. The more detailed the information below the better your AI will function. Please reach out to <a href="mailto:customersuccess@nurtureboss.com">customersuccess@nurtureboss.com</a> if you need help with the Knowledge Base.</p>
    <div class="leasing-alert alert alert-warning">Please ensure all answers provided comply with local and federal law as well as Fair Housing. Nurture Boss is not liable for problematic information entered here.</div>
    <div class="bot-faq-category">
      <div class="search-container mb-4">
        <div class="form-group">
          <h6>Search Knowledge Base</h6>
          <input 
            type="text" 
            class="form-control" 
            placeholder="Search..."
            [(ngModel)]="searchTerm"
            >
        </div>
      </div>
      <div>
        <p-accordion>

          <!-- Application Process -->
          <p-accordionTab [selected]="true" *ngIf="shouldShowAccordion('onlineApplicationUrl')">
            <p-header>
              Applications
            </p-header>
            <div class="bot-faq-accordion-content">
              <p class="category-reponse">Category Response</p>
              <div class="form-group">
                <label class="faq-label">{{applicationDescription}}</label>
                <input class="form-control" [(ngModel)]="localBotFAQContent.onlineApplicationUrl.content" [ngModelOptions]="{standalone: true}" placeholder="https://myapartments.com/apply..."/>
              </div>
            </div>
          </p-accordionTab>

          <!-- Schedule A Tour -->
          <p-accordionTab *ngIf="shouldShowAccordion('scheduleTourUrl')">
            <p-header>
              Schedule A Tour
            </p-header>
            <div class="bot-faq-accordion-content">
              <p class="category-reponse">Category Response</p>
              <div class="form-group">
                <label class="faq-label">{{scheduleTourDescription}}</label>
                <input class="form-control" [(ngModel)]="localBotFAQContent.scheduleTourUrl.content" [ngModelOptions]="{standalone: true}" placeholder="https://myapartments.com/tour..."/>
              </div>
            </div>
          </p-accordionTab>

          <!-- Fees & Deposits -->
          <p-accordionTab *ngIf="shouldShowAccordion('feesAndDeposits')">
            <p-header>
              Fees & Deposits
            </p-header>
            <div class="bot-faq-accordion-content">
              <p class="category-reponse">Category Response</p>
              <div class="form-group">
                <label class="faq-label">What fees and deposits are required at your property?</label>
                <app-limited-textarea
                  maxLength="2400"
                  rows="3"
                  id="feesAndDeposits"
                  isNgModel="true"
                  [(ngModelHook)]="localBotFAQContent.feesAndDeposits.content"
                  placeholder="General Information about fees & deposits..."
                ></app-limited-textarea>
              </div>
              <p class="sub-category-reponse">Detailed Responses</p>
              <span class="sub-category-description">Below you can add additional details to inform the AI even more about the fees & deposits.</span>

              <div class="sub-category-row row" *ngFor="let item of localBotFAQContent.feesAndDeposits.details">
                <div class="sub-category-label col-sm-2">
                  <p>{{item.name}}:</p>
                </div>
                <div class="sub-category-content col-sm-10">
                  <div class="form-group">
                    <input class="form-control" [(ngModel)]="item.content" [ngModelOptions]="{standalone: true}" [placeholder]="item.placeholder || ''"/>
                  </div>
                </div>
              </div>
            </div>
          </p-accordionTab>

          <!-- Pet Policy -->
          <p-accordionTab *ngIf="shouldShowAccordion('petPolicy')">
            <p-header>
              Pet Policy
            </p-header>
            <div class="bot-faq-accordion-content">
              <p class="category-reponse">Category Response</p>
              <div class="form-group">
                <label class="faq-label">What is your pet policy at the property?</label>
                <app-limited-textarea
                  maxLength="2400"
                  rows="3"
                  id="petPolicy"
                  isNgModel="true"
                  [(ngModelHook)]="localBotFAQContent.petPolicy.content"
                  placeholder="General Information about your pet policy..."
                ></app-limited-textarea>
              </div>
              <p class="sub-category-reponse">Detailed Responses</p>
              <span class="sub-category-description">Below you can add additional details to inform the AI even more about the pet policy.</span>
              <div class="sub-category-row row" *ngFor="let item of localBotFAQContent.petPolicy.details">
                <div class="sub-category-label col-sm-2">
                  <p>{{item.name}}:</p>
                </div>
                <div class="sub-category-content col-sm-10">
                  <div class="form-group">
                    <input class="form-control" [(ngModel)]="item.content" [ngModelOptions]="{standalone: true}" [placeholder]="item.placeholder || ''"/>
                  </div>
                </div>
              </div>
            </div>
          </p-accordionTab>

          <!-- Amenities -->
          <p-accordionTab *ngIf="shouldShowAccordion('amenities')">
            <p-header>
              Amenities
            </p-header>
            <div class="bot-faq-accordion-content">
              <p class="category-reponse">Category Response</p>
              <div class="form-group">
                <label class="faq-label">What amenities do you have at your property?</label>
                <app-limited-textarea
                  maxLength="2400"
                  rows="3"
                  id="amenities"
                  isNgModel="true"
                  [(ngModelHook)]="localBotFAQContent.amenities.content"
                  placeholder="General Information about your amenities..."
                ></app-limited-textarea>
              </div>
              <p class="sub-category-reponse">Detailed Responses</p>
              <span class="sub-category-description">Below you can add additional details to inform the AI even more about the amenities at your propety.</span>
              <div class="sub-category-row row" *ngFor="let item of localBotFAQContent.amenities.details">
                <div class="sub-category-label col-sm-2">
                  <p>{{item.name}}:</p>
                </div>
                <div class="sub-category-content col-sm-10">
                  <div class="form-group">
                    <input class="form-control" [(ngModel)]="item.content" [ngModelOptions]="{standalone: true}" [placeholder]="item.placeholder || ''"/>
                  </div>
                </div>
              </div>
            </div>
          </p-accordionTab>

          <!-- Local Area Information -->
          <p-accordionTab *ngIf="shouldShowAccordion('localInfo')">
            <p-header>
              Local Area Information
            </p-header>
            <div class="bot-faq-accordion-content">
              <p class="category-reponse">Category Response</p>
              <div class="form-group">
                <label class="faq-label">What do you want prospects to know about the local area?</label>
                <app-limited-textarea
                  maxLength="2400"
                  rows="3"
                  id="localInfo"
                  isNgModel="true"
                  [(ngModelHook)]="localBotFAQContent.localInfo.content"
                  placeholder="General Information about the local area..."
                ></app-limited-textarea>
              </div>
              <p class="sub-category-reponse">Detailed Responses</p>
              <span class="sub-category-description">Below you can add additional details to inform the AI even more about the amenities at your propety.</span>
              <div class="sub-category-row row" *ngFor="let item of localBotFAQContent.localInfo.details">
                <div class="sub-category-label col-sm-2">
                  <p>{{item.name}}:</p>
                </div>
                <div class="sub-category-content col-sm-10">
                  <div class="form-group">
                    <input class="form-control" [(ngModel)]="item.content" [ngModelOptions]="{standalone: true}" [placeholder]="item.placeholder || ''"/>
                  </div>
                </div>
              </div>
            </div>
          </p-accordionTab>

          <!-- Property Contact Information -->
          <p-accordionTab *ngIf="shouldShowAccordion('contactInfo')">
            <p-header>
              Property Contact Information
            </p-header>
            <div class="bot-faq-accordion-content">
              <p class="category-reponse">Category Response</p>
              <div class="form-group">
                <label class="faq-label">What is the contact info for the property you want to share with the prospects?</label>
                <app-limited-textarea
                  maxLength="2400"
                  rows="3"
                  id="contactInfo"
                  isNgModel="true"
                  [(ngModelHook)]="localBotFAQContent.contactInfo.content"
                  placeholder="Property email, phone number, address, etc..."
                ></app-limited-textarea>
              </div>
              <p class="sub-category-reponse">Detailed Responses</p>
              <span class="sub-category-description">Below you can add additional details to inform the AI even more about the contact information for your propety.</span>
              <div class="sub-category-row row" *ngFor="let item of localBotFAQContent.contactInfo.details">
                <div class="sub-category-label col-sm-2">
                  <p>{{item.name}}:</p>
                </div>
                <div class="sub-category-content col-sm-10">
                  <div class="form-group">
                    <input class="form-control" [(ngModel)]="item.content" [ngModelOptions]="{standalone: true}" [placeholder]="item.placeholder || ''"/>
                  </div>
                </div>
              </div>
            </div>
          </p-accordionTab>

          <!-- Specials & Concessions -->
          <p-accordionTab *ngIf="shouldShowAccordion('concessions')">
            <p-header>
              Specials & Concessions
            </p-header>
            <div class="bot-faq-accordion-content">
              <p class="category-reponse">Category Response</p>
              <div class="form-group">
                <label class="faq-label">Are there any current specials or concession you want to share with prospects?</label>
                <app-limited-textarea
                  maxLength="2400"
                  rows="3"
                  id="concessions"
                  isNgModel="true"
                  [(ngModelHook)]="localBotFAQContent.concessions.content"
                  placeholder="Look & Lease Special, $500 Off First Months..."
                ></app-limited-textarea>
              </div>
            </div>
          </p-accordionTab>

          <!-- Photo Gallery -->
          <p-accordionTab *ngIf="shouldShowAccordion('photos')">
            <p-header>
              Link To Photo Gallery
            </p-header>
            <div class="bot-faq-accordion-content">
              <p class="category-reponse">Category Response</p>
              <div class="form-group">
                <label class="faq-label">What is the URL to access the properties photo gallery?</label>
                <input class="form-control" [(ngModel)]="localBotFAQContent.photos.content" [ngModelOptions]="{standalone: true}" placeholder="https://myapartments.com/photos..."/>
              </div>
            </div>
          </p-accordionTab>

          <!-- Pricing & Availability -->
          <p-accordionTab *ngIf="shouldShowAccordion('pricingAndAvailability')">
            <p-header>
              Pricing & Availability
            </p-header>
            <div class="bot-faq-accordion-content">
              <p class="category-reponse">Category Response</p>
              <div class="form-group">
                <label class="faq-label">What general pricing & availability information do you want to share? NOTE: If Nurture Boss is integrated with your pricing & availability, the AI will attempt to get realtime data.</label>
                <app-limited-textarea
                  maxLength="2400"
                  rows="3"
                  id="pricingAndAvailability"
                  isNgModel="true"
                  [(ngModelHook)]="localBotFAQContent.pricingAndAvailability.content"
                  placeholder="We offer 1 to 3 bedroom..."
                ></app-limited-textarea>
              </div>
              <p class="sub-category-reponse">Detailed Responses</p>
              <span class="sub-category-description">Below you can add additional details to inform the AI even more about the pricing & availability at your propety.</span>
              <div class="sub-category-row row" *ngFor="let item of localBotFAQContent.pricingAndAvailability.details">
                <div class="sub-category-label col-sm-2">
                  <p>{{item.name}}:</p>
                </div>
                <div class="sub-category-content col-sm-10">
                  <div class="form-group">
                    <input class="form-control" [(ngModel)]="item.content" [ngModelOptions]="{standalone: true}" [placeholder]="item.placeholder || ''"/>
                  </div>
                </div>
              </div>
            </div>
          </p-accordionTab>

          <!-- Utlities & Included Fees-->
          <p-accordionTab *ngIf="shouldShowAccordion('includedUtilities')">
            <p-header>
              Utilities & Included Fees
            </p-header>
            <div class="bot-faq-accordion-content">
              <p class="category-reponse">Category Response</p>
              <div class="form-group">
                <label class="faq-label">What general information do you want to share about utilities & included fees?</label>
                <app-limited-textarea
                  maxLength="2400"
                  rows="3"
                  id="includedUtilities"
                  isNgModel="true"
                  [(ngModelHook)]="localBotFAQContent.includedUtilities.content"
                  placeholder="WiFi and electric are included in rent, residents are responsible..."
                ></app-limited-textarea>
              </div>
              <p class="sub-category-reponse">Detailed Responses</p>
              <span class="sub-category-description">Below you can add additional details to inform the AI even more about the utilities at your propety.</span>
              <div class="sub-category-row row" *ngFor="let item of localBotFAQContent.includedUtilities.details">
                <div class="sub-category-label col-sm-2">
                  <p>{{item.name}}:</p>
                </div>
                <div class="sub-category-content col-sm-10">
                  <div class="form-group">
                    <input class="form-control" [(ngModel)]="item.content" [ngModelOptions]="{standalone: true}" [placeholder]="item.placeholder || ''"/>
                  </div>
                </div>
              </div>
            </div>
          </p-accordionTab>

          <!-- Leasing Criteria-->
          <p-accordionTab *ngIf="shouldShowAccordion('leasingCriteria')">
            <p-header>
              Leasing Criteria
            </p-header>
            <div class="bot-faq-accordion-content">
              <div class="leasing-alert alert alert-danger">Please ensure all answers provided comply with local and federal law as well as Fair Housing.</div>
              <p class="category-reponse">Category Response</p>
              <div class="form-group">
                <label class="faq-label">What general information do you want to share about the leasing criteria at your property?</label>
                <app-limited-textarea
                  maxLength="2400"
                  rows="3"
                  id="leasingCriteria"
                  isNgModel="true"
                  [(ngModelHook)]="localBotFAQContent.leasingCriteria.content"
                  placeholder="Residents must show proof of income..."
                ></app-limited-textarea>
              </div>
              <p class="sub-category-reponse">Detailed Responses</p>
              <span class="sub-category-description">Below you can add additional details to inform the AI even more about the leasing criteria at your propety.</span>
              <div class="sub-category-row row" *ngFor="let item of localBotFAQContent.leasingCriteria.details">
                <div class="sub-category-label col-sm-2">
                  <p>{{item.name}}:</p>
                </div>
                <div class="sub-category-content col-sm-10">
                  <div class="form-group">
                    <input class="form-control" [(ngModel)]="item.content" [ngModelOptions]="{standalone: true}" [placeholder]="item.placeholder || ''"/>
                  </div>
                </div>
              </div>
            </div>
          </p-accordionTab>

          <!-- Parking-->
          <p-accordionTab *ngIf="shouldShowAccordion('parking')">
            <p-header>
              Parking
            </p-header>
            <div class="bot-faq-accordion-content">
              <p class="category-reponse">Category Response</p>
              <div class="form-group">
                <label class="faq-label">What general information do you want to share about parking at your property?</label>
                <app-limited-textarea
                  maxLength="2400"
                  rows="3"
                  id="parking"
                  isNgModel="true"
                  [(ngModelHook)]="localBotFAQContent.parking.content"
                  placeholder="Each resident is assigned..."
                ></app-limited-textarea>
              </div>
              <p class="sub-category-reponse">Detailed Responses</p>
              <span class="sub-category-description">Below you can add additional details to inform the AI even more about parking at your propety.</span>
              <div class="sub-category-row row" *ngFor="let item of localBotFAQContent.parking.details">
                <div class="sub-category-label col-sm-2">
                  <p>{{item.name}}:</p>
                </div>
                <div class="sub-category-content col-sm-10">
                  <div class="form-group">
                    <input class="form-control" [(ngModel)]="item.content" [ngModelOptions]="{standalone: true}" [placeholder]="item.placeholder || ''"/>
                  </div>
                </div>
              </div>
            </div>
          </p-accordionTab>
          
        </p-accordion>
      </div>
    </div>
  </div>
</div>
