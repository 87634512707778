<!-- System Notifications-->
<div class="page">
  <div class="overflow-auto">
    <h6 class="title pull-left">Notifications</h6>
  </div>
  <div class="settings-table">
    <div class="setting-row">
      <div class="setting-icon">
        <i class="fas fa-bell"></i>
      </div>
      <div class="setting-content">
        <h6>System Notifications</h6>
        <p class="setting-description">System Notifications include: Engaged Prospect Reports, New Tour Notifications, Prospect Contact Requests.</p>
      </div>
      <div class="setting-actions">
        <label class="switch">
          <input type="checkbox" checked="true" disabled>
          <span class="slider round disabled"></span>
        </label>
        <div class="button button-white button-small text-center" (click)="openNotificationConfiguration(systemnotifications)">Configure</div>
      </div>
    </div>
    <div class="setting-row" *ngIf="userData.user.claims.includes('vla')">
      <div class="setting-icon">
        <i class="fas fa-robot"></i>
      </div>
      <div class="setting-content">
        <h6>VLA Handoff Notifications</h6>
        <p class="setting-description">
          Notifications sent when the Virtual Leasing Assistant needs assistance answering a question or comment from a prospect or resident
        </p>
      </div>
      <div class="setting-actions">
        <label class="switch">
          <input type="checkbox" checked="true" disabled>
          <span class="slider round disabled"></span>
        </label>
        <div class="button button-white button-small text-center" (click)="openNotificationConfiguration(vlahandoffnotifications)">Configure</div>
      </div>
    </div>
    <div class="setting-row">
      <div class="setting-icon">
        <i class="fas fa-chart-line"></i>
      </div>
      <div class="setting-content">
        <h6>Scorecard Reports</h6>
        <p class="setting-description">
          Scorecards contain conversion and engagement data from your nurturing efforts. <a href="https://info.nurtureboss.io/hubfs/Customer-Scorecard-FAQ.pdf" target="_blank">View Scorecard FAQ</a>
        </p>
      </div>
      <div class="setting-actions">
        <label class="switch">
          <input type="checkbox" disabled [(ngModel)]="notifications.scorecards.enabled" (change)="updateUserNotifications()">
          <span class="slider round disabled"></span>
        </label>
        <div class="button button-white button-small text-center" (click)="openNotificationConfiguration(scorecardreports)">Configure</div>
      </div>
    </div>
    <div class="setting-row">
      <div class="setting-icon">
        <i class="fas fa-clock"></i>
      </div>
      <div class="setting-content">
        <h6>Automated Reminders</h6>
        <p class="setting-description">
          Create a custom reminder/notification to send yourself each week from the Nurture Boss platform. Reminders are sent weekly on Tuesday at 10am MST.
        </p>
      </div>
      <div class="setting-actions">
        <label class="switch">
          <input type="checkbox" checked="true" [(ngModel)]="notifications.reportsReminder.enabled" (change)="updateUserNotifications()">
          <span class="slider round"></span>
        </label>
        <div class="button button-white button-small text-center" (click)="openNotificationConfiguration(automatedreminders)">Configure</div>
      </div>
    </div>
    <div class="setting-row">
      <div class="setting-icon">
        <i class="fas fa-file-invoice-dollar"></i>
      </div>
      <div class="setting-content">
        <h6>Overdue Invoice Reminders</h6>
        <p class="setting-description">
          Overdue invoice reminders are sent out on Thursday mornings at 10am MST to all customers. Use this setting to change where those reminders are sent.
        </p>
      </div>
      <div class="setting-actions">
        <label class="switch">
          <input type="checkbox" disabled checked="true" [(ngModel)]="notifications.invoiceReminders.enabled" (change)="updateUserNotifications()">
          <span class="slider round disabled"></span>
        </label>
        <div class="button button-white button-small text-center" (click)="openNotificationConfiguration(invoicereminders)">Configure</div>
      </div>
    </div>
    <div class="setting-row">
      <div class="setting-icon">
        <i class="fas fa-money-check-alt"></i>
      </div>
      <div class="setting-content">
        <h6>Rent Reminder Engagement</h6>
        <p class="setting-description">
          Weekly report sent out to alert you of Residents who have engaged with Rent Reminders. Sent at 10am MST on Fridays.
        </p>
      </div>
      <div class="setting-actions">
        <label class="switch">
          <input type="checkbox" disabled checked="true" [(ngModel)]="notifications.rentReminders.enabled" (change)="updateUserNotifications()">
          <span class="slider round disabled"></span>
        </label>
        <div class="button button-white button-small text-center" (click)="openNotificationConfiguration(rentreminders)">Configure</div>
      </div>
    </div>
    <div class="setting-row">
      <div class="setting-icon">
        <i class="fas fa-envelope"></i>
      </div>
      <div class="setting-content">
        <h6>Unread Message Notifications</h6>
        <p class="setting-description">
          Get notified when you have unread messages in the Nurture Boss platform.
        </p>
      </div>
      <div class="setting-actions">
        <label class="switch" [ngbTooltip]="isTwoWay ? 'These notifications are not sent when AI Two Way Texting is enabled' : null">
          <input type="checkbox" [disabled]="isTwoWay" checked="true" [(ngModel)]="notifications.unreadMessages.enabled" (change)="updateUserNotifications()">
          <span class="slider round disabled"></span>
        </label>
        <div class="button button-white button-small text-center" (click)="openNotificationConfiguration(unreadmessages)">Configure</div>
      </div>
    </div>
  </div>
</div>

<!-- System Notificaion Settings -->
<ng-template #systemnotifications let-modal>
  <div class="modal-header">
    <h5 class="modal-title">System Notifications</h5>
    <div class="float-right">
        <button type="button" class="button button-white button-small" (click)="exitModal($event, modal)">
          <i class="far fa-times" aria-hidden="true"></i>
        </button>
    </div>
  </div>
  <div class="modal-body">

    <!-- Set Recipients -->
    <div class="row max-100 margin-top-24 margin-left-0">
      <h6 class="margin-top-12">System Notifications Recipients</h6>
      <div class="col-md-12 no-margin no-padding">
        <p-chips [(ngModel)]="userData.user.notifications"></p-chips>
        <p class="helper-text">Press enter after typing email address to add.</p>
        <div class="pull-right">
          <button (click)="updateNotificationEmails(modal)" [disabled]="savingsNotifications" class="button button-dark margin-bottom-24 margin-top-12">
            <span *ngIf="savingsNotifications" class="spinner-border spinner-border-sm mr-1"></span>
            Save Configuration
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- VLA Handoff Notificaion Settings -->
<ng-template #vlahandoffnotifications let-modal>
  <div class="modal-header">
    <h5 class="modal-title">VLA Handoff Notifications</h5>
    <div class="float-right">
        <button type="button" class="button button-white button-small" (click)="exitModal($event, modal)">
          <i class="far fa-times" aria-hidden="true"></i>
        </button>
    </div>
  </div>
  <div class="modal-body">

    <!-- Set Recipients -->
    <div class="row max-100 margin-top-24 margin-left-0">
      <h6 class="margin-top-12">VLA Handoff Notifications Recipients</h6>
      <div class="col-md-12 no-margin no-padding">
        <p-chips [(ngModel)]="notifications.vlaHandoffs.emails"></p-chips>
        <p class="helper-text">Press enter after typing email address to add.</p>
        <div class="pull-right">
          <button (click)="updateUserNotifications(modal)" [disabled]="savingsNotifications" class="button button-dark margin-bottom-24 margin-top-12">
            <span *ngIf="savingsNotifications" class="spinner-border spinner-border-sm mr-1"></span>
            Save Configuration
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Scorecard Reports -->
<ng-template #scorecardreports let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Scorecard Reports</h5>
    <div class="float-right">
      <button type="button" class="button button-white button-small" (click)="exitModal($event, modal)">
        <i class="far fa-times" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">

    <!-- Set Recipients -->
    <div class="row max-100 margin-top-24 margin-left-0">
      <div class="col-md-12 no-margin no-padding">

        <h6 class="margin-top-12">Scorecard Recipients</h6>
        <div>
          <p-chips [(ngModel)]="notifications.scorecards.emails"></p-chips>
          <p class="helper-text">Press enter after typing email address to add.</p>
        </div>
        <h6 class="margin-top-12">Scorecard Cadence:</h6>
        <div class="form-group">
          <select class="form-control" [(ngModel)]="notifications.scorecards.cadence" [ngModelOptions]="{standalone: true}">
            <option [value]="scorecardsCadenceOptions[0]">{{ startCase(scorecardsCadenceOptions[0]) }}</option>
            <option [value]="scorecardsCadenceOptions[1]">{{ startCase(scorecardsCadenceOptions[1]) }}</option>
          </select>
          <p class="helper-text">If reports are delivered Monthly, they will be delivered on the 1st Monday of the month.</p>
        </div>
        <h6 class="margin-top-12">Report Data Start Date:</h6>
        <div class="form-group">
          <input class="form-control" placeholder="MM/DD/YYYY" [(ngModel)]="notifications.scorecards.dataStartDate" [ngModelOptions]="{standalone: true}" />
          <p class="helper-text">If no start date is specified, reports will default to a 30 day lookback</p>
        </div>
        <h6 class="margin-top-12" *ngIf="notifications.scorecards.cadence === 'weekly'">Report Delivery Day:</h6>
        <div class="form-group" *ngIf="notifications.scorecards.cadence === 'weekly'">
          <select class="form-control" [(ngModel)]="notifications.scorecards.dayOfTheWeek" [ngModelOptions]="{standalone: true}">
            <option [value]="'sunday'">Sunday</option>
            <option [value]="'monday'">Monday</option>
            <option [value]="'tuesday'">Tuesday</option>
            <option [value]="'wednesday'">Wednesday</option>
            <option [value]="'thursday'">Thursday</option>
            <option [value]="'friday'">Friday</option>
            <option [value]="'saturday'">Saturday</option>
          </select>
          <p class="helper-text">Reports will be delivered at 10am MT on the day of the week selected.</p>
        </div>
        <div class="pull-right">
          <button (click)="updateUserNotifications(modal)" [disabled]="savingsNotifications" class="button button-dark margin-bottom-24 margin-top-12">
            <span *ngIf="savingsNotifications" class="spinner-border spinner-border-sm mr-1"></span>
            Save Configuration
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Automated Reminders -->
<ng-template #automatedreminders let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Automated Reminders</h5>
    <div class="float-right">
      <button type="button" class="button button-white button-small" (click)="exitModal($event, modal)">
        <i class="far fa-times" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">

    <!-- Set Recipients -->
    <div class="row max-100 margin-top-24 margin-left-0">
      <div class="col-md-12 no-margin no-padding">
        <h6 class="margin-top-12">Reminder Recipients</h6>
        <div>
          <p-chips [(ngModel)]="notifications.reportsReminder.emails"></p-chips>
          <p class="helper-text">Press enter after typing email address to add.</p>
        </div>
        <h6 class="margin-top-12">Reminder Message</h6>
        <div class="form-group">
          <quill-editor
            [(ngModel)]="notifications.reportsReminder.customMessage"
            [modules]="editorModules"
            [styles]="{height: '200px', width: '100%'}"
          ></quill-editor>
        </div>
        <div class="pull-right">
          <button (click)="updateUserNotifications(modal)" [disabled]="savingsNotifications" class="button button-dark margin-bottom-24 margin-top-12">
            <span *ngIf="savingsNotifications" class="spinner-border spinner-border-sm mr-1"></span>
            Save Configuration
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Overdue Invoice Reminders -->
<ng-template #invoicereminders let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Overdue Invoice Reminders</h5>
    <div class="float-right">
        <button type="button" class="button button-white button-small" (click)="exitModal($event, modal)">
          <i class="far fa-times" aria-hidden="true"></i>
        </button>
    </div>
  </div>
  <div class="modal-body">

    <!-- Set Recipients -->
    <div class="row max-100 margin-top-24 margin-left-0">
      <h6 class="margin-top-12">Overdue Invoice Reminder Recipients</h6>
      <div class="col-md-12 no-margin no-padding">
        <p-chips [(ngModel)]="notifications.invoiceReminders.emails"></p-chips>
        <p class="helper-text">Press enter after typing email address to add.</p>
        <div class="pull-right">
          <button (click)="updateUserNotifications(modal)" [disabled]="savingsNotifications" class="button button-dark margin-bottom-24 margin-top-12">
            <span *ngIf="savingsNotifications" class="spinner-border spinner-border-sm mr-1"></span>
            Save Configuration
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Rent Reminder Engagement -->
<ng-template #rentreminders let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Rent Reminder Engagement</h5>
    <div class="float-right">
        <button type="button" class="button button-white button-small" (click)="exitModal($event, modal)">
          <i class="far fa-times" aria-hidden="true"></i>
        </button>
    </div>
  </div>
  <div class="modal-body">

    <!-- Set Recipients -->
    <div class="row max-100 margin-top-24 margin-left-0">
      <h6 class="margin-top-12">Rent Reminder Engagement Recipients</h6>
      <div class="col-md-12 no-margin no-padding">
        <p-chips [(ngModel)]="notifications.rentReminders.emails"></p-chips>
        <p class="helper-text">Press enter after typing email address to add.</p>
        <div class="pull-right">
          <button (click)="updateUserNotifications(modal)" [disabled]="savingsNotifications" class="button button-dark margin-bottom-24 margin-top-12">
            <span *ngIf="savingsNotifications" class="spinner-border spinner-border-sm mr-1"></span>
            Save Configuration
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!--  Unread Messages  -->
<ng-template #unreadmessages let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Unread Messages</h5>
    <div class="float-right">
        <button type="button" class="button button-white button-small" (click)="exitModal($event, modal)">
          <i class="far fa-times" aria-hidden="true"></i>
        </button>
    </div>
  </div>
  <div class="modal-body">

    <!-- Set Recipients -->
    <div class="row max-100 margin-top-24 margin-left-0">
      <h6 class="margin-top-12">Unread Message Notification Recipients</h6>
      <div class="col-md-12 no-margin no-padding">
        <p-chips [(ngModel)]="notifications.unreadMessages.emails"></p-chips>
        <p class="helper-text">Press enter after typing email address to add.</p>
      </div>
      <div class="col-md-12 no-margin no-padding">
        <h6 class="margin-top-12">Notification Cadence</h6>
        <p-dropdown [style]="{'width':'100%'}" [options]="unreadMessagesCadenceOptions" [(ngModel)]="notifications.unreadMessages.cadence"></p-dropdown>
      </div>
      <div class="col-md-12 no-margin no-padding">
        <div class="pull-right">
            <button (click)="updateUserNotifications(modal)" [disabled]="savingsNotifications" class="button button-dark margin-bottom-24 margin-top-12">
            <span *ngIf="savingsNotifications" class="spinner-border spinner-border-sm mr-1"></span>
            Save Configuration
            </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
