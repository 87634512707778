<!-- Update Usage Modal -->
<div id="account-mimic-warning" [class.admin]="isAdmin || isPartner" *ngIf="showMimicWarning">
  <p class="white">Warning! You are logged in as {{showMimicWarning}}</p>
</div>

<div id="app-container">
  <div class="wrapper">

    <!-- Left Sidebar -->
    <nav id="sidebar" [class.collapsed]="sidebarCollapsed" *ngIf="(regexIndexOf(noSideBarStates, router.url) === -1)">
      
      <!-- Logo and Collapse -->
      <div class="sidebar-header">
        <a class="navbar-brand" href="/">
          <img class="logo" [src]="brandingData.logoUrl" *ngIf="!sidebarCollapsed" />
        </a>
        <button class="btn btn-link sidebar-toggle" (click)="toggleSidebar()">
          <i class="far" [class.fa-angle-left]="!sidebarCollapsed" [class.fa-angle-right]="sidebarCollapsed"></i>
        </button>
      </div>

      <div class="sidebar-content">
        <!-- Standard navigation -->
        <div *ngIf="(!isGroup && !isPartner) || isMimicAccount">
          <ul class="nav flex-column">
            <li class="nav-item">
              <a routerLink="/admin" [class.active]="router.url === '/admin'" *ngIf="isAdmin || isPartner">
                <div class="nav-item-content">
                  <div class="nav-icon">
                    <i class="far fa-users-crown" *ngIf="!sidebarCollapsed"></i>
                    <i class="far fa-users-crown" *ngIf="sidebarCollapsed" container="body" placement="right" ngbTooltip="Admin"></i>
                  </div>
                  <div class="nav-text" *ngIf="!sidebarCollapsed">
                    Admin
                  </div>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="/group" [class.active]="router.url === '/group'" *ngIf="isGroup">
                <div class="nav-item-content">
                  <div class="nav-icon">
                    <i class="fa-thin fa-people-roof" *ngIf="!sidebarCollapsed"></i>
                    <i class="fa-thin fa-people-roof" *ngIf="sidebarCollapsed" container="body" placement="right" ngbTooltip="Properties"></i>
                  </div>
                  <div class="nav-text" *ngIf="!sidebarCollapsed">
                    Properties
                  </div>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="/dashboard" [class.active]="router.url.indexOf('/dashboard') > -1 && router.url.indexOf('vla') === -1">
                <div class="nav-item-content">
                  <div class="nav-icon">
                    <i class="far fa-chart-pie" *ngIf="!sidebarCollapsed"></i>
                    <i class="far fa-chart-pie" *ngIf="sidebarCollapsed" container="body" placement="right" ngbTooltip="Reports"></i>
                  </div>
                  <div class="nav-text" *ngIf="!sidebarCollapsed">
                    Reports
                  </div>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="/blasts" [class.active]="router.url.indexOf('/blasts') > -1" *ngIf="!isRealEstate">
                <div class="nav-item-content">
                  <div class="nav-icon">
                    <i class="far fa-bullhorn" *ngIf="!sidebarCollapsed"></i>
                    <i class="far fa-bullhorn" *ngIf="sidebarCollapsed" container="body" placement="right" ngbTooltip="Blasts"></i>
                  </div>
                  <div class="nav-text" *ngIf="!sidebarCollapsed">
                    Blasts
                  </div>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="/vla" [class.active]="router.url.indexOf('/vla') > -1 && router.url.indexOf('settings') === -1">
                <div class="nav-item-content">
                  <div class="nav-icon">
                    <i class="fa-regular fa-brain-circuit" *ngIf="!sidebarCollapsed"></i>
                    <i class="fa-regular fa-brain-circuit" *ngIf="sidebarCollapsed" container="body" placement="right" ngbTooltip="VLA"></i>
                  </div>
                  <div class="nav-text" *ngIf="!sidebarCollapsed">
                    VLA
                  </div>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="/automation" [class.active]="router.url.indexOf('/automation') > -1 && router.url.indexOf('vla') === -1">
                <div class="nav-item-content">
                  <div class="nav-icon">
                    <i class="fa-regular fa-wand-sparkles" *ngIf="!sidebarCollapsed"></i>
                    <i class="fa-regular fa-wand-sparkles" *ngIf="sidebarCollapsed" container="body" placement="right" ngbTooltip="Automations"></i>
                  </div>
                  <div class="nav-text" *ngIf="!sidebarCollapsed">
                    Automations
                  </div>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="/forms" [class.active]="router.url.indexOf('/forms') > -1">
                <div class="nav-item-content">
                  <div class="nav-icon">
                    <i class="fa-regular fa-file-lines" *ngIf="!sidebarCollapsed"></i>
                    <i class="fa-regular fa-file-lines" *ngIf="sidebarCollapsed" container="body" placement="right" ngbTooltip="Surveys"></i>
                  </div>
                  <div class="nav-text" *ngIf="!sidebarCollapsed">
                    Surveys
                  </div>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="/contacts" [class.active]="router.url.indexOf('/contact') > -1">
                <div class="nav-item-content">
                  <div class="nav-icon">
                    <i class="far fa-id-card" *ngIf="!sidebarCollapsed"></i>
                    <i class="far fa-id-card" *ngIf="sidebarCollapsed" container="body" placement="right" ngbTooltip="Contacts"></i>
                  </div>
                  <div class="nav-text" *ngIf="!sidebarCollapsed">
                    Contacts
                  </div>
                </div>
              </a>
            </li>
            <li class="nav-item" *ngIf="!claimsData.includes('vla')">
              <a routerLink="/conversations" [class.active]="router.url === '/conversations'">
                <div class="nav-item-content">
                  <div class="nav-icon">
                    <i class="far fa-comments" *ngIf="!sidebarCollapsed"></i>
                    <i class="far fa-comments" *ngIf="sidebarCollapsed" container="body" placement="right" ngbTooltip="Conversations"></i>
                  </div>
                  <div class="nav-text" *ngIf="!sidebarCollapsed">
                    Conversations <div class="unread-message-indicator" *ngIf="unreadMessages > 0">{{unreadMessages}}</div>
                  </div>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a routerLink="/tracking" [class.active]="router.url === '/tracking'">
                <div class="nav-item-content">
                  <div class="nav-icon">
                    <i class="far fa-list" *ngIf="!sidebarCollapsed"></i>
                    <i class="far fa-list" *ngIf="sidebarCollapsed" container="body" placement="right" ngbTooltip="Tracking"></i>
                  </div>
                  <div class="nav-text" *ngIf="!sidebarCollapsed">
                    Tracking
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>

        <!-- Parnter navigation -->
        <div *ngIf="isPartner && !isMimicAccount">
          <ul class="nav flex-column">
            <li class="nav-item">
              <a routerLink="/admin" [class.active]="router.url === '/admin'" *ngIf="isAdmin || isPartner">
                <div class="nav-item-content">
                  <div class="nav-icon">
                    <i class="far fa-users-crown" *ngIf="!sidebarCollapsed"></i>
                    <i class="far fa-users-crown" *ngIf="sidebarCollapsed" container="body" placement="right" ngbTooltip="Admin"></i>
                  </div>
                  <div class="nav-text" *ngIf="!sidebarCollapsed">
                    Admin
                  </div>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a (click)="logout()">
                <div class="nav-item-content">
                  <div class="nav-icon">
                    <i class="far fa-sign-out" *ngIf="!sidebarCollapsed"></i>
                    <i class="far fa-sign-out" *ngIf="sidebarCollapsed" container="body" placement="right" ngbTooltip="Logout"></i>
                  </div>
                  <div class="nav-text" *ngIf="!sidebarCollapsed">
                    Logout
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>

        <!-- Group navigation -->
        <div *ngIf="isGroup && !isMimicAccount">
          <ul class="nav flex-column">
            <li class="nav-item">
              <a routerLink="/group" [class.active]="router.url === '/group'" *ngIf="isGroup">
                <div class="nav-item-content">
                  <div class="nav-icon">
                    <i class="fa-thin fa-people-roof" *ngIf="!sidebarCollapsed"></i>
                    <i class="fa-thin fa-people-roof" *ngIf="sidebarCollapsed" container="body" placement="right" ngbTooltip="Properties"></i>
                  </div>
                  <div class="nav-text" *ngIf="!sidebarCollapsed">
                    Properties
                  </div>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a (click)="logout()">
                <div class="nav-item-content">
                  <div class="nav-icon">
                    <i class="far fa-sign-out" *ngIf="!sidebarCollapsed"></i>
                    <i class="far fa-sign-out" *ngIf="sidebarCollapsed" container="body" placement="right" ngbTooltip="Logout"></i>
                  </div>
                  <div class="nav-text" *ngIf="!sidebarCollapsed">
                    Logout
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="sidebar-footer" *ngIf="(!isGroup && !isPartner) || isMimicAccount">
        <ul class="nav flex-column">
          <li class="nav-item">
            <a (click)="logout()">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-sign-out" *ngIf="!sidebarCollapsed"></i>
                  <i class="far fa-sign-out" *ngIf="sidebarCollapsed" container="body" placement="right" ngbTooltip="Logout"></i>
                </div>
                <div class="nav-text" *ngIf="!sidebarCollapsed">
                  Logout
                </div>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/settings" [class.active]="router.url.indexOf('/settings') > -1">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-cog" *ngIf="!sidebarCollapsed"></i>
                  <i class="far fa-cog" *ngIf="sidebarCollapsed" container="body" placement="right" ngbTooltip="Settings"></i>
                </div>
                <div class="nav-text" *ngIf="!sidebarCollapsed">
                  Settings
                </div>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a href="https://help.nurtureboss.io/help" target="_blank">
              <div class="nav-item-content">
                <div class="nav-icon">
                  <i class="far fa-question" *ngIf="!sidebarCollapsed"></i>
                  <i class="far fa-question" *ngIf="sidebarCollapsed" container="body" placement="right" ngbTooltip="FAQs"></i>
                </div>
                <div class="nav-text" *ngIf="!sidebarCollapsed">
                  Support
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </nav>

    <!-- Main Content -->
    <div id="content">
      <main id="page-content-wrapper">
        <div class="container-fluid">
          <router-outlet></router-outlet>
        </div>
      </main>
    </div>
  </div>
</div>

<!-- TODO: Do we want this still? -->
<app-user-audits-widget *ngIf="(regexIndexOf(noSideBarStates, router.url) === -1)"></app-user-audits-widget>

<!-- Toast Template -->
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

<!-- Loading Template -->
<div id="global-loader" *ngIf="loaderActive">
  <div class="lds-grid">
    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
    <p class="long-load-message" *ngIf="longLoaderActive">Hang tight, this may take a few seconds.</p>
  </div>
</div>

<!-- Terms of Service Update -->
<div id="global-tos-update" *ngIf="showTosUpdate">
  <div class="tos-update-body">
    <img src="/assets/NB-logo-small.png" />
    <p>We've updated our Terms</p>
    <span>To continue please confirm that you agree to our Terms of Service</span>
    <a href="https://nurtureboss.io/terms-of-service/" target="_blank">Terms of Service</a>
    <button class="button button-dark" (click)="agreeToTerms()">I Agree</button>
  </div>
</div>

<div id="update-usage-modal" *ngIf="showUpdateUsageModal">
  <div class="usage-update-body">
    <p>Enter the new amount of usage for this account:</p>
    <div class="form-group">
      <label># of pages and texts</label>
      <input type="number" class="form-control" [(ngModel)]="newUsageAmount" [ngModelOptions]="{standalone: true}" id="newUsageAmount">
    </div>
    <button class="button button-dark" (click)="updateUsage()">Add Usage</button>
    <button class="button button-white margin-top-12" (click)="showChangeUsage()">Exit</button>
  </div>
</div>