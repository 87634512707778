<div id="forms-wrapper">

  <!-- Forms Sidebar -->
  <nav class="forms-sidebar" *ngIf="hasFormsPermission">
    <div class="sidebar-header">
      <h2 class="page-title">Forms</h2>
    </div>
    <ul class="nav flex-column">
      <li class="nav-item">
        <a routerLink="/forms/create" [class.active]="router.url === '/forms/create'">
          <div class="nav-item-content">
            <div class="nav-icon">
              <i class="far fa-user-circle"></i>
            </div>
            <div class="nav-text">
              New Survey
            </div>
          </div>
        </a>
      </li>
      <li class="nav-item">
        <a routerLink="/forms/view" [class.active]="router.url === '/forms/view'">
          <div class="nav-item-content">
            <div class="nav-icon">
              <i class="far fa-eye"></i>
            </div>
            <div class="nav-text">
              View Survey
            </div>
          </div>
        </a>
      </li>
    </ul>
  </nav>

  <!-- Forms Content -->
  <div class="forms-content" *ngIf="hasFormsPermission">
    <router-outlet></router-outlet>
  </div>

  <div class="row enable-surveys-wrap" *ngIf="!hasFormsPermission">
    <p class="text-center"><img src="/assets/enable-surveys-placeholder.gif" /></p>
    <h3 class="text-center">
      <a target="_blank" [href]="emailHref">Click Here</a> to enable Nurture Boss Surveys!
    </h3>
  </div>
</div>
