<div id="tracking">
  <div class="card">
      <div class="card-body">
        <div class="overflow-auto">
          <div class="card-actions pull-right">
              <div class="item" (click)="onTypeChange($event, 'textMessages')" [ngClass]="{'active': activeState === 'textMessages'}">
                  Text Messages
              </div>
              <div class="item" (click)="onTypeChange($event, 'emails')" [ngClass]="{'active': activeState === 'emails'}">
                  Emails
              </div>
          </div>
          <p class="table-row-indicator pull-left" *ngIf="totalRecords > 0">
            <strong>Showing {{pagination.first}} to {{pagination.last}} of {{totalRecords}} rows</strong>
          </p>
          <p class="table-row-indicator" *ngIf="totalRecords === 0">
            <strong>No tracking info found</strong>
          </p>
        </div>
        <p-table #dt [responsive]="true" [value]="messages" class="tracking-table"
          [lazy]="true" [paginator]="true" rows="25" totalRecords="{{totalRecords}}" [showCurrentPageReport]="true"
          currentPageReportTemplate="Showing {first} to {last} of {{totalRecords}} entries"
          [rowsPerPageOptions]="[25,50,100,250]" (onPage)="paginate($event)"
        >
          <ng-template pTemplate="header">
              <tr *ngIf="activeState === 'textMessages'">
                  <th>
                      Full Name
                  </th>
                  <th>
                      Page Link
                  </th>
                  <th>
                      To
                  </th>
                  <th>
                      Date
                  </th>
                  <th>
                      Status
                  </th>
              </tr>
              <tr *ngIf="activeState === 'textMessages'">
                  <th>
                      <input pInputText type="text" placeholder="Search" [(ngModel)]="pageNameSearchText" (ngModelChange)="debouncedSearch(pageNameSearchText, 'pageName')">
                  </th>
                  <th></th>
                  <th>
                    <input pInputText type="text" placeholder="Search" [(ngModel)]="phoneNumberSearchText" (ngModelChange)="debouncedSearch(phoneNumberSearchText, 'to')">
                  </th>
                  <th>
                    <p-calendar #createdCalendar selectionMode="range" (onSelect)="onDateSelect($event, 'created')"
                        (onClearClick)="onDateClear($event)" [showButtonBar]="true"
                        styleClass="ui-column-filter" placeholder="Date" [readonlyInput]="true" dateFormat="mm-dd-yy">
                    </p-calendar>
                  </th>
                  <th>
                      <input pInputText type="text" placeholder="Search" [(ngModel)]="statusSearchText" (ngModelChange)="debouncedSearch(statusSearchText, 'status')">
                  </th>
              </tr>
              <tr *ngIf="activeState === 'emails'">
                <th>
                    Full Name
                </th>
                <th>
                    To
                </th>
                <th>
                    Template
                </th>
                <th>
                    Date
                </th>
                <th>
                    Status
                </th>
            </tr>
            <tr *ngIf="activeState === 'emails'">
                <th>
                    <input pInputText type="text" placeholder="Search" [(ngModel)]="fullNameSearchText" (ngModelChange)="debouncedSearch(fullNameSearchText, 'fullName')">
                </th>
                <th>
                    <input pInputText type="text" placeholder="Search" [(ngModel)]="emailToSearchText" (ngModelChange)="debouncedSearch(emailToSearchText, 'to')">
                </th>
                <th>
                    <input pInputText type="text" placeholder="Search" [(ngModel)]="emailTemplateSearchText" (ngModelChange)="debouncedSearch(emailTemplateSearchText, 'template')">
                </th>
                <th>
                    <p-calendar #sentTimeCalendar selectionMode="range" (onSelect)="onDateSelect($event, 'sentTime')"
                        (onClearClick)="onDateClear($event)" [showButtonBar]="true"
                        styleClass="ui-column-filter" placeholder="Date" [readonlyInput]="true" dateFormat="mm-dd-yy">
                    </p-calendar>
                </th>
                <th>
                    <input pInputText type="text" placeholder="Search" [(ngModel)]="emailStatusSearchText" (ngModelChange)="debouncedSearch(emailStatusSearchText, 'status')">
                </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-message let-rowData>
              <tr *ngIf="activeState === 'textMessages'">
                  <td>
                    {{message.pageName}}
                  </td>
                  <td>
                    <span *ngIf="message.pageId === 'NONE' || !message.pageId">No Page sent</span>
                    <a *ngIf="message.pageId !== 'NONE' && message.pageId" [href]="apiHelper.pageTemplateBaseUrl + '?id=' + message.pageId + '&track=false'" target="_blank">View Page</a>
                  </td>
                  <td>
                    {{message.to}}
                  </td>
                  <td>
                    {{message.created | date}}
                  </td>
                  <td class="text-center">
                    {{message.status}}
                  </td>
              </tr>
              <tr *ngIf="activeState === 'emails'">
                <td>
                  {{message.fullName}}
                </td>
                <td>
                  {{message.to}}
                </td>
                <td>
                  <a target="_blank" [href]="apiHelper.emailTemplateBaseUrl + '/' + userData._id + '/' + message.emailObjectId">{{message.template}}</a>
                </td>
                <td>
                  {{message.sentTime | date}}
                </td>
                <td class="text-center">
                  {{message.status}}
                </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
  </div>
</div>