<div class="settings-wrapper">
  <!-- Settings Sidebar -->
  <nav class="settings-sidebar">
    <div class="sidebar-header">
      <h2 class="page-title">Settings</h2>
    </div>
    <ul class="nav flex-column">
      <li class="nav-item">
        <a routerLink="/settings/account" [class.active]="router.url === '/settings/account'">
          <div class="nav-item-content">
            <div class="nav-icon">
              <i class="far fa-user-circle"></i>
            </div>
            <div class="nav-text">
              Account
            </div>
          </div>
        </a>
      </li>

      <li class="nav-item" *ngIf="userData.claims.includes('bot')">
        <a routerLink="/settings/bot" [class.active]="router.url === '/settings/bot'">
          <div class="nav-item-content">
            <div class="nav-icon">
              <i class="fa-regular fa-book-open"></i>
            </div>
            <div class="nav-text">
              Knowledge Base
            </div>
          </div>
        </a>
      </li>

      <li class="nav-item" *ngIf="userData.claims.indexOf('real estate') === -1 || userData.claims.indexOf('admin') > -1">
        <a routerLink="/settings/defaults" [class.active]="router.url === '/settings/defaults'">
          <div class="nav-item-content">
            <div class="nav-icon">
              <i class="far fa-check-square"></i>
            </div>
            <div class="nav-text">
              Defaults
            </div>
          </div>
        </a>
      </li>

      <li class="nav-item">
        <a routerLink="/settings/compliance" [class.active]="router.url === '/settings/compliance'">
          <div class="nav-item-content">
            <div class="nav-icon">
              <i class="far fa-file-contract"></i>
            </div>
            <div class="nav-text">
              Compliance
            </div>
          </div>
        </a>
      </li>

      <li class="nav-item">
        <a routerLink="/settings/integrations" [class.active]="router.url === '/settings/integrations'">
          <div class="nav-item-content">
            <div class="nav-icon">
              <i class="far fa-sync-alt"></i>
            </div>
            <div class="nav-text">
              Integrations
            </div>
          </div>
        </a>
      </li>

      <li class="nav-item">
        <a routerLink="/settings/media" [class.active]="router.url === '/settings/media'">
          <div class="nav-item-content">
            <div class="nav-icon">
              <i class="far fa-images"></i>
            </div>
            <div class="nav-text">
              Media
            </div>
          </div>
        </a>
      </li>

      <li class="nav-item">
        <a routerLink="/settings/notifications" [class.active]="router.url === '/settings/notifications'">
          <div class="nav-item-content">
            <div class="nav-icon">
              <i class="far fa-flag"></i>
            </div>
            <div class="nav-text">
              Notifications
            </div>
          </div>
        </a>
      </li>

      <li class="nav-item" *ngIf="!userData.claims.includes('partner') && !userData.partnerOwnerId">
        <a routerLink="/settings/billing" [class.active]="router.url === '/settings/billing'">
          <div class="nav-item-content">
            <div class="nav-icon">
              <i class="far fa-credit-card"></i>
            </div>
            <div class="nav-text">
              Billing
            </div>
          </div>
        </a>
      </li>

      <li class="nav-item" *ngIf="userData.claims.includes('vla')">
        <a routerLink="/settings/vla" [class.active]="router.url === '/settings/vla'">
          <div class="nav-item-content">
            <div class="nav-icon">
              <i class="fa-regular fa-brain-circuit"></i>
            </div>
            <div class="nav-text">
              VLA
            </div>
          </div>
        </a>
      </li>
    </ul>
  </nav>

  <!-- Settings Content -->
  <div class="settings-content">
    <router-outlet></router-outlet>
  </div>
</div>
