<div class="page">
  <div class="alert alert-warning d-flex justify-content-between align-items-center form-warning">
    <p class="mb-0 text-warning">
      <span class="text-capitalize text-warning">* First Name, Last Name, and Email capture will automationally be added to ALL surveys</span>
    </p>
  </div>
  <div class="form" [formGroup]="form">
    <div class="form-content">
      <div class="form-inputs">
        <label for="name">Survey Name</label>
        <div class="form-input-container">
          <input
            autofocus
            placeholder="Give Your Survey A Name! Type Here..."
            class="form-control"
            type="text"
            id="name"
            formControlName="name"
          />
        </div>
        <div class="form-input-container">
          <label for="description">Survey Description</label>
          <textarea
            rows="5"
            class="form-control"
            placeholder="Give Your Survey A Description! Type Here..."
            id="description"
            formControlName="description"
          ></textarea>
        </div>
      </div>
      <div cdkDropList #questions="cdkDropList" class="form-questions" [class.no-questions]="getQuestionControls().length === 0" (cdkDropListDropped)="dropHandler($event)">
        <div *ngIf="getQuestionControls().length === 0" class="no-questions-text">Your survey is empty.<br/>Drag and drop or click the question types in the sidebar to add them to the survey.</div>
        <div *ngFor="let question of getQuestionControls(); index as i">
          <app-question
            [isDisabled]="this.formId && !this.canEditQuestions"
            [question]="question"
            [confirmDeleteQuestion]="getConfirmDeleteQuestion(i)"
            [duplicateQuestion]="getDuplicateQuestionFn(question, i)"
          ></app-question>
        </div>
      </div>
    </div>
    <div class="form-sidebar">
      <div class="form-buttons">
        <div>
          <h6>Active</h6>
          <label class="switch mr-2">
            <input type="checkbox" id="isActive" formControlName="active">
            <span class="slider round"></span>
          </label>
        </div>
        <div class="sidebar-buttons">
          <button
            *ngIf="!originalActiveStatus"
            class="button button-dark preview-button"
            (click)="openFormPreview()"
            [disabled]="form.invalid"
          >
            <i class="fa-regular fa-eye preview-icon"></i>
            View Preview
          </button>
          <button 
            class="button button-dark save-button"
            (click)="onSaveClickHandler()"
            [disabled]="form.invalid"
          >
            Save Survey
          </button>
        </div>
      </div>
      <div *ngIf="!this.canEditQuestions" class="alert alert-danger">
        This survey has been activated. <br/><br/> Questions can no longer be edited even if deactivated. <br/><br/> Name, Description, and Active can still be edited.
      </div>
      <div *ngIf="this.canEditQuestions" cdkDropList cdkDropListSortingDisabled [cdkDropListConnectedTo]="[questions]" class="form-question-options">
        <div cdkDrag [cdkDragData]="'shortanswer'" (click)="addNewQuestion('shortanswer')" class="question-option"><i class="fa-regular fa-pencil"></i>Short Answer</div>
        <div cdkDrag [cdkDragData]="'longanswer'" (click)="addNewQuestion('longanswer')" class="question-option"><i class="fa-regular fa-align-left"></i>Long Answer</div>
        <div cdkDrag [cdkDragData]="'singlechoice'" (click)="addNewQuestion('singlechoice')" class="question-option"><i class="fa-regular fa-circle-dot"></i>Single Choice</div>
        <div cdkDrag [cdkDragData]="'checkbox'" (click)="addNewQuestion('checkbox')" class="question-option"><i class="fa-regular fa-square-check"></i>Checkbox</div>
        <div cdkDrag [cdkDragData]="'dropdown'" (click)="addNewQuestion('dropdown')" class="question-option"><i class="fa-regular fa-circle-caret-down"></i>Dropdown</div>
        <div cdkDrag [cdkDragData]="'linear'" (click)="addNewQuestion('linear')" class="question-option"><i class="fa-regular fa-line-height"></i>Linear</div>
      </div>
    </div>
  </div>
  <nb-modal
    [name]="'delete-question-modal'"
    [header]="'Delete Question'"
    [confetti]="false"
    [additionalStyles]="'top: 35%; left: 0; right: 0; margin-left: auto; margin-right: auto; max-width: 450px;'"
  >
    <div class="delete-question">
      <div class="alert alert-danger">
        Are you sure you want to delete this question?
      </div>
      <div class="d-flex p-2 justify-content-end">
        <button class="button button-danger mr-2" (click)="deleteQuestion()">Delete</button>
        <button class="button button-white no-margin" (click)="closeModal('delete-question-modal');">Cancel</button>
      </div>
    </div>
  </nb-modal>
  <nb-modal
    [name]="'save-form-modal'"
    [header]="'Save Survey'"
    [confetti]="false"
    [additionalStyles]="'left: 0; right: 0; margin-left: auto; margin-right: auto; max-width: 450px;'"
  >
    <div class="save-form">
      <div *ngIf="form.get('active').value" class="alert alert-danger">
        WARNING: Your survey is set to become <strong>active</strong> if you save. After saving an active survey for the first time <strong>questions will no longer be editable</strong>. Active status, name, and description can still be edited.
      </div>
      <div *ngIf="!form.get('active').value" class="alert alert-primary">
        Your survey is set to become <strong>inactive</strong> if you save.
      </div>
      <div class="d-flex p-2 justify-content-end">
        <button class="button button-dark mr-2" (click)="saveForm()">Save</button>
        <button class="button button-white no-margin" (click)="closeModal('save-form-modal');">Cancel</button>
      </div>
    </div>
  </nb-modal>
</div>
