import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { APIHelperService } from './apiHelper.service';

export interface Voice {
  id: string;
  name: string;
  gender: string;
  description: string;
  language: string;
  provider: 'cartesia' | 'elevenlabs';
}

@Injectable({ providedIn: 'root' })
export class TtsService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  getVoices(provider: 'cartesia' | 'elevenlabs'): Observable<Voice[]> {
    return this.http.get<any>(this.apiHelper.fillUrl('ttsVoices', { provider }, {}))
      .pipe(map(({result}) => result));
  }

  getVoiceById(provider: 'cartesia' | 'elevenlabs', voiceId: string): Observable<Voice> {
    return this.http.get<any>(this.apiHelper.fillUrl('ttsVoiceById', { provider, voiceId }, {}))
      .pipe(map(data => data));
  }

  getTextToSpeech(provider: 'cartesia' | 'elevenlabs', voiceId: string, transcript: string) {
    return this.http.post(this.apiHelper.fillUrl('ttsTextToSpeech', { provider }, {}), 
      { voiceId, transcript },
      { responseType: 'blob' }
    );
  }
}
