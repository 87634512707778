// TODO: Need to consider websockets for realtime updates here.

// Angular:
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

// Libs
import * as _ from 'lodash';
const showdown  = require('showdown');
const converter = new showdown.Converter();

// Services:
import { AuthenticationService, ContactsService, LoaderService, SettingsService, SettingType, TextMessagesService, ToastService } from '@app/_services';
import { ThreadsService } from '@app/_services/threads.service';
import { DomSanitizer } from '@angular/platform-browser';
import { IVlaSettings } from '@nurtureboss/common/dist/types/settings';

@Component({
  selector: 'app-vlasingleconversation',
  templateUrl: './vlasingleconversation.component.html',
  styleUrls: ['./vlasingleconversation.component.less']
})
export class VLASingleConversationComponent implements OnInit {

  threadId: string;
  thread: any = {
    passedToOnsiteTeam: false,
  }; // TODO: Type this.
  backTo: string;
  contactName: string;
  messages: [any]; // TODO: Type this.
  replyMessage: any; // TODO: Type this.
  sendingMessage = false;
  registeredUserData: any; //TODO: Type this.
  convertToHTML; // TODO: Type this.
  contact: any; // TODO: Type this.
  threadMessages: [any];
  voiceRecordingSignedUrl: string;
  showMarkAsLostButton = false;
  vlaSettings: IVlaSettings = {};
  vlaHandoffWarning = '';
  loading = false;

  constructor(
    private loaderService: LoaderService,
    private threadsService: ThreadsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private toastService: ToastService,
    private contactService: ContactsService,
    private textMessagesService: TextMessagesService,
    private settingsService: SettingsService,
  ) {
    this.registeredUserData = this.authService.currentUserValue;
  }

  ngOnInit() { 
    this.activatedRoute.queryParams.subscribe(params => {
      this.threadId = params['id'];
      this.backTo = params['backTo'];
      this.contactName = params['contactName'];
      this.loadSingleThread();
    });
  }

  goBack() {
    this.router.navigate([this.backTo], { skipLocationChange: false });
  }

  async loadSingleThread(): Promise<void> {
    this.loading = true;
    this.loaderService.triggerLoader();
    const data = await this.threadsService.getThread(this.threadId).toPromise();
    const messages = await this.threadsService.getThreadMessages(this.threadId).toPromise();
    try {
      if (data?.result?.nurtureBossThread.recording) {
        const res = await this.threadsService.getVoiceRecordingSignedUrl(this.threadId).toPromise();
        this.voiceRecordingSignedUrl = res.result;
      }
    } catch (e) {
      // no-op
    }
    this.threadMessages = messages.result;
    this.threadMessages = this.threadMessages.sort((a, b) => {
      return new Date(a.created).getTime() - new Date(b.created).getTime();
    });

    // TODO: Add check that we got the data back we were expecting.
    // TODO: Why do we have messages and threadMessages? Clean this up, also looks like we return OpenAI
    // messages on a GET to threadId. I don't think there is a reason to do that.
    const contactData = await this.contactService.getContact(data.result.nurtureBossThread.contactId).toPromise();
    this.contact = contactData.result;
    this.showMarkAsLostButton = this.contact?.status?.toLowerCase() !== 'lost';
    if (!this.messages) {
      // this.messages = data.result.messages;
      this.messages = this.threadMessages;
      for (const message of this.messages) {
        message.HTMLConversion = '<div class="inner-text-wrap">' + converter.makeHtml(message.message) + '</div>';
      }
    }
    this.thread = data.result.nurtureBossThread;
    this.vlaSettings = (await this.settingsService.getSettingsByType(SettingType.VLA).toPromise()).result;
    this.vlaHandoffWarning = 'This conversation is in an unresolved state. This means the AI needs assistance to answer a question or address the concern of a prospect or resident. You can either reply directly using the text field and Send button below or you can reach out to the prospect or resident directly. Once you have resolved this issue, please mark this thread as "Resolved"';
    if (!this.vlaSettings.disableEmailHandoff) {
      this.vlaHandoffWarning += ' so the AI knows it can continue to communicate with this prospect or resident.';
    } else {
      this.vlaHandoffWarning += '.';
    }
    this.scrollView();
    this.loading = false;
    this.loaderService.stopLoader();
  }

  toDate(secs: number) {
    return new Date(secs * 1000);
  }

  async sendMessage(e) {
    if (this.replyMessage && this.threadId) {
      if (this.thread.isTextMessageThread) {
        this.sendingMessage = true;
        e.preventDefault();
        this.textMessagesService.replyToText({
          to: this.contact?.phoneNumber,
          message: this.replyMessage,
          contactId: this.contact?._id?.toString(),
          conversationId: this.threadId // I don't think this is used in any workflow but needed for the URL
        }).subscribe((_data) => {
          this.messages.push({
            created: new Date().getTime(),
            originator: 'assistant',
            HTMLConversion: this.replyMessage,
            message: this.replyMessage,
          });
          this.sendingMessage = false;
          this.scrollView();
          this.replyMessage = '';
        }, (e) => {
          this.sendingMessage = false;
          this.toastService.showError(e.cause || 'There was an error sending your text message');
        });
      } else {
        try {
          this.sendingMessage = true;
          await this.threadsService.addMessageToThread(
            this.threadId,
            {
              threadIdType: 'OpenAI',
              role: 'assistant',
              threadId: this.thread.threadId,
              message: this.replyMessage,
              contactId: this.thread.contactId,
              clientEmailAddress: this.contact.emailAddress,
              sendEmail: true,
            }
          ).toPromise();
          this.messages.push({
            created: new Date().getTime(),
            originator: 'assistant',
            HTMLConversion: this.replyMessage,
            message: this.replyMessage,
          });
          this.scrollView();
          this.replyMessage = '';
          this.sendingMessage = false;
        } catch (e) {
          this.sendingMessage = false;
          this.toastService.showError('There was an error sending your message. Please try again later.');
        }
      }
    } else {
      this.sendingMessage = false;
      this.toastService.showError('Missing required information to send message');
    }
  }

  scrollView () {
    setTimeout(function() {
      var elem = document.getElementById('chat-window');
      if (elem) {
        elem.scrollTop = elem.scrollHeight;
      }
    });
  }

  async unresolveThread(unresolved) {
    try {
      this.loaderService.triggerLoader();
      let updatedThread = await this.threadsService.updateThread(this.threadId, { passedToOnsiteTeam: unresolved }).toPromise();
      this.thread = updatedThread.result;
      this.loaderService.stopLoader();
      let message = 'Thread has been marked resolved';
      if (unresolved) {
        message = 'Thread has been marked unresolved';
      }
      this.toastService.showSuccess(message);
    } catch(e) {
      this.loaderService.stopLoader();
      this.toastService.showError('Could not change thread resolution. Please contact customersuccess@nurtureboss.io');
    }
  }

  async markContactAsLost() {
    try {
      await this.contactService.markContactAsLost(this.contact._id).toPromise();
      await this.unresolveThread(false);
      this.toastService.showSuccess('Contact has been marked as lost');
    } catch (e) {
      this.toastService.showError('Could not mark contact as lost');
    }
  }

  async textDisableAI(disable) {
    try {
      this.loaderService.triggerLoader();
      let updatedThread;
      updatedThread = await this.threadsService.updateThread(this.threadId, { textingAIDisabled: disable }).toPromise();
      this.thread = updatedThread.result;
      this.loaderService.stopLoader();
      if (disable) {
        this.toastService.showSuccess('AI disabled for contact');
      } else {
        this.toastService.showSuccess('AI enabled for contact');
      }
    } catch(e) {
      this.loaderService.stopLoader();
      this.toastService.showError('Could not disable AI. Please contact customersuccess@nurtureboss.io');
    }
  }

}
