import { Component} from '@angular/core';
import { ToastService, LoaderService, ContactsService } from '@app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { APIHelperService } from '../../_services/apiHelper.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-contactinfo',
  templateUrl: './contactinfo.component.html',
  styleUrls: ['./contactinfo.component.less'],
  providers: [DatePipe]
})
export class ContactInfoComponent {

  activeContact: any = {
    phoneNumber: 0
  };
  activeContactId: String;
  backTo: string;
  constructor(
    private toastService: ToastService,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private contactsService: ContactsService,
    public apiHelper: APIHelperService,
    private datePipe: DatePipe,
  ) {

      this.activatedRoute.queryParams.subscribe(params => {
        this.activeContactId = params['id'];
        this.backTo = params['backTo'];
        if (!this.activeContactId) {
          this.router.navigate(['dashboard']);
        } else {
          this.loadData(this.activeContactId);
        }
      });
  }

  loadData(id) {
    this.loaderService.triggerLoader();
    this.contactsService.getContact(id).subscribe((contact) => {
      this.activeContact = contact.result;
      // Set third party ID for easier UI access
      this.activeContact.thirdPartyGuestCardId = 
        this.activeContact.yardiGuestCardId ||
        this.activeContact.knockProspectId ||
        this.activeContact.realPageGuestCardId ||
        this.activeContact.entrataGuestCardId ||
        this.activeContact.resmanPersonId;
      this.activeContact.expectedMoveInDate = this.datePipe.transform(this.activeContact.expectedMoveInDate, 'MM/dd/yyyy');
      this.loaderService.stopLoader();
    }, (err) => {
      this.loaderService.stopLoader();
      this.toastService.show('There was an error loading contact data', { classname: 'bg-danger text-light', delay: 5000 });
    });
  }

  goBack() {
    this.router.navigate([this.backTo], { skipLocationChange: false });
  }
}