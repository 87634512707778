<div id="dashboard">
    <div class="card">
        <div class="card-body">
            <div class="card-actions">
                <div class="item" (click)="changeDashboard('scorecard')" [ngClass]="{'active': activeState === 'scorecard'}">
                    <i class="far fa-browser"></i>
                    Scorecard
                </div>
                <div *ngIf="isIntegrated() || isKnock()" class="item" (click)="changeDashboard('tourdata')" [ngClass]="{'active': activeState === 'tourdata'}">
                    <i class="far fa-door-open"></i>
                    Tour Data
                </div>
                <div *ngIf="isIntegrated() || isKnock()" class="item" (click)="changeDashboard('applicationdata')" [ngClass]="{'active': activeState === 'applicationdata'}">
                    <i class="far fa-pen"></i>
                    Application Data
                </div>
                <div *ngIf="isIntegrated() || isKnock()" class="item" (click)="changeDashboard('leasedata')" [ngClass]="{'active': activeState === 'leasedata'}">
                    <i class="far fa-file-contract"></i>
                    Lease Data
                </div>
                <div *ngIf="isIntegrated() || isKnock()" class="item" (click)="changeDashboard('tour-conversions')" [ngClass]="{'active': activeState === 'tour-conversions'}">
                    <i class="far fa-user"></i>
                    Tour Conversions
                </div>
                <div *ngIf="isIntegrated() || isKnock()" class="item" (click)="changeDashboard('application-conversions')" [ngClass]="{'active': activeState === 'application-conversions'}">
                    <i class="far fa-user"></i>
                    Application Conversions
                </div>
            </div>
            <div id="scorecard-loader" *ngIf="activeState === 'scorecard'"></div>
            <div id="tourdata-loader" *ngIf="activeState === 'tourdata'"></div>
            <div id="applicationdata-loader" *ngIf="activeState === 'applicationdata'"></div>
            <div id="leasedata-loader" *ngIf="activeState === 'leasedata'"></div>
            <div id="tours-loader" *ngIf="activeState === 'tour-conversions'"></div>
            <div id="applications-loader" *ngIf="activeState === 'application-conversions'"></div>
        </div>
    </div>
</div>