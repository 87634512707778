import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

type AudioSource = string | Blob | null;

@Component({
  selector: 'nb-player',
  templateUrl: './nb-player.component.html',
  styleUrls: ['./nb-player.component.less']
})
export class NbPlayerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() audioSource: AudioSource = null;
  @Input() disabled: boolean = false;
  @Input() externalLoading: boolean = false;
  @Output() loadRequested = new EventEmitter<void>();
  @ViewChild('progressRing') progressRing: ElementRef;

  private audio: HTMLAudioElement;
  private objectUrl: string | null = null;
  private destroy$ = new Subject<void>();
  private wasLoadRequested: boolean = false;
  
  isPlaying: boolean = false;
  isLoading: boolean = false;
  progress: number = 0;
  duration: number = 0;
  hasError: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.audioSource) {
      this.initializeAudio();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['audioSource']) {
      if (!changes['audioSource'].firstChange) {
        if (!changes['audioSource'].currentValue) {
          // If audio source is set to null, reset the player
          this.reset();
        } else {
          this.initializeAudio();
          if (this.wasLoadRequested && this.audioSource) {
            this.audio.play()
              .then(() => {
                this.isPlaying = true;
                this.cdr.detectChanges();
              })
              .catch((error) => {
                console.error('Error auto-playing audio:', error);
                this.reset();
              });
          }
        }
      }
      this.wasLoadRequested = false;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.cleanup();
  }

  private cleanup() {
    if (this.audio) {
      this.audio.pause();
      this.audio.remove();
    }
    if (this.objectUrl) {
      URL.revokeObjectURL(this.objectUrl);
      this.objectUrl = null;
    }
  }

  reset() {
    this.cleanup();
    this.isPlaying = false;
    this.isLoading = false;
    this.hasError = false;
    this.progress = 0;
    this.duration = 0;
    this.wasLoadRequested = false;

    this.updateProgressDisplay();
    this.cdr.detectChanges();
  }

  private isValidUrl(url: string): boolean {
    if (!url) return false;
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  }

  private getAudioUrl(source: AudioSource): string | null {
    if (!source) return null;
    
    if (typeof source === 'string') {
      if (!this.isValidUrl(source)) {
        console.error('Invalid audio URL:', source);
        return null;
      }
      return source;
    }
    
    // If it's a Blob, create an object URL
    if (source instanceof Blob) {
      if (this.objectUrl) {
        URL.revokeObjectURL(this.objectUrl);
      }
      this.objectUrl = URL.createObjectURL(source);
      return this.objectUrl;
    }
    
    return null;
  }

  private initializeAudio() {
    this.cleanup();

    if (!this.audioSource) {
      this.reset();
      return;
    }

    const audioUrl = this.getAudioUrl(this.audioSource);
    if (!audioUrl) {
      this.reset();
      this.hasError = true;
      this.cdr.detectChanges();
      return;
    }

    this.hasError = false;
    this.isLoading = true;
    this.audio = new Audio(audioUrl);
    
    // Audio event listeners
    this.audio.addEventListener('loadstart', () => {
      this.isLoading = true;
      this.progress = 0;
      this.updateProgressDisplay();
      this.cdr.detectChanges();
    });

    this.audio.addEventListener('loadedmetadata', () => {
      this.duration = this.audio.duration;
      this.cdr.detectChanges();
    });

    this.audio.addEventListener('canplay', () => {
      this.isLoading = false;
      this.cdr.detectChanges();
    });

    this.audio.addEventListener('ended', () => {
      this.isPlaying = false;
      this.progress = 0;
      this.updateProgressDisplay();
      this.cdr.detectChanges();
    });

    this.audio.addEventListener('timeupdate', () => {
      if (this.audio.duration && !isNaN(this.audio.duration)) {
        
        if (!this.audioSource) {
          this.progress = 0;
        } else {
          this.progress = (this.audio.currentTime / this.audio.duration) * 100;
        }
        this.updateProgressDisplay();
        this.cdr.detectChanges();
      }
    });

    this.audio.addEventListener('error', (error) => {
      console.error('Audio error:', error);
      this.reset();
      this.hasError = true;
      this.cdr.detectChanges();
    });
  }

  private updateProgressDisplay() {
    if (this.progressRing) {
      this.progressRing.nativeElement.style.setProperty('--progress', `${this.progress}%`);
    }
  }

  togglePlayPause() {
    if (this.externalLoading || !this.audioSource) {
      this.wasLoadRequested = true;
      this.loadRequested.emit();
      return;
    }

    if (!this.audio || this.hasError || this.isLoading) return;

    if (this.isPlaying) {
      this.audio.pause();
      this.isPlaying = false;
    } else {
      this.audio.play()
        .then(() => {
          this.isPlaying = true;
          this.cdr.detectChanges();
        })
        .catch((error) => {
          console.error('Error playing audio:', error);
          this.reset();
          this.hasError = true;
          this.cdr.detectChanges();
        });
    }
  }

  triggerLoad() {
    this.wasLoadRequested = true;
    this.loadRequested.emit();
  }
}
