<div *ngIf="!forms.length && !isLoading" class="row">
  <p class="text-center full-width margin-top-48">
    <img src="/assets/no-messaging.png" />
  </p>
  <h3 class="text-center full-width margin-top-48">You do not have any forms. <br/> Click "New Survey" to the left to create one!</h3>
</div>

<div class="card" [ngClass]="{'hidden': !forms.length}">
  <div class="card-body">
    <div class="overflow-auto">
      <p class="table-row-indicator pull-left" *ngIf="totalRecords > 0">
        <strong>Showing {{pagination.first}} to {{pagination.last}} of {{totalRecords}} rows</strong>
      </p>
      <p class="table-row-indicator" *ngIf="totalRecords === 0">
        <strong>No surveys found</strong>
      </p>
    </div>
    <p-table #dt 
      [responsive]="true"
      [value]="forms"
      class="form-table"
      styleClass="ui-table-blasts"
      [lazy]="true"
      [paginator]="true"
      rows="25"
      totalRecords="{{totalRecords}}"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {{totalRecords}} entries"
      [rowsPerPageOptions]="[25,50,100,250]"
      (onPage)="paginate($event)"
    >
      <ng-template pTemplate="header">
          <tr>
            <th>
              Created Date
            </th>  
            <th>
              Survey Name
            </th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th>
              <p-calendar #createdCalendar
                selectionMode="range"
                (onSelect)="onDateSelect($event, 'created')"
                (onClearClick)="onDateClear($event)"
                [showButtonBar]="true"
                styleClass="ui-column-filter"
                placeholder="Date"
                [readonlyInput]="true"
                dateFormat="mm-dd-yy"
              ></p-calendar>
            </th>
            <th>
              <input pInputText type="text" placeholder="Search" [(ngModel)]="formNameSearchText" (ngModelChange)="debouncedSearch(formNameSearchText, 'name')">
            </th>
            <th></th>
            <th class="centered">Active Status</th>
            <th></th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-form let-rowData>
        <tr>
          <td>
            {{form.created | date}}
          </td>
          <td>
            {{form.name}}
          </td>
          <td>
            <div class="copy-url-box">
              <pre>{{ generateViewFormUrl(form._id) }}</pre>
              <i
                class="fal fa-copy fa-lg ml-2"
                (click)="copyToClipboard(generateViewFormUrl(form._id, !form.active))"
                placement="left"
                ngbTooltip="Click to Copy"
              ></i>
            </div>
          </td>
          <td>
            <div class="active-toggle">
              <label class="switch">
                <input type="checkbox" [ngModel]="form.active" [id]="form._id" (click)="toggleFormActiveStatus(form)">
                <span class="slider round"></span>
              </label>
            </div>
          </td>
          
          <td>
            <div class="row-icons">
              <i [ngbTooltip]="'Edit Survey'" class="fa-regular fa-pencil" (click)="openEditFormPage(form._id)"></i>
              <i [ngbTooltip]="'Clone Survey'" class="fa-regular fa-copy" (click)="openCloneFormModal(form)"></i>
              <i [ngbTooltip]="'View Results'" class="fa-regular fa-chart-mixed" (click)="openFormResultsPage(form._id)"></i>
              <i [ngbTooltip]="form.active ? 'View Survey' : 'Preview Survey'" class="fa-regular fa-eye" (click)="openFormPage(generateViewFormUrl(form._id, !form.active))"></i>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <nb-modal
    [name]="'set-form-active-modal'"
    [header]="'Activate Survey'"
    [confetti]="false"
    [additionalStyles]="'left: 0; right: 0; margin-left: auto; margin-right: auto; max-width: 450px;'"
  >
    <div class="save-form">
      <div class="alert alert-danger">
        WARNING: Your survey is set to become active. Afterwards <strong>questions will no longer be editable even if the survey is later deactivated</strong>. Active status, name, and description can still be edited.
      </div>
      <div class="d-flex p-2 justify-content-end">
        <button class="button button-dark mr-2" (click)="toggleFormActiveStatus(stagedForm, true)">Save</button>
        <button class="button button-white no-margin" (click)="cancelCloseSetFormActiveModal()">Cancel</button>
      </div>
    </div>
  </nb-modal>
  <nb-modal
    [name]="'clone-form-modal'"
    [header]="'Clone Survey'"
    [confetti]="false"
    [additionalStyles]="'left: 0; right: 0; margin-left: auto; margin-right: auto; max-width: 450px;'"
  >
    <div class="save-form">
      <div class="alert alert-warning">
        Clone <strong>{{stagedForm && stagedForm.name}}</strong> survey? Survey will not be active and can be edited.
      </div>
      <div class="d-flex p-2 justify-content-end">
        <button class="button button-dark mr-2" (click)="cloneForm()">Ok</button>
        <button class="button button-white no-margin" (click)="closeModal('clone-form-modal')">Cancel</button>
      </div>
    </div>
  </nb-modal>
</div>
