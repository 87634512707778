import { Environment } from './environmentType';

export const environment: Environment = {
    baseUrl: 'https://app.nurtureboss.io/',
    eventsUrl: 'https://events.nurtureboss.io/',
    textsUrl: 'https://texts.nurtureboss.io/',
    emailsUrlWithoutProtocol: '//emails.nurtureboss.io/',
    pageTemplateBaseUrlWithoutProtocol: '//aptlife.co/',
    production: true,
    apiUrl: 'https://app.nurtureboss.io/'
}
