import { Component, OnInit} from '@angular/core';
import { AuthenticationService } from '@app/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vla',
  templateUrl: './vlaautomations.component.html',
  styleUrls: ['./vlaautomations.component.less']
})
export class VLAAutomationsComponent {

  userData: any;

  constructor(
    private authService: AuthenticationService,
    public router: Router,
  ) {
    this.userData = this.authService.currentUserValue.user;
  }

}
