<div id="vla-conversation">
  <div class="page-title">
    <h2 (click)="goBack()" class="pointer go-back-link pull-left"><i class="fa-regular fa-chevron-left"></i> Go Back</h2>
    <h2 class="pull-right">Conversation with {{contactName || 'Unknown'}}</h2>
  </div>

  <!-- Email & Text VLA Thread UI -->
  <div class="email-text-wrapper" *ngIf="!thread.callId && !loading">
    <div class="chat-wrapper">

      <div id="chat-window">
        <div class="chat-bubble-wrap" *ngFor="let message of messages; let i = index">
          <div
            class="chat-text"
            [ngClass]="{'from-them': message.originator === 'user', 'from-me': message.originator === 'assistant'}"
            [innerHTML]="message.HTMLConversion"
          ></div>
          <span
            [ngClass]="{'them-time': message.originator === 'user', 'me-time': message.originator === 'assistant'}"
            class="timestamp"
          >
            {{message.created | date:'MMM d, y, h:mm a'}}
        </span>
        </div>
      </div>

      <div class="reply-input" *ngIf="!thread.visitorId">
        <div class="margin-bottom-12 margin-top-12">
          <div class="input-group">
            <textarea rows="5" placeholder="Reply..." [(ngModel)]="replyMessage"
            [ngModelOptions]="{standalone: true}" class="form-control reply-field"></textarea>
          </div>
        </div>

        <div class="pull-left">
          <button class="button button-dark button-small submit-button" [disabled]="sendingMessage" (click)="sendMessage($event)"><span *ngIf="sendingMessage" class="spinner-border spinner-border-sm mr-1"></span>Send</button>
        </div>
      </div>

    </div>

    <!-- TODO: Populate with contact information -->
    <div class="contact-info-window">

      <!-- Text Disabled or Unresolved Thread Warning Message -->
      <div *ngIf="(thread.textingAIDisabled || thread.passedToOnsiteTeam) && !loading">
        <div class="alert alert-warning">
          <span>
            {{thread.textingAIDisabled ? 'AI is disabled for texting on this contact. If this contact replies, the AI will not respond. If you do not want to monitor this conversation, and want the AI to respond, click the “Enable AI” button. ': ''}}
            {{thread.passedToOnsiteTeam ? vlaHandoffWarning : ''}}
          </span>
        </div>
      </div>
      <div class="thread-action-buttons">
        <h5>Thread Actions</h5>
        <!-- Thread Resolution -->
        <button *ngIf="!thread.passedToOnsiteTeam" class="button button-small button-danger" (click)="unresolveThread(true)">Mark Thread As Unresolved</button>
        <button *ngIf="thread.passedToOnsiteTeam" class="button button-small button-dark" (click)="unresolveThread(false)">Mark Thread As Resolved</button>

        <!-- Mark Lead as Lost -->
        <button *ngIf="showMarkAsLostButton" id="mark-as-lost-button" class="button button-small button-basic" (click)="markContactAsLost()">Mark as Lost{{thread.passedToOnsiteTeam ? ' and Resolve' : ''}}</button>
        
        <!-- Disable AI for Text Threads -->
        <button *ngIf="thread.isTextMessageThread && !thread.textingAIDisabled" class="disable-ai-button button button-small button-danger" (click)="textDisableAI(true)">Disable AI</button>
        <button *ngIf="thread.isTextMessageThread && thread.textingAIDisabled" class="disable-ai-button button button-small button-dark" (click)="textDisableAI(false)">Enable AI</button>
      </div>
      <h5 class="margin-top-24">Contact Information</h5>
      <p>Name: {{contactName}}</p>
    </div>
  </div>
  
  <!-- Voice VLA Thread UI -->
  <div class="voice-call-wrapper" *ngIf="thread.callId && !loading">
    <div class="transcript-wrapper">
      <div>
        <ng-container *ngIf="voiceRecordingSignedUrl">
          <h5>Call Recording:</h5>
          <figure>
            <audio controls [src]="voiceRecordingSignedUrl"></audio>
          </figure>
        </ng-container>
        <h5>Summary:</h5>
        <div class="alert alert-summary margin-top-0">{{thread.summary}}</div>
        <h5>Transcript:</h5>
        <div>
          <div class="chat-bubble-wrap" *ngFor="let message of messages; let i = index">
            <div
              class="chat-text"
              [ngClass]="{'from-them': message.originator === 'user', 'from-me': message.originator === 'assistant'}"
              [innerHTML]="message.HTMLConversion"
            ></div>
            <span
              [ngClass]="{'them-time': message.originator === 'user', 'me-time': message.originator === 'assistant'}"
              class="timestamp"
            >
              {{message.created | date:'MMM d, y, h:mm a'}}
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- TODO: Populate with contact information -->
    <div class="contact-info-window">
      <h5>Contact Information</h5>
      <p>Name: {{contactName}}</p>
    </div>
  </div>
</div>