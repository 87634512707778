<div class="page">
  <!-- TCPA Enabled Sources-->
  <div *ngIf="hasIntegration" class="margin-bottom-48">
    <div class="overflow-auto">
      <h6 class="title pull-left">Opted-In Sources</h6>
      <button class="button button-dark pull-right" [disabled]="savingSettings" (click)="updateSources()">
        <span *ngIf="savingSettings" class="spinner-border spinner-border-sm mr-1"></span>
        Update Sources
      </button>
    </div>
    <div>
      <div *ngIf="!loading">
        <app-source-picker
          [potentialSources]="sources"
          [storedSources]="storedSources"
          [integrationType]="integrationType"
          [allowCustomSources]="allowCustomSources"
        ></app-source-picker>
      </div>
    </div>
  </div>

  <!-- TCPA SETTINGS -->
  <!-- Intentionally do not expose to Partners. -->
  <div>
    <div *ngIf="loadingUserSettings" class="spinner-border spinner-border-sm"></div>
    <div *ngIf="!loadingUserSettings" class="user-table no-border">
      <div *ngIf="isAdmin">
        <h6>Single Opt-In</h6>
        <label class="switch">
        <input type="checkbox" [(ngModel)]="userData.optIn" (change)="updateUserSettings()" [ngModelOptions]="{standalone: true}" >
        <span class="slider round"></span>
        </label>
      </div>
      <div >
        <h6>Prefer to Text</h6>
        <label class="switch">
        <input type="checkbox" [(ngModel)]="userSmsSettings.preferToText" (change)="updateSmsSettings()" [ngModelOptions]="{standalone: true}" >
        <span class="slider round"></span>
        </label>
      </div>
      <!-- ######## Opt-In Keyword disabled for the time ######## -->
      <!-- <div class="form-group">
        <label>Opt-In Keyword</label>
        <input type="text" [readonly]="!userData.optIn" class="form-control" [(ngModel)]="userData.keyword" [ngModelOptions]="{standalone: true}" id="optinKeyword" aria-describedby="optinKeyword" placeholder="Enter opt-in keyword">
      </div> -->
    </div>
  </div>
</div>
