<div id="media">
  <div class="card">
    <div class="overflow-auto">
      <h6 class="title pull-left">Media Library</h6>
      <button class="button button-dark pull-right" [disabled]="loadingImage" (click)="onUploadMediaClicked()">
        <span *ngIf="loadingImage" class="spinner-border spinner-border-sm mr-1"></span>
        Upload New Media
      </button>
      <input
        *ngIf="!loadingImage"
        id="upload-media-input"
        (change)="uploadMedia($event)"
        type="file"
        [accept]="getAcceptedFileTypes()"
      />
    </div>
    <div class="card-body">
    <div class="row" *ngIf="!media || media.length === 0">
      <h3 class="empty-media-holder">You don't have any media yet!<i class="far fa-folder-open"></i></h3>
    </div>
    <div class="row" *ngIf="media && media.length > 0">
      <div class="col-md-3 margin-bottom-24 image-wrapper" *ngFor="let image of media; let i = index">
        <div *ngIf="!isFile(image.url)" (click)="openImage(image.url)" class="image-holder" [style.background-image]="'url(' + image.url + ')'"></div>
        <i
          (click)="openImage(image.url)"
          *ngIf="isFile(image.url)"
          class="file-icon far {{getFileIconClass(image.url)}}"
         ></i>
        <h5>{{image.fileName}}</h5>
        <span class="file-size">{{image.created | date:'MM/dd/yyyy'}}</span>
        <span class="file-size">{{image.fileSize / 1000 | number:'1.0-0'}} KB</span>
        <span class="delete-image" [class.disabled]="image.loading" (click)="deleteImage(image, i)">
          <div *ngIf="image.loading" class="spinner-border spinner-border-sm"></div>
          <i class="far fa-trash-alt"></i>
        </span>
      </div>
    </div>
  </div>
</div>