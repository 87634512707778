<div id="contact">

  <!-- Contact Sidebar -->
  <nav class="contact-sidebar">
    <div class="sidebar-header">
      <h2 class="page-title">Contact</h2>
    </div>
    <ul class="nav flex-column">
      <li class="nav-item">
        <a (click)="navigateTo('events')" [class.active]="router.url.includes('/contact/events')">
          <div class="nav-item-content">
            <div class="nav-icon">
              <i class="far fa-calendar"></i>
            </div>
            <div class="nav-text">
              Events
            </div>
          </div>
        </a>
      </li>
      <li class="nav-item">
        <a (click)="navigateTo('info')" [class.active]="router.url.includes('/contact/info')">
          <div class="nav-item-content">
            <div class="nav-icon">
              <i class="far fa-user-circle"></i>
            </div>
            <div class="nav-text">
              Contact Info
            </div>
          </div>
        </a>
      </li>
    </ul>
  </nav>

  <!-- Contact Content -->
  <div class="contact-content">
    <router-outlet></router-outlet>
  </div>
</div>