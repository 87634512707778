import { Component, OnInit} from '@angular/core';
import { AuthenticationService } from '@app/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vladashboard',
  templateUrl: './vladashboard.component.html',
  styleUrls: ['./vladashboard.component.less']
})
export class VLADashboardComponent {

  userData: any;

  constructor(
    private authService: AuthenticationService,
    public router: Router,
  ) {
    this.userData = this.authService.currentUserValue.user;
  }
}
