import { Component, OnInit, TemplateRef} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AuthenticationService,
  LoaderService,
  ToastService,
  VLAService,
  UsersService,
  SettingsService,
  SettingType,
} from '@app/_services';
import { Router } from '@angular/router';

type VlaType = 'email' | 'text' | 'voice' | 'chatbot';

const MODAL_OPTIONS = { windowClass : 'custom-modal-styles-new enable-vla-modal'};

@Component({
  selector: 'app-vla',
  templateUrl: './vla.component.html',
  styleUrls: ['./vla.component.less']
})
export class VLAComponent implements OnInit {

  userData: any;
  isAdmin = false;
  vlaHref = '';
  isEmailVlaEnabled = false;
  isTextVlaEnabled = false;
  isVoiceVlaEnabled = false;
  isChatbotAssistantEnabled = false;
  isEnabling = false;
  vlaTypeToEnable?:VlaType = null;
  originalBotName = '';
  botName = '';
  botNameEditable = false;

  constructor(
    private authService: AuthenticationService,
    public router: Router,
    private vlaService: VLAService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private usersService: UsersService,
    private modalService: NgbModal,
    private settingsService: SettingsService,
  ) {
    this.userData = this.authService.currentUserValue.user;
    this.vlaHref = `mailto:sales@nurtureboss.io?subject=Id like to enable the VLA on my account&body=Please contact me to enable the VLA on my account at property ${this.userData.propertyName}!`
  }

  ngOnInit(): void {
    // Adjust CSS to give CS a good experience too :)
    this.isAdmin = this.userData.claims.includes('admin');

    if (this.userData.vlaAssistantId) {
      this.isEmailVlaEnabled = true;
    }

    if (this.userData.textMessageAssistantId) {
      this.isTextVlaEnabled = true;
    }

    if (this.userData.voiceAssistantId) {
      this.isVoiceVlaEnabled = true;
    }

    if (this.userData.chatBotAssistantId) {
      this.isChatbotAssistantEnabled = true;
    }

    if (
      this.userData.voiceAssistantId ||
      this.userData.vlaAssistantId ||
      this.userData.textMessageAssistantId ||
      this.userData.chatBotAssistantId
    ) {
      // If the user has any existing bot, get the bot name. We prompt for an updated name when we confirm enabling a bot.
      this.settingsService.getSettingsByType(SettingType.VLA).subscribe((data: any) => {
        this.originalBotName = data.result.botName;
        this.botName = this.originalBotName;
        this.botNameEditable = !this.botName;
      });
    }
  }

  vlaEnabled() {
    return this.authService.currentUserValue?.user?.claims?.includes('vla');
  }

  async enableVLA(type: VlaType) {
    try {
      if (this.isEnabling) return;

      this.isEnabling = true;

      await this.settingsService.saveSettingsByType(SettingType.VLA, { botName: this.botName }).toPromise();

      this.originalBotName = this.botName;
      this.loaderService.triggerLoader();

      if (type === 'voice') {
        await this.vlaService.enableVoiceVLA().toPromise();
        this.toastService.showSuccess('Voice VLA Enabled');
      } else if (type === 'email') {
        await this.vlaService.enableEmailVLA().toPromise();
        this.toastService.showSuccess('Email VLA Enabled');
      } else if (type === 'text') {
        await this.vlaService.enableTextVLA().toPromise();
        this.toastService.showSuccess('Text VLA Enabled');
      } else if (type === 'chatbot') {
        await this.vlaService.enableChatbot().toPromise();
        this.toastService.showSuccess('Chatbot Assistant Enabled');
      } else {
        throw new Error('Invalid type');
      }

      this.usersService.loadUser();

      // Wait 2 seconds so that loadUser can run and get the updated user
      // Otherwise the enable button stays on the page
      setTimeout(async () => {
        window.location.reload();
        this.loaderService.stopLoader();
      }, 2000);
    } catch (e) {
      this.loaderService.stopLoader();
      this.toastService.showError(`Could not enable ${type || ''} VLA`);
      if(e?.userMessage) { this.toastService.showError(e.userMessage) }
    } finally {
      this.modalService.dismissAll();
      this.isEnabling = false;
    }
  }

  openVlaModal(content: TemplateRef<NgbModal>) {
    this.botNameEditable = !this.botName;
    this.modalService.open(content, MODAL_OPTIONS)
      .result.catch((res) => {
        if (!res) {
          this.botNameEditable = false;
          this.botName = this.originalBotName;
        }
      })
  }
}
