// Angular:
import { Component, OnInit } from '@angular/core';

// Services:
import {
  AuthenticationService,
  ToastService,
  LoaderService,
  SettingsService,
  SettingType,
  VoiceVLAService,
  VLAService,
  TtsService,
  Voice
} from '@app/_services';

// Types
import { User } from '@nurtureboss/common/dist/types/users';
import { IVlaSettings, PropertySettings } from '@nurtureboss/common/dist/types/settings';

enum TransferCallDestinationType {
  EmergencyMaintenance = 'emergencyMaintenance',
  Representative = 'representative',
  ResidentRepresentative = 'residentRepresentative',
}

interface TransferCallDestination {
  number: number,
  message: string,
  enabled: boolean,
  type: TransferCallDestinationType,
}

@Component({
  selector: 'app-integrations',
  templateUrl: './vla.component.html',
  styleUrls: ['./vla.component.less'],

})
export class VLASettingsComponent implements OnInit {
  user: Partial<User> = {};
  propertySettings: PropertySettings = {
    emergencyMaintenancePhoneNumber: '',
  }
  vlaSettings: Partial<IVlaSettings> = {
    disableEmailHandoff: false,
    botName: '',
  };
  originalBotName = '';
  originalVoiceId = '';
  voiceAssistant: any = {};
  savingSettings = false;
  hasVoiceAssistant = false;
  hasEmailAssistant = false;
  hasTextEmailAssistant = false;
  emergencyMaintenanceDestination: TransferCallDestination = {
    type: TransferCallDestinationType.EmergencyMaintenance,
    enabled: false,
    number: null,
    message: 'I am forwarding your call to emergency maintenance. Please stay on the line.',
  };
  representativeDestination: TransferCallDestination = {
    type: TransferCallDestinationType.Representative,
    enabled: false,
    number: null,
    message: 'I am forwarding your call to a representative. Please stay on the line.',
  }
  residentRepresentativeDestination: TransferCallDestination = {
    type: TransferCallDestinationType.ResidentRepresentative,
    enabled: false,
    number: null,
    message: 'I am forwarding your call to a resident representative. Please stay on the line.',
  };
  private _greetingMessage: string = '';
  private _disableEmailHandoffField: boolean = false;
  isMultilingual = false;
  availableVoices: Voice[] = [];
  selectedVoice: Voice;
  selectedLanguage: string = 'EN';
  availableLanguages: string[] = [];
  filteredVoices: Voice[] = [];
  testVoiceText: string = '';

  constructor(
    private authService: AuthenticationService,
    private voiceVLAService: VoiceVLAService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private settingsService: SettingsService,
    private vlaService: VLAService,
    private ttsService: TtsService,
  ) {
    // no-op
  }

  ngOnInit() {
    this.loaderService.triggerLoader();
    this.loadData();
  }

  // Enable Handoff Field
  get disableEmailHandoffField() { return this._disableEmailHandoffField }
  set disableEmailHandoffField(value) { this._disableEmailHandoffField = value }

  // Greeting Message Field
  get greetingMessage(): string { return this._greetingMessage }
  set greetingMessage(value: string) { this._greetingMessage = value }

  async loadData() {
    this.user = this.authService.currentUserValue.user;
    if (this.user.voiceAssistantId) {
      await this.loadVoiceAssistant(this.user.voiceAssistantId);
    }
    if (this.user.vlaAssistantId && this.user.textMessageAssistantId) {
      this.hasTextEmailAssistant = true;
    }
    if (this.user.vlaAssistantId) {
      this.hasEmailAssistant = true;
    }
    this.propertySettings = (await this.settingsService.getSettingsByType(SettingType.Property).toPromise()).result;
    this.vlaSettings = (await this.settingsService.getSettingsByType(SettingType.VLA).toPromise()).result;
    if (this.vlaSettings) {
      this.originalBotName = this.vlaSettings.botName || '';
      this.disableEmailHandoffField = this.vlaSettings.disableEmailHandoff;
    }
    this.emergencyMaintenanceDestination.number = parseInt(this.propertySettings.emergencyMaintenancePhoneNumber);
    if (this.hasVoiceAssistant) {
      this.loadVoices();
    }
    this.loaderService.stopLoader();
  }

  async loadVoiceAssistant(voiceAssistantId) {
    try {
      const res = await this.voiceVLAService.getVoiceAssistant(voiceAssistantId).toPromise();
      if (!res.result.id) {
        return;
      }

      // Update the component state
      this.hasVoiceAssistant = true;
      this.voiceAssistant = res.result;
      this.greetingMessage = res.result?.firstMessage;
      this.isMultilingual = res.result?.voice?.provider != 'cartesia';
      this.originalVoiceId = res.result?.voice?.voiceId;

      // Set the call transfer destinations if they exist
      if (res.result.model?.tools?.length) {
        const transferCallTool = res.result.model.tools.find(tool => tool.type === 'transferCall');
        if (transferCallTool?.destinations?.length) {
          transferCallTool.destinations.forEach(destination => {
            // There is no name field on destinations so have to search descriptions for keywords
            // Descriptions are hardcoded and not editable by users so they are consistent
            if (destination.description?.toLowerCase() === ('emergency maintenance')) {
              this.emergencyMaintenanceDestination.message = destination.message;
              this.emergencyMaintenanceDestination.enabled = true;
            }
            if (destination.description?.toLowerCase() === ('representative')) {
              this.representativeDestination.number = parseInt(destination.number.slice(2)); // Slice to remove +1
              this.representativeDestination.message = destination.message;
              this.representativeDestination.enabled = true;
            }
            if (destination.description?.toLowerCase() === ('resident representative')) {
              this.residentRepresentativeDestination.number = parseInt(destination.number.slice(2)); // Slice to remove +1
              this.residentRepresentativeDestination.message = destination.message;
              this.residentRepresentativeDestination.enabled = true;
            }
          })
        }
      }
    } catch (err) {
      this.toastService.showError('Error retrieving voice VLA settings.');
    }
  }

  async updateCallTransferDestinations() {
    if (
      (this.emergencyMaintenanceDestination.enabled && !this.emergencyMaintenanceDestination.message)
      || (
        this.representativeDestination.enabled
        && (!this.representativeDestination.message || !this.representativeDestination.number)
      )
      || (
        this.residentRepresentativeDestination.enabled
        && (!this.residentRepresentativeDestination.message || !this.residentRepresentativeDestination.number)
      )
    ) {
      this.toastService.showError('Cannot update: Missing Call Transfer Fields');
      return;
    }

    let hasDuplicateNumberButNotMatchingMessage = false;
    const destinations = [
      this.emergencyMaintenanceDestination,
      this.representativeDestination,
      this.residentRepresentativeDestination,
    ];

    destinations.forEach(destination1 => {
      if (!destination1.enabled) {
        return;
      }
      destinations.forEach(destination2 => {
        if (!destination2.enabled) {
          return;
        }

        // If numbers are the same messages should match or voice VLA might behave weird
        if (destination1.number === destination2.number && destination1.message !== destination2.message) {
          hasDuplicateNumberButNotMatchingMessage = true;
        }
      })
    })

    if (hasDuplicateNumberButNotMatchingMessage) {
      this.toastService.showError('Destinations with the same number must have the same message.');
      return;
    }

    this.loaderService.triggerLoader();
    this.savingSettings = true;

    try {
      const payload = [];

      if (this.emergencyMaintenanceDestination.enabled) {
        payload.push({
          ...this.emergencyMaintenanceDestination,
          number: '+1' + this.emergencyMaintenanceDestination.number,
        });
      }

      if (this.representativeDestination.enabled) {
        payload.push({
          ...this.representativeDestination,
          number: '+1' + this.representativeDestination.number,
        });
      }

      if (this.residentRepresentativeDestination.enabled) {
        payload.push({
          ...this.residentRepresentativeDestination,
          number: '+1' + this.residentRepresentativeDestination.number,
        });
      }

      await this.voiceVLAService.updateCallTransferDestinations(this.voiceAssistant.id, payload).toPromise();
      this.toastService.showSuccess('Successfully Updated Call Transfers!');
    } catch (err) {
      this.toastService.showError('There was an error updating you call transfers.');
    }
    this.savingSettings = false;
    this.loaderService.stopLoader();
  }

  // this handles updates for the VLA Settings section
  async updateVlaSettings() {
    this.loaderService.triggerLoader();
    this.savingSettings = true;

    try {
      const payload = { disableEmailHandoff: this.disableEmailHandoffField };
      await this.settingsService.saveSettingsByType(SettingType.VLA, payload).toPromise();
      this.toastService.showSuccess('Successfully Updated VLA Settings!');
    } catch (err) {
      this.toastService.showError(err?.userMessage || 'There was an error updating your VLA Settings.');
    }
    this.savingSettings = false;
    this.loaderService.stopLoader();
  }

  // this handles updates for the Personality section
  async updateVLAPersonalitySettings() {
    if (this.hasVoiceAssistant && !this.selectedVoice?.id) {
      this.toastService.showError('Please select a voice before updating personality settings.');
      return;
    }

    this.loaderService.triggerLoader();
    this.savingSettings = true;

    const botNameChanged = this.originalBotName !== this.vlaSettings.botName;
    const firstMessageChanged = this.voiceAssistant.firstMessage !== this.greetingMessage;
    const voiceIdChanged = this.originalVoiceId !== this.selectedVoice?.id;
    
    try {
      // 1.) Update Greeting Message if it has changed
      if (this.hasVoiceAssistant && firstMessageChanged) {
        // warning: including model in payload overwrites existing model
        // so we need to make sure to only send the firstMessage field
        const payload = { firstMessage: this.greetingMessage };
        await this.voiceVLAService.updateVoiceAssistant(this.user.voiceAssistantId, payload).toPromise();
      }

      // 2.) Update Voice ID if it has changed
      if (this.hasVoiceAssistant && voiceIdChanged) {
        const provider = this.isMultilingual ? 'elevenlabs' : 'cartesia';
        await this.voiceVLAService.updateVoiceAssistantVoice(this.user.voiceAssistantId, provider, this.selectedVoice?.id).toPromise();
        this.originalVoiceId = this.selectedVoice?.id;
      }

      if (botNameChanged) {
        const payload = { botName: this.vlaSettings.botName };
        await this.settingsService.saveSettingsByType(SettingType.VLA, payload).toPromise();
        await this.vlaService.rebuildAllUserPrompts().toPromise();
      }

      this.originalBotName = this.vlaSettings.botName;
      this.toastService.showSuccess('Successfully Updated Personality Settings!');
    } catch (err) {
      // If there is an error, reset the greeting message and bot name
      try {
        if(botNameChanged){
          const payload = { botName: this.originalBotName };
          await this.settingsService.saveSettingsByType(SettingType.VLA, payload).toPromise();
          await this.vlaService.rebuildAllUserPrompts().toPromise();
        }

      } catch (err) {
        // no-op
      }
        this.toastService.showError(err?.userMessage || 'There was an error updating your Personality Settings.');

    }
    this.savingSettings = false;
    this.loaderService.stopLoader();
  }

  async loadVoices() {
    try {
      const provider = this.isMultilingual ? 'elevenlabs' : 'cartesia';
      const voices = await this.ttsService.getVoices(provider).toPromise();
      this.availableVoices = voices.sort((a, b) => a.name.localeCompare(b.name));
      
      // Reset selected voice if it's not in available voices
      if (this.selectedVoice && !this.availableVoices.find(voice => voice.id === this.selectedVoice.id)) {
        this.selectedVoice = null;
      }

      // If we have a voiceId
      if (this.voiceAssistant?.voice?.voiceId) {
        this.selectedVoice = this.availableVoices.find(voice => voice.id === this.voiceAssistant.voice.voiceId);
      }

      // if no voice is found, use the default voice
      if (!this.selectedVoice) {
        const defaultVoiceId = this.isMultilingual ? 'EXAVITQu4vr4xnSDxMaL' : 'f9836c6e-a0bd-460e-9d3c-f7299fa60f94';
        this.selectedVoice = this.availableVoices.find(voice => voice.id === defaultVoiceId);
      }
      
      // If no voice is selected (either no selectedVoiceId or voice not found), select the first available voice
      if (!this.selectedVoice && this.availableVoices.length > 0) {
        this.selectedVoice = this.availableVoices[0];
      }
    } catch (err) {
      this.toastService.showError('Error loading available voices');
    }
  }

  filterVoices(event: any) {
    const query = event.query.toLowerCase();
    this.filteredVoices = this.availableVoices.filter(voice => 
      this.getVoiceDisplay(voice).toLowerCase().includes(query)
    );
  }

  getVoiceDisplay(voice: Voice): string {
    if (!voice) return '';
    return `${voice.name} - ${voice.gender}`;
  }

  onVoiceSelect(event: any) {
    this.selectedVoice = event;
  }

  async onMultilingualToggle() {
    this.loaderService.triggerLoader();
    await this.loadVoices();
    await new Promise(resolve => setTimeout(resolve, 200));
    this.loaderService.stopLoader();
  }
}
