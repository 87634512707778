// Angula Core:
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DragDropModule } from '@angular/cdk/drag-drop';
// Libs:
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TableModule } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ChipsModule } from 'primeng/chips';
import { PickListModule } from 'primeng/picklist';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { QuillModule } from 'ngx-quill';
import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';

// Base:
import { AppComponent } from './app.component';
import { appRoutingModule } from './app.routing';
// Helpers
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { SnakeCasePipe} from './_helpers/snakeCase.pipe';
import { PageFilterPipe } from './_helpers/page-filter.pipe';
import { FilterPipe } from './_helpers/filter.pipe';
import { ListFilterPipe } from './_helpers/listFilter.pipe';
import { MessageFilterPipe } from './_helpers/message-filter.pipe';
import { PhonePipe } from './_helpers/phone.pipe';
import { CamelCasePipe } from './_helpers/camelCase.pipe';
import { ComponentInjectorDirective } from './_helpers/component-injector.directive';
import { LogPipe } from './_helpers/log.pipe';
import { EventResultPipe } from './_helpers/eventResult.filter';
// Components
import { LoginComponent } from './login';
import { PagesComponent } from './pages';
import { BillingComponent, IntegrationsComponent, MediaComponent, SettingsComponent, IntegrationCardComponent } from './settings';
import { TrackingComponent } from './tracking';
import { ToastsContainer } from './toast';
import {
    MediaModalComponent,
    MasterNurtureTemplateComponent,
    SaveDefaultsModalComponent,
    BuildAutomationsModalComponent,
    ContactTableColumnSelectorComponent,
    WarningModalComponent,
    GenerateAITextModalComponent,
} from './modals';
import { ForgotPasswordComponent } from './forgot-password';
import { PasswordResetComponent } from './password-reset';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { SheetJSComponent } from './sheetjs';
import { DashboardComponent } from './dashboard';
import { AnalyticsComponent } from './analytics';
import { ContactComponent, ContactInfoComponent } from './contact';
import { TwoWayComponent } from './two-way';
import { AutomationComponent } from './automation';
import { environment } from '../environments/environment';
import { AdminComponent } from './admin';
import { GroupComponent } from './group';
import { ContactsComponent } from './contacts';
import { AccountComponent } from './settings/account/account.component';
import { DefaultsComponent } from './settings/defaults/defaults.component';
import { ComplianceComponent } from './settings/compliance/compliance.component';
import { SourcePickerComponent } from './shared/source-picker/source-picker.component';
import { ChurnAccountDisplayPipe, MimicAccountDisplayPipe, MimicAccountDisplayWithPMCPipe } from './admin/mimic-account-display.pipe';
import { ContactsTableComponent } from './shared/contacts-table/contacts-table.component';
import { NurtureBossModalComponent } from './_components/nb-modal/nb-modal.component';
import { NbPlayerModule } from './_components/nb-player/nb-player.module';
import { TtsAudioPlayerComponent } from './_components/tts-audio-player/tts-audio-player.component';
import { KnowledgeBaseComponent } from './knowledge-base/knowledge-base.component';
import { KnowledgeBaseLoginComponent } from './knowledge-base/knowledge-base-login/knowledge-base-login.component';
import { ByoaComponent } from './byoa/byoa.component';
import { StepComponent } from './byoa/step/step.component';
import { PrintErrorsComponent } from './print-errors/print-errors.component';
import { IphonePreviewComponent } from './shared/iphone-preview/iphone-preview.component';
import { BroadcastComponent } from './broadcast/broadcast.component';
import { CardActionComponent } from './shared/card-action/card-action.component';
import { TokenContainerComponent } from './shared/token-container/token-container.component';
import { ToggleVisiblePasswordComponent } from './shared/toggle-visible-password/toggle-visible-password.component';
import { CharacterCountComponent } from './shared/character-count/character-count.component';
import { TemplateFormGroupComponent } from './shared/template-form-group/template-form-group.component';
import { TipsAndTricksComponent } from './shared/tips-and-tricks/tips-and-tricks.component';
import { ButtonComponent } from './shared/button/button.component';
import { CardTotalComponent } from './shared/card-total/card-total.component';
import { SendTextMessageComponent } from './shared/send-text-message/send-text-message.component';
import { SidebarFilterComponent } from './broadcast/sidebar-filter/sidebar-filter.component';
import { TemplatePickerComponent as NewTemplatePickerComponent } from './broadcast/template-picker/template-picker.component';
import { TemplateListComponent } from './broadcast/template-list/template-list.component';
import { TemplateFormComponent } from './shared/template-form/template-form.component';
import { SendEmailComponentComponent } from './shared/send-email-component/send-email-component.component';
import { WizardComponent, WizardStepComponent } from './wizard/wizard.component';
import { TestComponent, TestInnerCompnent } from './test/test.component';
import { BroadcastBuilderComponent } from './broadcast/broadcast-builder/broadcast-builder.component';
import { DeliveryMethodComponent } from './broadcast/delivery-method/delivery-method.component';
import { FilePreviewComponent } from './shared/file-preview/file-preview.component';
import { NotificationsComponent } from './settings/notifications/notifications.component';
import { BroadcastSendMessageComponent } from './broadcast/broadcast-send-message/broadcast-send-message.component';
import { FileTypeAllComponent } from './shared/file-type-all/file-type-all.component';
import { FileTypeImageComponent } from './shared/file-type-image/file-type-image.component';
import { FileTypeDocumentsComponent } from './shared/file-type-documents/file-type-documents.component';
import { BroadcastSchedulerComponent } from './broadcast/broadcast-scheduler/broadcast-scheduler.component';
import { ScheduleModalComponent } from './shared/schedule-modal/schedule-modal.component';
import { BotComponent } from './settings/bot/bot.component';
import { LimitedTextareaComponent } from './shared/limited-textarea/limited-textarea.component';
import { UserAuditsWidgetComponent } from './user-audits-widget/user-audits-widget.component';
import { AlertComponent } from './alert/alert.component';
import { SubjectLineGraderInputComponent } from './shared/subject-line-grader-input/subject-line-grader-input.component';
import { GenerateAITextComponent } from './shared/generate-ai-text/generate-ai-text.component';
import { FormsComponent } from './forms/forms.component';
import { ViewFormsComponent } from './forms/view-forms/view-forms.component';
import { CreateFormComponent } from './forms/create-form/create-form.component';
import { QuestionComponent } from './forms/create-form/question/question.component';
import { ViewFormResultsComponent } from './forms/view-form-results/view-form-results.component';
import { SpamWarningsComponent } from './shared/spam-warnings/spam-warnings.component';
import { VLASettingsComponent } from './settings/vla/vla.component';

// Directives:
import { StickAtTopDirective } from './_directives/stick-at-top.directive';
import { TrimDirective } from './_directives/trim.directive';
import { ContactEventsComponent } from './contact/events/contactevents.component';
import { BetaBadgeComponent } from './shared/beta-badge/beta-badge.component';
import { VLAAllConversationsComponent, VLAAutomationsComponent, VLAComponent, VLADashboardComponent, VLAKnowledgeBase, VLASingleConversationComponent } from './vla';

export let options: Partial<IConfig> | (() => Partial<IConfig>);

const ngWizardConfig: NgWizardConfig = {
    theme: THEME.default
};

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        appRoutingModule,
        NgbModule,
        FormsModule,
        AutocompleteLibModule,
        TableModule,
        TreeTableModule,
        MultiSelectModule,
        DropdownModule,
        CalendarModule,
        ColorPickerModule,
        ChipsModule,
        PickListModule,
        AccordionModule,
        AutoCompleteModule,
        OverlayPanelModule,
        NgxMaskModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        NgWizardModule.forRoot(ngWizardConfig),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        QuillModule.forRoot(),
        DragDropModule,
        NbPlayerModule,
    ],
    declarations: [
        AppComponent,
        KnowledgeBaseLoginComponent,
        KnowledgeBaseComponent,
        DashboardComponent,
        ComplianceComponent,
        IntegrationsComponent,
        IntegrationCardComponent,
        ContactComponent,
        PagesComponent,
        LoginComponent,
        ForgotPasswordComponent,
        PasswordResetComponent,
        SettingsComponent,
        BillingComponent,
        AccountComponent,
        NotificationsComponent,
        DefaultsComponent,
        AdminComponent,
        GroupComponent,
        AutomationComponent,
        MediaComponent,
        TrackingComponent,
        MediaModalComponent,
        SaveDefaultsModalComponent,
        BuildAutomationsModalComponent,
        ContactTableColumnSelectorComponent,
        GenerateAITextModalComponent,
        WarningModalComponent,
        PageFilterPipe,
        EventResultPipe,
        FilterPipe,
        ListFilterPipe,
        MessageFilterPipe,
        CamelCasePipe,
        PhonePipe,
        SnakeCasePipe,
        ToastsContainer,
        MasterNurtureTemplateComponent,
        SheetJSComponent,
        AnalyticsComponent,
        ContactComponent,
        ContactsComponent,
        ContactInfoComponent,
        ContactEventsComponent,
        TwoWayComponent,
        SourcePickerComponent,
        ChurnAccountDisplayPipe,
        MimicAccountDisplayPipe,
        MimicAccountDisplayWithPMCPipe,
        ContactsTableComponent,
        NurtureBossModalComponent,
        TtsAudioPlayerComponent,
        ByoaComponent,
        StepComponent,
        PrintErrorsComponent,
        IphonePreviewComponent,
        BroadcastComponent,
        CardActionComponent,
        CharacterCountComponent,
        TokenContainerComponent,
        ToggleVisiblePasswordComponent,
        TemplateFormGroupComponent,
        TipsAndTricksComponent,
        ButtonComponent,
        BetaBadgeComponent,
        CardTotalComponent,
        SendTextMessageComponent,
        SidebarFilterComponent,
        NewTemplatePickerComponent,
        TemplateListComponent,
        TemplateFormComponent,
        LogPipe,
        SendEmailComponentComponent,
        WizardComponent,
        WizardStepComponent,
        TestComponent,
        TestInnerCompnent,
        BroadcastBuilderComponent,
        DeliveryMethodComponent,
        FilePreviewComponent,
        BroadcastSendMessageComponent,
        ComponentInjectorDirective,
        StickAtTopDirective,
        FileTypeAllComponent,
        FileTypeImageComponent,
        FileTypeDocumentsComponent,
        BroadcastSchedulerComponent,
        ScheduleModalComponent,
        BotComponent,
        LimitedTextareaComponent,
        TrimDirective,
        UserAuditsWidgetComponent,
        AlertComponent,
        SubjectLineGraderInputComponent,
        GenerateAITextComponent,
        FormsComponent,
        ViewFormsComponent,
        CreateFormComponent,
        QuestionComponent,
        ViewFormResultsComponent,
        VLAComponent,
        VLAAutomationsComponent,
        VLAAllConversationsComponent,
        VLASingleConversationComponent,
        VLADashboardComponent,
        VLAKnowledgeBase,
        SpamWarningsComponent,
        VLASettingsComponent,
    ],
    entryComponents: [
        MediaModalComponent,
        MasterNurtureTemplateComponent,
        SaveDefaultsModalComponent,
        BuildAutomationsModalComponent,
        ContactTableColumnSelectorComponent,
        GenerateAITextModalComponent,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
